@import url('sm.bootstrap-base.css');
@import url('sm.portal-course-browser.css');
/*
Theme Name: U4SM
Text Domain: u4sm
Author: Unit4 Education Solutions
Author URI: http://www.unit4.com/us/solution/education
Description: U4SM is the custom theme built for Unit4 Student Management. This is our base theme.
Version: 0.1
Tags: Unit4 Education Solutions, Unit4
*/

/** * Table of Contents
*
* 1.0 - Base Styles
*   1.1 - Eric Meyer's Reset 2.0 + Normalize (Contents Imported)
*     1.1.1 - Eric Meyer's Reset 2.0
*     1.1.2 - Normalize
*   1.2 - Imported
*/


/****** FORMAT ******/
/** * 0.0 - Header
*   Comment
*/

    /** * 0.1 - Subheader
    *   Comment
    */
/**** END FORMAT ****/


/** * 1.0 - Base Styles
*/

    /** * 1.1 - Eric Meyer's Reset 2.0 (1.1.1) + Normalize (1.1.2)
    */

        /** * 1.1.1 ./style/css/reset-normalize.css
		*/


    /** * 1.2 - Imported Theme Style -- May want to / need to overhaul this...
    *       Licensed under GNU General Public License version 3.0
    */

        /** * 1.2.1 ./style/css/imported.css
		*/

		
/** * TEMPORARY PLACEHOLDERS
*
*/

	
/** * U4SM THEME Styles
*
*/

body {
	font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	color: #777;
	font-size: 14px;
	line-height: 24px;
}
body.error404 .main-container > section.error-page {
    padding-top: 250px;
    padding-bottom: 150px;
}
.error-page i {
    display: inline-block;
    font-size: 80px;
    margin-bottom: 24px;
    transition: all 300ms ease;
    animation: spin 6s ease-in-out infinite alternate;
}
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0;
	font-weight: 300;
	color: #333;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: #333;
}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
	color: #e74c3c;
}
h1 {
	font-size: 32px;
	line-height: 48px;
	margin-bottom: 48px;
}
h2 {
	font-size: 28px;
	line-height: 44px;
}
h3 {
    font-size: 26px;
    line-height: 32px;
}
h5, h6 {
    font-weight:600;
    margin-bottom:16px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}
a {
	color: #333;
    transition: all 300ms ease;
}
a:hover {
	text-decoration: none;
	color: #e74c3c;
}
a.link-text {
    font-family: Raleway, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #3498db;
    display: inline-block;
    padding-bottom: 0px;
    color: #3498db;
}
a.link-text:hover {
    color: #e74c3c;
    border-bottom-color: #e74c3c;
}

button {
	background: none;
    transition: all 300ms ease;
	display: inline-block;
	line-height: 1;
    font-family: Raleway, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: bold;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #3498db;
    border-radius: 2px;
    border: 2px solid #3498db;
    padding: 14px 28px 13px 28px;
}
.btn, .button, .menu-button > a {
    background: none;
    transition: all 300ms ease;
    min-width: 155px;
}
.btn-primary, .button, .menu-button > a {
    border-radius: 2px;
    display: inline-block;
    padding: 14px 28px 13px 28px;
    line-height: 1;
    border: 2px solid #3498db;
    font-family: Raleway, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #3498db;
}
.btn:hover, .button:hover, .menu-button > a:hover, .btn:active, .btn:focus {
    background: none;
    color: #fff;
    border-color: #3498db;
    background-color: #3498db;
}
.btn.btn-white {
    border-color: #fff;
    color: #fff;
}
.btn.btn-white:hover {
    background: #fff;
    color: #333;
    border-color: #fff;
}
.btn.btn-primary.btn-filled {
    background: #3498db;
    color: #fff;
}
.btn.btn-default, .btn.btn-primary, .btn.btn-success, .btn.btn-info, .btn.btn-warning, .btn.btn-danger {
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input[type=checkbox],
input[type=radio] {
    margin-right: 10px;
}
input[type="submit"],
.post-password-form input[type="submit"] {
    -webkit-appearance: none;
    background: none;
    transition: all 300ms ease;
    min-width: 155px;
    border-radius: 2px;
    display: inline-block;
    padding: 14px 28px 13px 28px;
    line-height: 1;
    border: 2px solid #3498db;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #3498db;
    max-width: 155px;
}
input[type="submit"] {
    max-width: none;
    width: auto;
}
.photo-form-wrapper input[type="submit"] {
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.smHeaderLogin form input[type="submit"] {
    color: #fff;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #e74c3c;
    border: solid 1px #e74c3c;
    width: auto;
    box-shadow: none;
    border-radius: 25px;
    margin-bottom: 30px;
    max-width: 570px;
    padding: 1em 2em;
    cursor: pointer;
}
.smHeaderLogin form input[type="submit"]:hover,
.smHeaderLogin form input[type="submit"]:active,
.smHeaderLogin form input[type="submit"]:focus {
    background-color: #e74c3c;
    border-color: #e74c3c;
    
}

ul {
	list-style: none;
	padding: 0;
}

p {
	line-height: 24px;
    font-size: 14px;
}
p:last-child {
	margin-bottom: 0;
}
p:empty {
	display: none;
}
p.lead {
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
}

img {
	max-width: 100%;
    height: auto;
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    overflow: hidden;
}
section.full,
div.full {
	padding: 0;
}
section.full.headline {
	padding-top: 20px;
}
section.topheavy {
    padding-top: 40px;
    padding-bottom: 20px;
}

header a,
header a:hover,
header a:focus,
header a:visited,
footer a,
footer a:hover,
footer a:focus,
footer a:visited,
.overlay-bar a,
.overlay-bar a:hover,
.overlay-bar a:focus,
.overlay-bar a:visited {
	color: #fff;
}
header.signup:before {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	content: '';
	z-index: 2;
	background-color: rgba(51, 51, 51, 0.3);
}
header.signup h1,
header.signup .container .smHeaderMessageBox h1 {
	font-weight: 600;
	font-size: 40px;
	line-height: 56px;
	margin-bottom: 0;
}
header .container {
	position: relative;
	z-index: 3;
}
footer {
	padding-top: 80px;
	padding-bottom: 80px;
}
footer.short {
	font-size: 16px;
}
footer.short ul {
	display: inline-block;
	margin: 0;
	line-height: 24px;
}
footer.short ul li {
	float: left;
	margin-right: 32px;
}
footer.short ul li a {
	opacity: 0.8;
}
footer.short ul.social-icons a {
    opacity: 1;
    font-size: 22px;
}
footer.short .sub {
	opacity: .5;
	display: inline-block;
}
footer ul.social-icons li {
    font-size: 24px;
}
footer ul.social-icons li:last-child {
    margin-right: 0;
}
ins, label {
    color: #777;
    text-decoration: none;
}

i.icon {
    display: inline-block;
    vertical-align: text-top;
    margin-left: 5px;
}
i.icon.icon-jumbo {
    width: auto;
    height: auto;
    line-height: 64px;
    margin: 0 20px 25px 20px;
    color: #e74c3c;
    text-align: center;
    font-size: 64px;
}
.error-page .jumbo {
    font-size: 160px;
    line-height: 160px;
    font-weight: 600;
    margin-bottom: 16px;
}
.error-page.bg-primary h1 {
    color: white;
}

.alt-font {
    font-family: Raleway, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.text-white {
    color: #fff;
}

.bg-secondary-1 {
	background-color: #2c3e50;
}
.bg-secondary-1 * {
	color: #fff;
}

.container {
	max-width: 100%;
	
}
.smLandingPageHeader {
	position: relative;
}
.smLandingPageHeader + section {
	padding-top: 0;
}
.smLandingPageHeader .col-sm-12.loginHeader {
    padding: 0;
}

.menu {
	padding: 0;
	margin: 0;
	display: inline-block;
	position: relative;
	top: 2px;
}
.menu li {
    float: left;
    margin-right: 56px;
    position: relative;
}
.menu li a {
	font-family: Raleway, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: bold;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 1px;
	transition: all 500ms ease;
	padding-bottom: 40px;
	display: inline-block;
	line-height: 14px;
}
.overlay-bar > .container .sub-menu {
	display: none; /*Keep this to prevent submenu 'flash' on page load.*/
    position: absolute;
    background: #fff;
    padding: 24px;
    transition: all 500ms ease;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 1.5em rgba(0,0,0,0.3);
    margin-left: -20px;
}
.overlay-bar .sub-menu li a {
    color: #777;
}
.overlay-bar .container .sub-menu li a:hover {
    color: #333;
}
.offscreen-container .menu li a
.offscreen-container-authenticated .menu li a {
    color: #aaa;
}
.offscreen-container .menu li a:hover,
.offscreen-container-authenticated .menu li a:hover {
    color: #fff;
}
.overlay-bar > .container .menu-item-has-children {
    bottom: 3px;
}
.overlay-bar > .container .menu-item-has-children:after {
    font-family: ElegantIcons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: "\33";
    color: #fff;
}
/*Moved submenu show/hide into script.js*/
/*.overlay-bar > .container .menu-item-has-children:hover .sub-menu {
	display: block;
}*/
.overlay-bar > .container .sub-menu li {
    margin-right: 0;
    min-width: 142px;
}
.overlay-bar > .container .sub-menu li a {
    padding-bottom: 0;
    margin-bottom: 16px;
}
.overlay-bar > .container .sub-menu li:last-child a {
    margin-bottom: 0;
}

.background-image-holder {
	position: absolute;
	top: 0;
	min-width: 100%;
	height: 100%;
	overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(126, 148, 171);
}
.background-image-holder img {
	min-width: 100%;
    visibility: hidden;
}
section.landing-hero .background-image-holder {
    position: relative;
    min-height: 175px;
    height: auto;
    max-height: 950px;
}
section.landing-hero .background-image-holder img {
    position: relative;
    min-height: 175px;
}


.offscreen-container .menu {
	display: block;
}
.offscreen-container .menu li {
	float: none;
	margin-bottom: 16px;
}
.offscreen-container .menu li a {
	padding-bottom: 0px;
	color: #aaa;
}
.offscreen-container .sub-menu {
	display: block;
}
.offscreen-container,
.offscreen-container-authenticated {
	position: fixed;
	width: 300px;
	height: 100%;
	background: #333;
	right: 0;
	top: 0;
	z-index: 99999;
	transition: all 300ms ease;
	transform: translate3d(300px, 0, 0);
	padding: 25px 10px;
    visibility:hidden;
}

.offscreen-menu .container,
.offscreen-menu-authenticated .container {
	transform: translate3d(0, 0, 0);
}

.offscreen-menu .container.reveal-nav,
.main-container.reveal-nav,
.footer-container.reveal-nav {
	transform: translate3d(-300px, 0, 0);
    
}

.offscreen-container.reveal-nav,
.offscreen-container-authenticated.reveal-nav {
    visibility:visible;
	transform: translate3d(0, 0, 0);
}

.offscreen-toggle i {
    font-size: 36px;
    cursor: pointer;
}
.mobile-toggle {
	display: none;
}

.top-bar {
	position: absolute;
	background: #fff;
	width: 100%;
	z-index: 10;
	transition: all 500ms ease;
	line-height: 0;
	top: 0;
}

.top-bar img.logo {
    position: relative;
	max-width: 140px;
	bottom: 12px;
	margin-bottom: -6px;
}
.top-bar.nav-sticky .menu > li > a {
    padding-bottom: 32px;
}

.tight-bottom-wrap > *:last-child {
	margin-bottom: 0;
}

div.smContentArea select,
div.smStickyFooter select {
    background-position: calc(100% - 5px) 50%;
}

.container .text-center h1 {
    margin-bottom: 24px;
}
.blog-snippet-1 {
    margin-bottom: 40px;
}
.blog-snippet-1 h2 {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 36px;
}
.blog-snippet-1 img {
    display: inline-block;
    margin-bottom: 16px;
}
.blog-snippet-1 a {
    margin-top: 8px;
}
.stat {
    text-align: center;
}
.feature {
    overflow: hidden;
}
.stat-bubble {
    margin: 0 auto;
    width: 120px;
    height: 120px;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.15);
    text-align: center;
    line-height: 116px;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
    margin-bottom: 12px;
}
section.trs-light-row .stat-bubble,
section.trs-light-row h3 {
    border-color: #999;
    color: #999;
}

section.hero-divider {
    padding: 160px 0;
    position: relative;
    overflow: hidden;
}
.hero-divider:before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    content: '';
    opacity: 0.4;
    z-index: 2;
    background-color: #333;
}
.hero-divider .container {
    position: relative;
    z-index: 3
}
.hero-divider .btn {
    margin-right: 16px;
    margin-top: 24px;
}
.hero-divider .btn:last-child {
    margin-right: 0;
}

.video-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}
.video-wrapper video {
    width: 100%;
    position: absolute;
    top: 0;
}

.photo-form-wrapper input {
    border: none;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 4px;
    padding: 14px 24px 14px 24px;
    width: 100%;
    margin-bottom: 30px;
}

/* CONTINUING EDUCATION - JITG - 4/10/16 */
.sm-inverted-grey
{
	background-color:rgb(74, 87, 100);
}

#ce_template .col-md-4.col-sm-12 .blog-snippet-1
{
	
}

#ce_template .stat.feature h3
{
    color:#fff;
}

@media (min-width:768px)
{
	#ce_template .col-md-4.col-sm-12 .blog-snippet-1 img
	{
		width:100%;
	}
}

#ce_template p.subHeading
{
	color:#333;
	font-size:large;
	margin-bottom:2em;
}

#ce_template .blog-snippet-1 p > a.link-text
{
	
}

#ce_template .sm-inverted-grey .stat.feature > h3
{
	color:#fff;
}

#ce_template section.smallTopPad
{
	padding-bottom:0;
}

.sm_loginWidget
{
   display: none;
    float: left;
    clear: both;
    width: 100%;
    max-width: 100%;
    border:none !important;  
    margin: .5em;
}

.sm_loginWidget input[type="submit"]
{
    background-color:#fff;
    border:solid 1px #3498db;
    padding:1em 2em;
    cursor:pointer;
}

.sm_loginWidget > div > p
{
    color:#fff;
}


@media (max-width: 1230px) {
    .menu li {
        margin-right: 32px;
    }
}
@media screen and (max-width: 767px) {
    /*section > .container {
        padding-left: 30px;
        padding-right: 30px;
    }*/
    body.error404 .main-container > section.error-page {
        padding-top: 150px;
        padding-bottom: 50px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px)  {
    section {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    p.lead {
        font-size: 14px;
        line-height: 24px;
    }
    .btn {
        margin-top: 16px;
    }
    .menu {
        top: 0px;
    }
    .menu li {
        margin-right: 0;
    }
    
    .hero-divider {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .hero-divider .video-wrapper {
        display: none;
    }
    .hero-divider .btn {
        margin-right: 0;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    body.error404 .main-container > section.error-page {
        padding-top: 200px;
        padding-bottom: 100px;
    }
}
@media (max-width: 1023px) {
    .top-bar img.logo {
        bottom: 0;
    }
}
@media screen and (min-width: 1024px) {
    section.landing-hero .background-image-holder img {
        top: 50%;
        transform: translateY(-50%);
    }
}

@media (max-width: 1400px) {
    .video-wrapper video {
        width: 110%;
    }
}
@media (max-width: 1200px) {
    .video-wrapper video {
        width: 130%;
    }
}
@media (max-width: 1000px) {
    .video-wrapper video {
        width: 150%;
    }
}


/**** KEYFRAME ANIMATIONS SETTINGS ****/

/* Chrome, Safari, Opera */ 
@-webkit-keyframes spin {
    0%   {transform: rotate(-15deg);}
    10%   {transform: rotate(-85deg);}
    30%   {transform: rotate(5deg);}
    50%   {transform: rotate(250deg);}
    100% {transform: rotate(0deg);}
}

/* Standard syntax */
@keyframes spin {
    0%   {transform: rotate(-15deg);}
    10%   {transform: rotate(-85deg);}
    30%   {transform: rotate(5deg);}
    50%   {transform: rotate(250deg);}
    100% {transform: rotate(0deg);}
}