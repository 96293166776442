/* Icon Classes for Portal */
/* BASIC ICONS STYLING */
span.icon
{
	display:block;
	height:25px;
	width:25px;
	background-repeat:no-repeat;
	background-size:70%;
	background-position:50%;
}

/* PLUGIN BRANDING U4 ICON */
#toplevel_page_u4sm-adaptor-options .wp-menu-image.dashicons-before
{
    background-image:url('../img/svg/icon_u4logo.svg');
    background-repeat:no-repeat;
    background-position:50%;
    background-size:25px;
}

.smContentArea .responsiveMenu span.menuIcon,
.smModal .responsiveMenu span.menuIcon
{
	background-image: url('../img/svg/dark/icon_menu.svg');
}

.smProfileHeader span.menuIcon,
.nav-menu span.menuIcon
{
	background-image: url('../img/svg/dark/icon_menu.svg');
}

.smProfileHeader span.menuIconWhite
{
	background-image: url('../img/svg/icon_menu.svg');
}

.smContentArea a.subItemTrigger,
.smModal a.subItemTrigger,
.smContentArea span.closed,
span.closed
{
	background-image: url('../img/svg/dark/icon_disclosureDown.svg')
}

.smContentArea a.subItemTrigger.active,
.smModal a.subItemTrigger.active,
.smContentArea span.open,
span.open
{
	background-image: url('../img/svg/dark/icon_disclosureUp.svg')
}

/* SPECIFIC ICONS */
.smContentArea .advanceSingle,
.smModal .advanceSingle
{
    background-image: url('../img/svg/dark/icon_advanceSingle.svg');
}

.smContentArea .menuIcon,
.menuIcon,
.smModal .menuIcon
{
	background-image: url('../img/svg/icon_menu.svg');
}

.smContentArea .holdsIcon,
.smModal .holdsIcon /* For header */
{
	background-image: url('../img/svg/icon_alertTriangle.svg');
}

.smContentArea .holdsItemIcon,
.smModal .holdsItemIcon
{
	background-image: url('../img/svg/dark/icon_exclamationMark.svg');
}

.smContentArea .detailsIcon,
.smModal .detailsIcon
{
	background-image: url('../img/svg/icon_disclosureDown.svg');
}

.smContentArea .detailsIcon.hide,
.smModal .detailsIcon.hide
{
	display:block !important; /* Needed in order to override broadly scoped bootstrap show/hide with !important */
    transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
}

.smContentArea .billingSummaryIcon,
.smModal .billingSummaryIcon
{
	background-image: url('../img/svg/icon_billingSummary.svg');
}

.smContentArea .financialAidIcon,
.smModal .financialAidIcon
{
	background-image: url('../img/svg/icon_financialAid.svg');
}

.smContentArea .myCoursesIcon,
.smModal .myCoursesIcon
{
	background-image: url('../img/svg/icon_college.svg');
}

.smContentArea .myCommunicationsIcon,
.smModal .myCommunicationsIcon
{
	background-image: url('../img/svg/icon_discussion.svg');
}

.smContentArea .visitLinkIcon,
.smModal .visitLinkIcon
{
	background-image: url('../img/svg/icon_visitLink.svg');
}

.smContentArea .emailMessageIcon,
.smModal .emailMessageIcon
{
	background-image: url('../img/svg/dark/icon_mailEnvelopeClosed.svg');
}

.smContentArea .smsMessageIcon,
.smModal .smsMessageIcon
{
	background-image: url('../img/svg/dark/icon_sms.svg');
}

.smContentArea .webAlertIcon,
.smModal .webAlertIcon
{
	background-image: url('../img/svg/dark/icon_webAlert.svg');
}

/* .smContentArea .trsReplyMessageIcon,
.smModal .trsReplyMessageIcon
{
	background-image: url('../img/svg/dark/icon_replyMessage.svg');
} */

/* .smContentArea .trsArchiveMessageIcon,
.smModal .trsArchiveMessageIcon
{
	background-image: url('../img/svg/dark/icon_archiveMessage.svg');
} */

/* .smContentArea .trsNewMessageIcon,
.smModal .trsNewMessageIcon
{
	background-image: url('../img/svg/dark/icon_envelopeNew.svg');
} */

.smContentArea .invoiceIcon,
.smContentArea .documentIcon,
.smModal .invoiceIcon,
.smModal .documentIcon
{
	background-image: url('../img/svg/dark/icon_document.svg');
}

.smContentArea .gradesIcon,
.smModal .gradesIcon
{
	background-image: url('../img/svg/icon_grades.svg');
}


/* BROUGHT OVER FROM TEMPORARY */
.smContentArea .editItemIcon,
.smModal .editItemIcon
{
	background-image: url('../img/svg/dark/icon_edit.svg');
}

.smContentArea .deleteItemIcon,
.smModal .deleteItemIcon
{
	background-image: url('../img/svg/dark/icon_trash.svg');
}

.smContentArea .deleteIcon,
.smModal .deleteIcon,
.smStickyFooter .deleteIcon
{
    background-image: url('../img/svg/dark/icon_X.svg');
}

.smContentArea .saveItemIcon,
.smModal .saveItemIcon
{
	background-image: url('../img/svg/dark/icon_save.svg');
}

.smContentArea .cancelItemIcon,
.smModal .cancelItemIcon
{
	background-image: url('../img/svg/dark/icon_cancel.svg');
}

.smContentArea .addItemIcon,
.smModal .addItemIcon
{
    background-image: url('../img/svg/dark/icon_plus.svg');
}

.smContentArea .facebookIcon,
.smModal .facebookIcon
{
    background-image: url('../img/svg/dark/icon_facebookLogo.svg');
}

.smContentArea .skypeLogoIcon,
.smModal .skypeLogoIcon
{
    background-image: url('../img/svg/dark/icon_skypeLogo.svg');
}

.smContentArea .skypeCallIcon,
.smModal .skypeCallIcon
{
    background-image: url('../img/svg/dark/icon_skypeCall.svg');
}

.smContentArea .skypeChatIcon,
.smModal .skypeChatIcon
{
    background-image: url('../img/svg/dark/icon_skypeChat.svg');
}

.smContentArea .twitterBirdIcon,
.smModal .twitterBirdIcon
{
    background-image: url('../img/svg/dark/icon_twitterBird.svg');
}

.smContentArea .linkedInIcon,
.smModal .linkedInIcon
{
    background-image: url('../img/svg/dark/icon_linkedInLogo.svg');
}

.smContentArea .editAddressIcon,
.smModal .editAddressIcon
{
    cursor: pointer;
}

.smContentArea .phoneNumbersIcon,
.smModal .phoneNumbersIcon
{
    background-image: url('../img/svg/dark/icon_mobilePhone.svg');
}

.smContentArea .addressesIcon,
.smModal .addressesIcon
{
    background-image: url('../img/svg/dark/icon_home.svg');
}

.smContentArea .firstPage,
.smModal .firstPage
{
    background-image: url('../img/svg/dark/icon_firstPage.svg');
}

.smContentArea .lastPage,
.smModal .lastPage
{
    background-image: url('../img/svg/dark/icon_lastPage.svg');
}

.smContentArea .previousPage,
.smModal .previousPage
{
    background-image: url('../img/svg/dark/icon_previousPage.svg');
}

.smContentArea .nextPage,
.smModal .nextPage
{
    background-image: url('../img/svg/dark/icon_nextPage.svg');
}

.smContentArea .calendarIcon,
.smModal .calendarIcon
{
    background-image: url('../img/svg/dark/icon_calendar.svg');
}

.smContentArea .personIconWhite,
.smProfileHeader .personIconWhite,
.nav-menu .personIconWhite
{
	background-image: url('../img/svg/icon_person.svg');
}

.smContentArea .personIcon,
.smProfileHeader .personIcon,
.nav-menu .personIcon
{
	background-image: url('../img/svg/dark/icon_person.svg');
}

.smContentArea .peopleIcon,
.smModal .peopleIcon
{
    background-image: url('../img/svg/dark/icon_people2.svg');
}

.smContentArea .reqComplete,
.smModal .reqComplete
{
    background-image: url('../img/svg/dark/icon_reqComplete.svg');
}

.smContentArea .reqIncomplete,
.smModal .reqIncomplete
{
    background-image: url('../img/svg/dark/icon_reqIncomplete.svg');
}

.smContentArea .uploadIcon,
.smModal .uploadIcon
{
    background-image: url('../img/svg/dark/icon_uploadDocument.svg');
}

.smContentArea .loadingSpinner,
.smModal .loadingSpinner
{
    background-image: url('../img/loader.gif');
}

.smContentArea .searchIcon,
.smModal .searchIcon
{
    background-image: url('../img/svg/dark/icon_search.svg');
}

.smContentArea .iconPrevious,
.smModal .iconPrevious
{
    background-image: url('../img/svg/icon_previousArrow.svg');
}

.smContentArea .iconNext,
.smModal .iconNext
{
    background-image: url('../img/svg/icon_nextArrow.svg');
}

.smContentArea .iconUnlocked,
.smModal .iconUnlocked,
.iconUnlocked
{
    background-image: url('../img/svg/dark/icon_unlocked.svg');
}

.smContentArea .iconLocked,
.smModal .iconLocked,
.iconLocked
{
    background-image: url('../img/svg/dark/icon_locked.svg');
}

.smContentArea span.shoppingCartIcon,
.smModal span.shoppingCartIcon
{
    background-image: url('../img/svg/icon_cartIcon.svg');
}

.smContentArea span.recordAttendance,
.smModal span.recordAttendance
{
    background-image: url('../img/svg/dark/icon_recordAttendance.svg');
}

.smContentArea span.recordAttendanceLight,
.smModal span.recordAttendanceLight
{
    background-image: url('../img/svg/icon_recordAttendance.svg');
}



/* ATTENDANCE */
.optionsBarToggle
{
    background-image: url('../img/svg/icon_settingsCog1.svg') !important;
}

span.regressDouble
{
    background-image: url('../img/svg/icon_regressDouble.svg') !important;
}

span.regressSingle
{
    background-image: url('../img/svg/icon_regressSingle.svg') !important;
}

span.advanceSingle
{
    background-image: url('../img/svg/icon_advanceSingle.svg') !important;
}

span.advanceDouble
{
    background-image: url('../img/svg/icon_advanceDouble.svg') !important;
}

span.searchResultsIcon
{
    background-image:url('../img/svg/icon_searchResults.svg') !important;
}

span.personIcon,
ul.crmControls .profileControl > .profileImage > .listImage > span.noPhotoIcon,
.relationNode.Person > span.icon
{
    background-image: url('../img/svg/dark/icon_person.svg');
}

span.noPhotoIcon
{
    background-image:url('../img/svg/dark/icon_noCamera.svg');
}

button.attend_present > span.icon,
span.attend_present
{
    background-image: url('../img/svg/dark/icon_person.svg');
}

button.attend_absent > span.icon,
span.attend_absent
{
    background-image: url('../img/svg/dark/icon_cancel.svg');
}

button.attend_late > span.icon,
span.attend_late
{
    background-image: url('../img/svg/dark/icon_clock.svg');
}

button.attend_excused > span.icon,
span.attend_excused
{
    background-image: url('../img/svg/dark/icon_checkmark.svg');
}

td.recordedStatus
{
    background-image: url('../img/svg/dark/icon_checkBox.svg');
}

/* td.recordedStatus.recorded
{
    background-image: url('../img/svg/icon_checkBoxChecked');
} */





/* 8073 - 10/21/2015 - MVS - PULLED FROM TEMPORARY FOR MERGING AND OPTIMIZATION */

.icon.scrollToTop
{
    background-image: url('../img/svg/icon_scrollTop.svg');
    background-size:100%;
}

/* END PULL FROM 10/21/2015 TEMPORARY */