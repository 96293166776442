/* MOSTLY FOR CONTINUING EDUCATION - COURSE BROWSER */
/* NOTE: THIS WORKS FOR SHORT TERM - BUT THIS MAKES COURSE BROWSER PAGES RELIANT ON OUR THEME (BUT ONLY FOR BODY CLASSING/STYLE-SCOPING) */

/* COURSES STYLING */
/* CONTINUING EDUCATION - 7-27-16 */
body.u4sm-theme
{
    
}

body.u4sm-theme .smContentArea ul.detailedList > li .listItem, 
body.u4sm-theme .smModal ul.detailedList > li .listItem, 
body.u4sm-theme .smStickyFooter ul.detailedList > li .listItem
{
    background-color:#fff;
}

body.u4sm-theme .smRegistrationCourseSearch
{
    background:none;
}

body.u4sm-theme .smRegistrationCourseSearch .superSearch input[type="text"]
{
    color:#999;
}

body.u4sm-theme table
{
    box-sizing:border-box;
    width:100%;
}

body.u4sm-theme .courseBasicSummary p
{
    line-height:1.25;
    color:#666;
    padding:.25em 0;
    max-width:800px;
}

body.u4sm-theme .courseDetailedSummary
{
    float:left;
    clear:both;
    width:100%;
    margin:0;
    border-style:solid;
    border-width:1px 0;
    border-color:#999;
    padding:1em 0;
}

body.u4sm-theme .courseDetailedSummary .field.inline
{
    display:inline-block;
    margin-right:.5em;
}

body.u4sm-theme .courseDetailedSummary .field.inline:after
{
    content:"|";
    position:relative;
    left:.5em;
    color:#333;
}

body.u4sm-theme .courseDetailedSummary a.courseMoreDetails
{
    
}

body.u4sm-theme .courseDetailedSummary a.courseMoreDetails span.icon
{
    
}

@media screen and (max-width:600px)
{
    body.u4sm-theme .courseDetailedSummary .field.inline
    {
        width:33%;
        border-right:solid 1px #999;
    }

    body.u4sm-theme .courseDetailedSummary .field.inline:after
    {
        content:"";
    }

    .page-template-u4sm-coursebrowser .itemDetails
    {
        font-size:small;
    }
}

@media screen and (max-width:400px)
{
    body.u4sm-theme .courseDetailedSummary .field.inline
    {
        width:30%;
    }

    a[data-course-more-details] /* DON'T USE ATTRIBUTE HERE - REFACTOR TO CLASS FOR ALIGNMENT */
    {
        display:inline-block;
        vertical-align:top;
    }

    body.u4sm-theme .courseDetailedSummary a.courseMoreDetails
    {
        float:right;
        margin-right:1em;  
        margin-top:.5em; 
    }
}

body.u4sm-theme .courseDetailedSummary span.infoIcon
{
    display:inline-block;
    background-image:url(../img/svg/dark/icon_circleInfo.svg);
    background-size:100%;
    vertical-align:middle;
    height:20px;
    width:20px;
}

body.u4sm-theme .itemDetails table th:last-child
{
    min-width:200px;
    text-align:center;
}

body.u4sm-theme .itemDetails table th
{
    padding:1em .5em;
}

body.u4sm-theme .itemDetails table th:last-child
{
    text-align:center;
}

body.u4sm-theme .itemDetails table tbody tr
{
    border-bottom:solid 1px rgba(150,150,150,.1);
}

body.u4sm-theme .itemDetails table tbody tr td .enrollModeButtons
{
    min-width:0;
    border-radius:0;
    height:2.4rem;
    margin:.3rem;
    padding:0 .5rem;
    line-height:2.4rem;
    display:inline-block;
    cursor:pointer;
    transition-duration:.2s;
    color:#000;
    width:100%;
    text-align:center;
}

body.u4sm-theme .itemDetails table tbody tr a.facultyImage
{
    display:inline-block;
    height:50px;
    width:50px;
}

body.u4sm-theme .itemDetails table tbody tr a.facultyImage img
{

}

body.u4sm-theme .isPhone .smEnrollmentStatus
{
    display:block;
    float:right;
    text-align:center;
    max-width:60%;
}

body.u4sm-theme .itemDetails table tbody tr td .smEnrollmentStatus > p,
body.u4sm-theme .isPhone .smEnrollmentStatus > p
{
    line-height:1;
    color:rgb(51,51,51);
    margin-top:.5em;
}

body.u4sm-theme .isPhone .smEnrollmentStatus > p
{
    display:none;
}

body.u4sm-theme .itemDetails table tbody tr td .smEnrollmentStatus > a,
body.u4sm-theme .isPhone .smEnrollmentStatus > a
{
    color:red;
    text-decoration:underline;
}

body.u4sm-theme .itemDetails table tbody tr td .hiddenInputs
{
    display:none !important;
}

body.u4sm-theme .pageIndicator
{
    display:inline-block;
    color:#FFF;
}

body.u4sm-theme .pagerWrap
{
    margin:.5em 0;
    background-color:#5093c1;
    line-height:35px;
}


/* SM COURSES CART */
body.u4sm-theme .smCoursesCart
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:.25em;
    background-color:#f7f7f7;
}

body.u4sm-theme .smSlideupHeader
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    background-color:rgb(0, 56, 161);
    color:#fff;
    box-sizing:border-box;
    padding:.25em;
}

body.u4sm-theme .smSlideupHeader
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
}

body.u4sm-theme .smSlideupHeader > button:last-child
{
    float:right;
}

body.u4sm-theme  .smSlideupHeader > h3
{
    display:inline-block;
    float:none;
    color:#fff;
    font-size:1.5em;
    margin-left:.25em;
    font-weight:500;
}

body.u4sm-theme .smSlideupHeader button
{
    color:#fff;
    border:solid 1px #fff;
    padding: 0 1em;
    min-width:0;
    margin: 0 .25em;
    line-height:2.5;
    vertical-align:top;
    border-width:1px;
    border-radius:2px;
    position:relative;
    top:2px;
}

body.u4sm-theme .smCoursesCart ul
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    overflow-y:auto;
}

body.u4sm-theme .smCoursesCart ul li
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    background-color:#efefef;
    border-bottom:solid 1px #999;
    padding:.5em 0;
}

body.u4sm-theme .smCoursesCart ul li > span.inspectIcon
{
    float:left;
}

body.u4sm-theme .smCoursesCart ul li > h3
{
    display:block;
    float:left;
    font-size:1em;
    margin:0 .5em;
    max-width:70%;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}

body.u4sm-theme .smCoursesCart ul li > h3 > b
{
    float:left;
    margin-right:.5em;
}

body.u4sm-theme .smCoursesCart ul li > h3 > span.smCourseDescription
{
    max-width:58%;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}

body.u4sm-theme .smCoursesCart ul li > span.smEnrollmentType
{
    display:block;
    float:left;
    margin-top:.25em;
}

body.u4sm-theme .smCoursesCart ul li > span.deleteIcon
{
    float:right;
    margin-right:.5em;
    margin-top:.25em;
    border:solid 1px rgb(153,153,153);
    border-radius:3em;
    cursor:pointer;
}

body.u4sm-theme .smCoursesCart ul li > span.deleteIcon:hover
{
    border-color:rgb(52, 152, 219);
    background-color:rgb(52, 152, 219);
    background-image:url('../img/svg/icon_X.svg');
}

body.u4sm-theme .widgetContent h3
{
    max-width:90%;
    line-height:1.25em;
    margin:0 0 .25em 0;
}

body.u4sm-theme .widgetContent h3 b
{

}

body.u4sm-theme .widgetContent h3 span.courseId
{
    display:block;
    font-size:.9em;
}

body.u4sm-theme ul.detailedList > li .listItem
{
    padding:.5em;
}

body.u4sm-theme ul.detailedList > li .listItem .summaryBlock
{
}

body.u4sm-theme ul.detailedList > li .listItem .summaryBlock .courseThumb
{
    float:left;
    margin:0 .75em 0 0;
    background-color:#efefef;
    width:90px;
    height:90px;
    border:solid 1px #999;
    opacity:.8;
    transition: opacity .3s ease;
}

body.u4sm-theme ul.detailedList > li .listItem .summaryBlock .courseThumb:hover
{
    opacity:1;
}


@media only screen and (max-width:768px)
{
    body.u4sm-theme ul.detailedList > li .listItem .summaryBlock .courseThumb
    {
        height:50px;
        width:50px;
    }
}

/* CART ITEMS TABLE */
body.u4sm-theme .cartItemsWrapper
{
    float:left;
    clear:both;
    width:100%;
    overflow-y:auto;
    overflow-x:hidden;
}

body.u4sm-theme .cartItems
{

}

body.u4sm-theme table.cartItems 
{

}

body.u4sm-theme table.cartItems thead tr,
body.u4sm-theme table.portalDefault th
{
    background-color:#efefef;
    color:#777;
    font-weight:600;
}

body.u4sm-theme table.cartItems th,
body.u4sm-theme table.cartItems td
{
    padding:.5em;
    line-height:1.25;
}

body.u4sm-theme table.cartItems  th:last-child,
body.u4sm-theme table.cartItems  td:last-child
{
    text-align:center;
    width:50px;
}

body.u4sm-theme table.cartItems  td:last-child span.icon
{
    display:inline-block;
    float:none;
    background-color:#fff;
    border:solid 1px #999;
    cursor:pointer;
}

body.u4sm-theme table.cartItems  tbody tr:nth-child(even)
{
    background-color:#fff;
}

body.u4sm-theme table.cartItems  tbody tr:last-child
{
    
}

body.u4sm-theme tr.sumTotals
{
    border-style:solid;
    border-width:1px 0;
    border-color:#999;
    background-color:#eaeaea;
    color:#000;
}

/* CART ITEM LIST */
ul.cartItems
{
    
}

ul.cartItems li
{
    
}

ul.cartItems li
{
    
}












body.u4sm-theme .smStickyFooterButtons .cartBtn .cartBadge
{
    background-color:#1c2040;
    padding:.5em 0;
    float:left;
    border-radius:3em;
    margin-left:1em;
}

body.u4sm-theme .smStickyFooterButtons .cartBtn .cartBadge span.shoppingCartIcon
{
    background-image: url('../img/svg/icon_cartIcon.svg');
    background-color:#1c2040;
    float:left;
    margin:0 1em;
    border-radius:3em;
    width:20px;
    height:20px;
    background-size:100%;
}

body.u4sm-theme .smStickyFooterButtons .cartBtn .cartBadge .cartItemCount
{
    display:inline-block;
    font-size:medium;
    margin:0 .75em 0 0;
}

body.u4sm-theme .smStickyFooterButtons .cartBtn .cartSummaryText p
{
    display: inline-block;
    float:left;
    max-width:140px;
    text-align:left;
    text-transform:none;
    margin:0 .5em;
    line-height:1;
}

/* CART CHECKOUT */
body.u4sm-theme .smCoursesCart .cartCheckout
{
    float:left;
    clear:both;
    width:100%;
    margin:.5em 0;
}

body.u4sm-theme .smCoursesCart .cartCheckout p.cartMessage
{
    float:left;
    max-width:75%;
    text-align:center;
    border:solid 1px #999;
    padding:1em;
    line-height:1.25;
    color:#fff;
    background-color:#1b2d41;
}


@media (max-width:600px)
{
    body.u4sm-theme .smCoursesCart .cartCheckout p.cartMessage
    {
        width:100%;
        clear:both;
        max-width:none;
        margin:0;    
        position:relative;
        left:-1px;
        margin-bottom:1em;
    }

    body.u4sm-theme .smCoursesCart .cartCheckout .checkoutButton
    {
        display:block;
        float:none !important;
        clear:both;
        margin:0 auto !important;
        width:80%;
    }
}

.cartMobileDetails p
{
    line-height:1;
    margin:.25em 0;
}



body.u4sm-theme .smCoursesCart .cartCheckout .checkoutButton
{
    float:none;
    margin:1em auto;
    display:inherit;
}




/* SM STICKY FOOTER SLIDEUP */
body.u4sm-theme .smStickySlideup
{
    display:block;
    width:100%;
    position:fixed;
    bottom:79px;
    height:0px;
    background-color:#fff;
    transition:height .3s ease;
    /*visibility:hidden;*/
    box-shadow:0px 0px 2.5em rgba(0,0,0,.5);
    border-style:solid;
    border-width:1px;
    border-color:rgb(56, 77, 116);
    z-index:999;
    max-width:1175px;
    left:50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform:translateX(-50%);
}

body.u4sm-theme .smStickySlideup.hidden
{
    visibility:hidden;
    overflow:hidden;
}

body.u4sm-theme .smStickySlideup.visible
{
    visibility:visible;
}

body.u4sm-theme .smStickySlideup.inYourFace
{
    height:calc(100% - 65px);
}

body.u4sm-theme .smStickyFooterButtons span:not(.badge)
{
    display:block;
    line-height:1;
    margin: .5em;
}
/* END STICKY SLIDEUP */

/* sm STICKY FOOTER */
body.u4sm-theme .smStickyFooter
{
    position:fixed;
    bottom:0;
    height:64px;
    background-color:rgb(56,77,116);
    width:100%;
    z-index:999;
}

body.u4sm-theme .smStickyFooterButtons
{
    display:none;
    width:100%;
    position:fixed;
    bottom:0;
    box-sizing:border-box;
    z-index:1000;
    max-width:1175px;
    left:50%;
    transform:translateX(-50%);
    -webkit-transform:translateX(-50%);
    -moz-transform:translateX(-50%);
    box-shadow:0px 0px 1.5em rgba(0,0,0,.5);
}

body.u4sm-theme .smStickyFooterButtons button.cartBtn
{
    display:block;
    float:left;
    width:75%;
    text-align:center;
    background:rgb(56, 77, 116);
    color:#fff;
    border:0;
    text-shadow:none;
    border-right:solid 1px rgba(255,255,255,.3);
    border-radius:0;
    min-width:0;
    border-top:solid 1px rgb(56, 77, 116);
    min-height:64px;
    margin:0;
    padding:.5em;
}

body.u4sm-theme .smStickyFooterButtons button:focus
{
    /*outline:0;*/
}

body.u4sm-theme .smStickyFooterButtons button:last-child
{
    border:0;
    border-top:solid 1px rgb(56, 77, 116);
    width:25%;
}

body.u4sm-theme .smStickyFooterButtons button.active
{
    /*background:rgb(56, 77, 116);*/
    background:rgb(0, 56, 161);
    box-shadow:none;
    border-top:solid 1px transparent;
}

body.u4sm-theme .smStickyFooterButtons span.badge
{
    display:inline-block;
    margin:0 .5em;
    background-color:#fff;
    color:#333;
    border-radius:3em;
    width:auto;
    line-height:15px;
    font-weight:bold;
    padding:.5em 1em;
}

body.u4sm-theme .smStickyFooter .regPaymentOptions > .fieldGroup > .field.inline
{
    margin-right:3em;
}

body.u4sm-theme .regPaymentOptions > .fieldGroup > .field:first-child
{
    margin-left:.5em;
}

body.u4sm-theme .smStickyFooterButtons button[data-scroll-top] span.iconWrap
{
    background-color:transparent;
}

body.u4sm-theme .smStickyFooterButtons .badge.iconWrap
{
    padding: 0 .5em;
}

body.u4sm-theme .smStickyFooterButtons .badge .icon
{
    margin: 0;
}

body.u4sm-theme .smStickyFooterButtons.visible
{
    display:block;
}

body.u4sm-theme .smStickyFooterButtons button > span:last-child
{
    width:70px;
    display:inline-block;
}

body.u4sm-theme[data-original-position] .top-bar.overlay-bar.offscreen-menu
{
    transition:none !important;
}

/* END sm STICKY FOOTER */

/* LOGIN/CREATE ACCOUNT PARTIAL */
.smLoginRegister
{
    float:left;
    clear:both;
    width:100%;
    padding:.25em;
    background-color:#fff;
}

.smLoginRegister .row
{
    overflow:auto;
    float:left;
    clear:both;
    width:100%;
    margin:0;
}

.smLoginRegister .cartTopMessage
{
    float:left;
    clear:both;
    width:100%;
    text-align:center;
    padding:1em;
}

.smLoginRegister .cartTopMessage h3
{
    padding:.5em 1em;
}

.smAjaxLoginWidget,
.smCreateAccountForm,
.smPaymentForm
{
    float:left;
    clear:both;
    width:100%;
    padding:0 .5em;
}

.smLoginRegister fieldset
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    border:solid 1px #999;
    padding:1em;
    text-align:center;
    min-height:475px;
    background-color:#f7f7f7;
}

.smLoginRegister fieldset legend
{
    line-height:1;
    float:left;
    border:0;
    margin:.5em 0 1em 0;
}

.smAjaxLoginWidget input[type="text"],
.smCreateAccountForm input[type="text"],
.smAjaxLoginWidget input[type="password"]
{
    width:100%;
    color:#333;
}

.smCreateAccountForm .sm_loginFields div.field > label
{
    margin-bottom:0;
    color:#666;
    font-weight:normal;
    width:100%;
    text-align:left;
}

@media only screen and (max-width:600px){
    
}

[data-to-bottom]
{
    overflow:auto;
    float:left;
    width:100%;
}

.smLoginRegister .sm_loginFields
{
    margin-bottom:1em;
}

@media only screen and (max-width:990px)
{
    .smAjaxLoginWidget
    {
        margin-bottom:2em;
    }

    body.u4sm-theme .smSlideupHeader
    {
        text-align:center;
    }

    body.u4sm-theme .smSlideupHeader > h3
    {
        display:inline-block;
        float:none;
    }

    .smLoginRegister fieldset
    {
        min-height:unset;
    }
}

/* PAYMENT FORM */
.smPaymentForm
{
    float:left;
    clear:both;
    width:100%;
    padding:.25em;
    background-color:#f7f7f7;
}

.smPaymentFormWrapper
{
    float:left;
    clear:both;
    width:100%;
}

.smPaymentForm legend
{
    line-height:1;
    float:left;
    border:0;
    margin:1em 0;
    text-align:center;
}

.smPaymentForm .notificationMessage
{

}

.smPaymentForm .fieldGroup
{
    
}

.smPaymentForm .fieldGroup div.field:last-child
{
    
}

.smPaymentForm .fieldGroup.split.fiftyfifty
{
    
}

.smPaymentForm .fieldGroup.split.fiftyfifty > div.field
{
    float:left;
    width:100%;
    position:relative;
}

.smPaymentForm .fieldGroup.split.fiftyfifty > div.field > label
{
    display:inline-block;
    max-width:100%;
    width:35%;
    margin: 0 .5em 0 0;
    float:left;
    text-align:right;
    font-weight:normal;
    color:#4c4c4c;
    clear:both;
    text-decoration:none;
}

.smPaymentForm .fieldGroup.split.fiftyfifty > div.field > input
{
    width:35%;
    float:none;
    clear:none;
}

.smPaymentForm div.field > select
{
    clear:none;
}

@media only screen and (max-width:600px)
{
    .smPaymentForm div.field > select
    {
        max-width:190px;
    }
}

.smPaymentForm .fieldGroup.split.fiftyfifty > div.field > input[type="text"]
{
    padding:0 .5em;
    background-color:#fff;
    color:#333333;
    display:block;
}

/* VALIDATIONS & ERRORS */
.smPaymentForm div.field.error
{
    background-color:rgb(255,206,206);
}

.smPaymentForm div.field.error > input.required
{
    border-style:solid;
    border-width:1px;
    border-color:#ff0000;
}

.smPaymentForm div.field > label.requiredLabel::before,
.smCreateAccountForm div.field > label.requiredLabel::before
{
    content:" * ";
    color:#ff0000;
    font-size:1.5em;
    position:relative;
    top:6px;
    left:4px;
    margin-right:.25em;
}

.smPaymentForm .fieldGroup.split.fiftyfifty > div.field.error > label.error
{
    display:block;
    text-align:center;
    margin-left:0px !important;
    color:rgb(73,73,73);
    position:relative;
    top:5px;
    float:left;
    width:100%;
}

.smPaymentForm legend span.requiredLegend,
.smCreateAccountForm legend span.requiredLegend
{
    display:inline-block;
    color:#ff0000;
    margin:1em 0 0 0;
    float:left;
    clear:both;
    width:100%;   
    font-size:.75em;
}

.smPaymentForm legend span.requiredLegend abbr,
.smCreateAccountForm legend span.requiredLegend abbr
{

}

.smAjaxLoginWidget label
{
    font-weight:normal;
}


.smPaymentForm div.field.error > label.error:after
{
    content:none;
}

.smPaymentForm .buttonRow
{
    width:100%;
    clear:both;
    text-align:center;
    padding:1em 0;
}

/* HEADER STATUS COLORING */
/* ALL HEADER MESSAGING */
.smProcessingConfirmation .smSlideupHeader.msg
{
    
}

.smProcessingConfirmation .smSlideupHeader.msg h3
{
    font-weight:500;
}

/* SUCCESS */

.smProcessingConfirmation .smSlideupHeader.msg.success
{
    background-color:#00a800 !important;
}

/* FAILURE */
.smProcessingConfirmation .smSlideupHeader.msg.failure
{
    background-color:#aa1a1a !important;
}

/* WARNING */
.smProcessingConfirmation .smSlideupHeader.msg.warning */
{
    background-color:#f88a00 !important;
}

/* END SLIDEUP HEADER MESSAGING */




/* MODAL Z-INDEX OVERRIDE FOR COURSE BROWSER SCREENS */
.page-template-u4sm-coursebrowser .smOverlay
{
    z-index:9999;
    cursor:not-allowed;
}

.page-template-u4sm-coursebrowser .smOverlay .smModal.active
{
    cursor:default;
}

/* DISABLED STICKY FOOTER BUTTONS */
.smStickyFooterButtons button[disabled="disabled"]
{
    
}

.smStickyFooterButtons button[data-complete-redirect]
{
    width:100% !important;
}

.smStickyFooterButtons button[data-complete-redirect]:hover
{
    background-color:rgb(0, 56, 161);
}

/* PAYMENT PROCESSING & CONFIRMATION */
.smProcessingMessage
{

}

.courseBrowserLoading
{
animation-name: courseBrowserBlue;
animation-duration: 1s;
animation-iteration-count: infinite;
-webkit-animation-name: courseBrowserBlue;
-webkit-animation-duration: 1s;
-webkit-animation-iteration-count: infinite;
}

.courseBrowserLoading.slow
{
    animation-duration:5s;
    -webkit-animation-duration: 5s;
}

@keyframes courseBrowserBlue {
from { background-color: #0038a1; }
50% { background-color: #0059ff; }
to { background-color: #0038a1;}
}

@-webkit-keyframes courseBrowserBlue {
from { background-color: #0038a1; }
50% { background-color:#0059ff; }
to { background-color: #0038a1;}
}

.smProcessingConfirmation
{
    float:left;
    clear:both;
    width:100%;
    background-color:#f7f7f7;
}

.smProcessingConfirmation .smSlideupHeader h3
{
    float:none !important;
    text-align:center;
}

.smProcessingMessage > p,
.smPaymentSuccessful > p
{
    float:left;
    clear:both;
    width:100%;
    text-align:center;
    color:#000;
    padding:1em;
    font-size:large;
}

.smShopperDetails
{
    float:left;
    clear:both;
    width:100%;
    padding:1em;
    background-color:#fff;
}

@media only screen and (max-width:600px)
{
    .smShopperDetails
    {
        max-width:none;
    }
}

.smShopperDetails .fieldGroup div.field
{
    clear:both;
}

.smShopperDetails .fieldGroup div.field > label
{
    
}

.smShopperDetails .fieldGroup div.field > span
{
    clear:none;
    margin-left:.5em;
}

.smShopperDetails .fieldGroup div.field address
{
    float:left;
    clear:both;
    margin:0;
    color:#333;
}

.registeredCoursesSchedule,
.registeredCourseInvoice
{
    float:left;
    clear:both;
    width:100%;
    padding:1em;
}

.registeredCoursesSchedule table,
.registeredCourseInvoice table
{
    border-style:solid;
    border-width:1px;
    border-color:#999;
}

.registeredCoursesSchedule table caption,
.registeredCourseInvoice table caption
{
    padding:.5em .25em;
    color:#000;
    font-weight:bold;
}

/* COURSE DETAILS MODAL - TABSET OVERRIDES */
body.u4sm-theme .tabSet .tabWrapper
{
    width:100% !important;
}

.courseFullDetailsWrapper
{
    float:left;
    clear:both;
    width:100%;
    padding:1em;
}

.courseFullDetailsWrapper > p
{
    line-height:1.25;
    color:#000;
    margin:.5em 0 1.5em 0;
}

.mediumDialog .tabSet .tabScrollableContent
{
    max-height: 300px;
    overflow:auto;
    float:left;
    clear:both; 
    width:100%;
    padding:1em;
}

.syllabus section
{
    padding:0;
}

.syllabus section h4
{
    color:#000;
    margin:1em 0;
}

.syllabus section h5
{
    font-size:1em;
    margin-bottom:1em !important;
}

.syllabus section h6
{
    
}

/* UNIFYING FOR NOW */
/* COME BACK AND STANDARDIZE COLORING FOR ACCESSIBILIY (AA Standard) */
.cartItems th,
.cartItems td,
.smCreateAccountForm .sm_loginFields div.field > label,
.smPaymentForm .fieldGroup.split.fiftyfifty > div.field > label,
body.u4sm-theme .courseBasicSummary p,
.smContentArea div.field > label,
.smContentArea div.field > span:not(.icon)

{
    color:#4c4c4c;
}

/* COMPLEX LAYOUT OF MULTIPLE FIELDS ON PHONE REZ */
.complexLayout
{
    float:left;
    clear:both; 
    width:100%;
    border:solid 1px #ccc;
    margin:.5em 0;
    background-color:#f8f8f8;
    padding:1em;
}

.complexLayout > div
{
    float:left;
    width:50%;
}

.complexLayout div.field.inline
{
    display:inline-block;
    margin-right:.5em;
    float:left;
    clear:both;
    width:100%;
}

.complexLayout .enrollModeButtons
{
    float:left;
    clear:both;
    width:100%;
    margin-top:.5em;
}

.complexLayout .enrollModeButtons > button
{
    padding:0 .25em;
    font-size:.9em;
}

.complexLayout a.facultyImage
{
    max-width:40px; 
    float:left;
    clear:both;
    display:block;
}

/* BELOW TABLET */
@media only screen and (max-width:767px)
{
    
}




