/* REUSABLE ACCENTS */
.smContentArea .accent1
{
    background-color: rgba(32,130,241,1) !important;
}

.smContentArea .accent2
{
   background-color: rgba(123,150,81,1) !important;
}

.smContentArea .accent3
{
    background-color: rgba(123,50,21,1) !important;
}

.smContentArea .accent4
{
    background-color: rgba(59,130,111,1) !important;
}

.smContentArea .accent5
{
    background-color: rgba(112,100,231,1) !important;
}

.smContentArea .accent6
{
    background-color: rgba(61,41,1,1) !important;
}

.smContentArea .accent7
{
    background-color: rgba(62,2,4,1) !important;
}

.smContentArea .accent8
{
    background-color: rgba(235, 255, 0, 1) !important;
}

.smContentArea .accent9
{
    background-color: rgba(56,107,122,1) !important;
}

.smContentArea .accent10
{
    background-color: rgba(192,92,62,1) !important;
}

.smContentArea .accent11
{
    background-color: rgba(123,170,1,1) !important;
}

.smContentArea .accent12
{
    background-color: rgba(0,34,24,1) !important;
}

.smContentArea .accent13
{
    background-color: rgba(173,180,50,1) !important;
}

.smContentArea .accent14
{
    background-color: rgba(0,2,39,1) !important;
}

.smContentArea .accent15
{
    background-color: rgba(33,10,41,1) !important;
}

.smContentArea .accent16
{
    background-color: rgba(33,10,41,1) !important;
}

.smContentArea .accent17
{
    background-color: rgba(33,10,41,1) !important;    
}

.smContentArea .accent18
{
    background-color: rgba(33,10,41,1) !important;
}

.smContentArea .accent19
{
    background-color: rgba(33,10,41,1) !important;
}

.smContentArea .accent20
{
    background-color: rgba(0,48,137,1) !important;
}