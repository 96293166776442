/* Portrait phones and smaller */
@media only screen and (max-width: 319px) 
{
    .smContentArea #TestScoreForm .dataDisplayView .displayHeader button
    {
        float: none;
    }
}

@media only screen and (max-width: 480px) 
{
    /* BOOTSTRAP OVERRIDES */


    /* PIVOT OVERRIDES */


    /* TRS OVERRIDES */
    .smStickySlideup.inYourFace
    {
        height:calc(100% - 67px);
        box-shadow:none;
    }

    .smStickyFooterButtons button span.badge
    {
        margin-bottom:1em;
    }

    .smContentArea .tabSet > ul:first-child > li[data-activate-tab]
    {
        font-size:.8em;
    }

    .smContentArea .flexWidget.small,
    .smContentArea .flexWidget.smid, 
	.smContentArea .flexWidget.medium,
    .smContentArea .flexWidget.half,
	.smContentArea .flexWidget.large
	{
		width:100% !important;
		min-height:0;
	}
	
	.smContentArea  ul.triBlock > li
	{
		width:100% !important;
	}
	
	.smContentArea  ul.triBlock > li:last-child
	{
		height:75px;	
	}
	
	.smContentArea  ul.triBlock > li > span
	{
		font-size:1.5em;
	}
	
	.smContentArea  ul.triBlock > li > label
	{
		bottom:10%;
	}
	
	.smContentArea ul.inlineFields li,
    .smContentArea ul.inlineFields.two li,
    .smContentArea ul.inlineFields.three li,
    .smContentArea ul.inlineFields.four li
	{
		display:block;
		float:left;
		clear:both;	
		width:100%;
        border-bottom:solid 1px rgba(150,150,150,.3);
        padding:.5em 0;
	}
	
	.smContentArea  ul.inlineFields li .field label
	{
		display:inline;
		float:none;
		text-align:right;
		min-width:35%;
		margin-right:.5em;	
	}
	
	.smContentArea ul.inlineFields li .field span
	{
		display:block;
		float:none;
	}
		
	.smContentArea  .fieldGroup.responsive > div.field
	{
		clear:both;
	}

    .smContentArea ul.detailedList > li.editorShowing .listItem .itemEditor.active
    {
        margin-top:0;
        border:0;
    }

    .smContentArea ul.detailedList > li.editorShowing .listItem > .buttonGroup
    {
        float:left;
        width:100%;
        clear:both;
        text-align:center;
        border-style:solid;
        border-width:1px 0px;
        padding:.5em 0;
        margin-top:.5em;
    }

    .smContentArea .flexWidget .widgetContent
    {
        max-height:none;
    }

    /* DEGREE AUDIT QUERIES */
    .smModal
    {
        width:100%;
        margin:0;
        max-width:100% !important;
    }

    .smModal.smallDialog
    {
        width:90%;
    }

    ul.reqCourseList > li.active > p
    {
        margin-top:0;
    }

    ul.reqCourseList > li
    {
        padding:1em;
    }

    .flexWidget.large .fieldGroup.splitField > .field.inline.currency
    {
        width:100%;
        border-left:0;
    }

    .courseList.phoneDetails > li
    {
        display:block;
        float:left;
        clear:both;
        width:100%;
        border-style:solid;
        border-width:1px 0;
        border-color:#999;
        background-color:#fff;
        padding:.5em;
        margin-bottom:.25em;
    }

    .courseList.phoneDetails > li > .courseSummary > span
    {
        display:block;
        float:left;
        clear:both;
        width:100%;
        margin-bottom:.5em;
        color:rgb(92,92,92);
        font-weight:bold;
    }

    .smContentArea ul.detailedList.reqList > li .listItem .itemSummary > span:nth-child(1)
    {
        margin-top:.5em;
        max-width:85%;
        white-space:normal;
        line-height:1.2;
    }


    .smContentArea ul.detailedList.reqList > li .listItem > span.progressIndicator
    {
        margin-top:1.25em;
        margin-left:.5em;
    }

    .smContentArea ul.detailedList.reqList > li .itemSummary.responsive
    {
        margin-bottom:.5em;
    }

    .smContentArea ul.detailedList.reqList > li .itemSummary > .fieldGroup
    {
        margin-top:.5em;
        width:100%; /* Added for Degree Audit - phone rendering - text expanding beyond container */
    }

    .smContentArea ul.detailedList.reqList > li .itemSummary > .fieldGroup > div.field.inline
    {
        margin:.25em 0;    
        width:50%;
        padding:0;
    }

    .smContentArea ul.detailedList.reqList > li .itemSummary > .fieldGroup > div.field.inline:after
    {
        content:"" !important;
        margin:0 !important;
    }

    .smContentArea .applicationContentArea .templateContainer > div > h3,
    .smContentArea #AccountContainer > div > h3,
    .smContentArea .friendlyMessage
    {
        padding:.5em !important;
    }


    .smContentArea .flexWidget#registrationContainer > .widgetHeader
    {
        text-align: center;
    }
    .smContentArea .flexWidget#registrationContainer > .widgetHeader > h2
    {
        margin-left:.5em;
    }
    .smContentArea .flexWidget#registrationContainer > .widgetHeader .widgetActions
    {
        position: static;
        display: block;
        margin: 0 auto 1em;
    }

    .smContentArea .isPhone ul.phoneDetails > li > span:nth-child(n+3)
    {
        width: 100%;
        clear: left;
    }
    .smContentArea #TestScoreForm .listItem.withActions .itemDetails .fieldGroup .field label
    {
        max-width: 70%;
        width: 100%;
        text-align: left;
    }
    .smContentArea #TestScoreForm .listItem.withActions .itemDetails .fieldGroup .field span
    {
        max-width: 20%;
        float: right;
    }

    .smContentArea#smStudentProfile #mailingAddressListContainer .itemSummary .fieldGroup,
    .smContentArea#smStudentProfile #mailingAddressListContainer .itemSummary .fieldGroup .field.inline
    {
        width: 100% !important;
    }

    #trs_requirementsWidgets .listItem .itemSummary.responsive .fieldGroup .field.inline
    {
        width:100%;
    }

    /* My Profile */
    .smContentArea#smStudentProfile .itemSummary > .fieldGroup,
    .smContentArea#smStudentProfile .itemSummary > .fieldGroup .field.inline
    {
        width: 100% !important;
    }
    /* My Profile */
    .smContentArea#smStudentProfile .itemSummary > .fieldGroup,
    .smContentArea#smStudentProfile .itemSummary > .fieldGroup .field
    {
        padding: 0;
    }

    #registrationContainer .widgetActions > button
    {
        float:none !important;
    }
}

/* Landscape phones and portrait tablets */
@media only screen and (max-width: 767px) 
{
    /* ORIGINAL PIVOT QUERIES */
    .offscreen-menu
    {
        overflow: visible !important;
    }

    .admin-bar .offscreen-menu.top-bar
    {
        top: 0 !important;
    }

    .offscreen-menu > .container > .row:last-child
    {
        display: none;
    }
    /* END ORIGINAL PIVOT QUERIES */

    /* ORIGINALLY - Max - 560px */
    .smContentArea .fieldGroup.half
    {
        width:100%;
        clear:both;
    }

    .smContentArea .isPhone,
    .smStickyFooter .isPhone
    {
        display:block !important;
    }

    .smContentArea .notPhone,
    .smStickyFooter .notPhone
    {
        display:none !important;
    }
    /* END 560px*/

    /* ORIGINALLY 320PX - 600PX */
    .smQuickLinks span.alt-font
    {
        display:none;
    }

    div.main-container
    {
        margin-top:1em !important;
    }

    .smQuickLinks .smProfileLogin
	{
		margin-top:0 !important;
        display:inline-block;
        float:right;
	}

    .smProfileLogin ul.login_wid > li:nth-child(1),
    .smProfileLogin ul.login_wid > li:nth-child(3)
    {
        display:none;
    }

    .smProfileLogin ul.login_wid > li:nth-child(2),
    .smProfileLogin ul.login_wid > li:nth-child(4)
    {
        width:38%;
    }

    .smProfileLogin ul.login_wid > li input[type="text"],
    .smProfileLogin ul.login_wid > li input[type="password"]
    {
        border:solid 1px #dfdfdf !important;
    }

    .smProfileLogin ul.login_wid > li:last-child
    {
        width:20%;
        position:relative;
        top:1px;
        right:6px;
    }

    .top-bar.offscreen-menu.overlay-bar:not(.nav-sticky) .smProfileLogin .login_wid > li > input[type="submit"]
    {
        width:auto;
        min-width:0;
        color:#333 !important;
        border-color:#999;
        padding:8px;
    }

    .top-bar.overlay-bar.offscreen-menu .row.nav-menu .smQuickLinks .smProfileBlock .offscreen-toggle.sm-authenticated
    {
        margin-bottom:-12px;
        border-bottom:0;
    }

    .smHeaderMessageBox
    {
        padding:1em;
        max-width:100%;
        background-color:rgba(0,0,0,.6);
        left:0;
        margin:0;
    }

    .smHeaderMessageBox h1
    {
        font-size:1.1em;
    }

    .smHeaderMessageBox p
    {
        font-size:1em;
    }

    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > input
    {
        width:45% !important;
    }

    .smContentArea .fieldGroup.split.fiftyfifty > div.field > label
    {
        width:32%;
    }

    .smContentArea .fieldGroup
    {
        padding:.5em 0;
    }

    .blockUI.blockMsg.blockElement
    {
        width:auto !important;
        max-width:80%;
    }

    label.error
    {
        display:block;
        float:left;
        clear:both;
        width:100% !important;
    }

    .smContentArea .applicationSidebar
    {
        margin-right:0;
    }

    .top-bar.overlay-bar.offscreen-menu
	{
		overflow:hidden !important;
	}

    .top-bar.overlay-bar.offscreen-menu.showOverflow
    {
        overflow:visible !important;    
    }

    .widgetContent .fieldGroup.full .field.half
    {
        width:100%;
        clear:both;
        padding:5px 10px;
    }
    /* END 320-600 */

    /* ORIGINALLY 320-768 */
    .top-bar.overlay-bar.offscreen-menu .row.nav-menu .smQuickLinks
	{
		top:0;
        margin-bottom:1em;
        border-bottom:solid 1px #dfdfdf;
	}

    .top-bar.overlay-bar.offscreen-menu .row.nav-menu .smQuickLinks .smProfileBlock .offscreen-toggle.sm-authenticated
	{
		right:5px;
        top:1.5em;
	}

    .top-bar.overlay-bar.offscreen-menu .row.nav-menu .smQuickLinks .smProfileBlock .offscreen-toggle.sm-authenticated .smProfileDetails p
	{
		text-align:left;
	}

    .smLandingPageHeader header.signup
	{
		padding-top:0 !important;
	}
	
	.smLandingPageHeader .smHeaderLogin
	{
		position:relative;
        margin-top:-60px;
	}

    /* MENU OVERRIDES */
    #menu-main-menu,
	.mobile-toggle
	{
		display:none;
	}
	
	.smQuickLinks
	{
		display:block;
		text-align:center;
		float:left;
		clear:both;
		width:100%;
		padding:.5em;
	}
	
	.offscreen-toggle
	{
		display:block;
		float:right;
        position:relative;
        top:10px;
	}
	
	.smQuickLinks .offscreen-toggle.sm-authenticated
	{
		margin-top:-21px;
	}
	
	.row.nav-menu .col-sm-3.col-md-2.columns > a
	{
		float:left;
		position:relative;
		top:-5px;
        left:-6px;
	}
	
	section
	{
		padding-top:20px;
	}
	
	.admin-bar .offscreen-container
	{
		height:100vh;
		overflow:auto;
	}
	
	.offscreen-menu.nav-sticky
	{
		max-height:none;
	}
	
	.fixedElement
	{
		position:fixed;
	}
	
	.freezeHtml
	{
		overflow:hidden;
	}

    /* END 320-768 */

    /* ORIGINALLY MAX 600PX */
    footer
    {
        padding-top:80px;
        padding-bottom:0px;
    }

    footer.short .sub
    {
        margin-right:0;
        margin:0 auto;
        bottom:0;
        width:100%;
    }

    footer.short ul
    {
        position:relative;
        top:-80px;
        right:20px;
    }

    .smStickyFooterButtons button
    {
        height:auto;
        padding:0;
    }

    .smStickyFooterButtons button span.badge
    {
        display:block;
        margin:0 auto;
        margin-bottom:0;
        margin-top:.5em;
        max-width:50%;
    }

    /* TRS PIVOT FOOTER */
    .trsFooterMenu
    {
        display:none;
    }

    .footerAddress
    {
        display:block;
        float:left;
        width:100%;
    }

    .footerAddress > img
    {
        float:left;
        min-width:100%;
        border-right:0;
        padding-right:.5em;
        margin-right:.5em;
    }

    .footerAddress > address
    {
        float:left;
        line-height:1.25;
        margin:1em 0;
    }

    .footerAddress > address > span
    {
        display:block;
    }

    /* NEWSROOM POST */
    .postid-196 .offscreen-toggle.sm-authenticated,
    .postid-194 .offscreen-toggle.sm-authenticated,
    .postid-192 .offscreen-toggle.sm-authenticated
    {
        position:relative;
        right:0;
    }

    .postid-196 .main-container,
    .postid-194 .main-container,
    .postid-192 .main-container
    {
        margin-top:30px !important;
    }

    /* Stupid fixes - MAY HAVE WORKAROUND - TRY NEGATIVE 100% MARGIN TRICK */
	.smContentArea  ul.triBlock > li
	{
		width:32.8%;
	}

    .smContentArea .widgetContent div.uploadControl > div:first-child,
	.smContentArea .widgetContent div.uploadControl > div:last-child
	{
		width:100%;
	}

    .smContentArea .widgetContent div.uploadControl > div:last-child
    {
        margin-top:.5em;
    }

    .smContentArea .widgetContent div.uploadControl > div > .field.inline
    {
        border-bottom:solid 1px;
        padding:.5em 0;
        width:100%;
    }

    .smContentArea .widgetContent div.uploadControl > div > .field.inline:after
    {
        content:'' !important;
    }

    .smContentArea .documentsList > .itemDetails > .uploadControl > .uploadArea
    {
        width:100% !important;
        border:dashed 2px;
        padding:.5em;
    }

    .smContentArea .documentsList > .itemDetails > .uploadControl > .uploadArea > label > input[type="file"]
    {
        display:none;
    }

    .smContentArea button.resetUpload.active
    {
        margin-top:1em;
    }
    /* END MAX 600PX*/

    /* ORIGINALLY MAX 649PX */
    .smContentArea .fieldGroup.split.fiftyfifty > .field > select
    {
        max-width:135px;
    }

    .smContentArea .fieldGroup.split.fiftyfifty > .field > select[multiple="multiple"]
    {
        max-width:155px;
    }

    .smContentArea .applicationContent .sectionHeader > div.sectionTitle > h2
    {
        display:block;
        clear:both;
        font-size:1.25em;
    }

    .smContentArea .summaryContainer div.summaryHeader > h3
    {
        max-width:50%;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;    
    }

    .smContentArea .applicationContent .sectionHeader > button,
    .smContentArea .applicationContent .sectionFooter > button
    {
        min-width:50px;
        margin:0px;
        font-size:1em;
        position:relative;
    }

    .smContentArea .appsecItem > span.appsecStatus
    {
        display:block;
    }

    .smContentArea .applicationHeader
    {
        display: none !important;
    }

    .smContentArea .applicationSidebar
    {
        float: left;
        text-align: left;
        display: block;
        overflow:visible;
        padding:2.5px 0px 5px 0px;
    }

    .smContentArea .applicationContentArea
    {
        float: left;
        width: 100%;
        margin-right:-100%;
        padding: 5px 65px 5px 5px;
    }

    .smContentArea .applicationSidebar .appsecList > li
    {
        display: block;
        height: 33px;
        width: 62px;
    }

    .smContentArea .appsecList .appsecItem
    {
        cursor: pointer;
    }

    .smContentArea .appsecList > li.expanded
    {
        background-color: white;
        position:relative;
    }
    
    .smContentArea .appsecList .appsecSpacer,
    .smContentArea .applicationSidebar .appsecList > li > span.appsecTitle,
    .smContentArea .applicationSidebar .appsecList > li.active > span.appsecTitle
    {
        display: none;
    }

    .smContentArea .applicationSidebar .appsecList > li > span.appsecTitle.expanded
    {
        display: block;
        position:absolute;
        left:60px;
        z-index:9999;
        width:116px;
        background-color:rgb(169,169,169);
        color:#fff;
        border:0;
        height:33px;
        border-color:#999999;
        box-shadow: inset 0 0 1px rgb(74, 74, 74),0 0 .5em rgba(0, 0, 0, 0.1);
        border-top-right-radius:2px;
        border-bottom-right-radius:2px;
    }

    .smContentArea .applicationSidebar .appsecList > li.active > span.appsecTitle
    {
        background-color:rgb(59, 141, 189);
    }

    .comboSearch > input[type="text"]
    {
        max-width:125px;
    }

    div.comboSearchResults
    {
        left:80px !important;
    }

    .smContentArea div.validation-summary
    {
        text-align:center;
    }

    .smContentArea button#goToSummaryButton,
    .smContentArea button#submitApplicationButton
    {
        min-height:0;
        margin:0;
        width:auto;
    }

    .smContentArea .applicationSaveSummary
    {
        width:60%;    
    }

    .smContentArea .applicationContentArea .fieldGroup h3
    {
        padding:1em;    
    }

    .smContentArea .applicationFeeSummary
    {
        margin: 0 1em 1em 1em;
    }

    .smContentArea .fieldGroup .field.trsPasswordMessage h3
    {
        padding:.5em;
    }

    .smContentArea .applicationContent .fieldGroup
    {
        padding:0 .5em;
    }

    .smContentArea .fieldGroup h3
    {
        max-width:99% !important;
    }

    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > label
    {
        width:40% !important;
    }

    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.hsDateRange
    {
        text-align:center;
    }

    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.hsDateRange > label
    {
        width:100% !important;
        text-align:center;
        margin-bottom:.5em;
    }

    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.hsDateRange > input[type="text"]
    {
        display:inline;
    }

    .smContentArea .notificationMessage
    {
        margin:0 1em 1em 1em;
    }

    .smContentArea .summaryContainer div.fieldGroup div.field
    {
        width:100%;
        border-bottom:solid 1px #dfdfdf;
    }

    .smContentArea .summaryContainer div.fieldGroup div.field:last-child
    {
        border:0;
    }

    .smContentArea .summaryContainer .fieldGroup .field span.emailAddress
    {
        clear:both;
        width:100%;
    }

    .smContentArea .summaryContainer div.fieldGroup div.field > label,
    .smContentArea .summaryContainer div.fieldGroup div.field > span
    {
        width:45%;
        line-height:1.2;
    }

    .smContentArea ul.detailedList > li .listItem > .itemDetails > .buttonGroup
    {
        width:100%;
        text-align:center;
    }

    .smContentArea ul.detailedList > li .listItem > .itemDetails > .buttonGroup > button
    {
        display:inline-block;
        float:none;
    }

    .smContentArea #sm360Communications .listItem .messageHeader > .fieldGroup.stacked
    {
        margin:.5em 0 .5em 0;
    }

    .smContentArea #sm360Communications .newOrReplyMessage h2
    {
        font-size:1.5em;
        margin:-.25em 0 0 0;
    }

    .smContentArea #sm360Communications ul.detailedList > li .listItem .itemSummary > span:nth-child(1)
    {
        max-width:90%;
        overflow:hidden;
    }

    .smContentArea .flexWidget.half
    {
        width:100%;
    }
    /* END MAX 649PX */

    /* ORIGINALLY MAX 680PX */
    .smContentArea .responsiveMenu ul,
	.smContentArea .responsiveMenu ul li ul
	{
		display:none;
	}
	
	.smContentArea .responsiveMenu ul.active,
	.smContentArea .responsiveMenu ul > li > ul.active
	{
		display:block;	
		float:left;
		clear:both;
		width:100%;
		background-color:#dfdfdf;
	}
	
	.smContentArea .responsiveMenu span.menuIcon
	{
		display:block;
	}
	
	.smContentArea .responsiveMenu > ul > li > a
	{
		border-bottom:solid 1px #afafaf;
	}
	
	.smContentArea .responsiveMenu > ul > li,
	.smContentArea .responsiveMenu > ul > li > a,
	.smContentArea .responsiveMenu ul li ul li,
	.smContentArea .responsiveMenu ul li ul li a
	{
		display:block;
		float:left;
		clear:both;
		width:100%;
	}
	
	.smContentArea .responsiveMenu ul.active
	{
		display:block;
		position:inherit;
	}
    /* END MAX 680PX*/

    /* ORIGINALLY MAX 700PX */
    .smContentArea ul.detailedList li .listItem .itemSummary.responsive.testScores > span
    {
        margin-top:0;
    }

    ul.detailedList .listItem.withActions .listItemActions
    {
        display:block;
        float:left;
        clear:both;
        width:100%;
        margin:1em 0;   
        text-align:center;
    }

    ul.detailedList .listItem.withActions .listItemActions button
    {
        display:inline-block;
        margin: 0 1em;
    }

    ul.detailedList .listItem.withActions .itemDetails .fieldGroup .field
    {
        width:100%;
    }

    ul.detailedList .listItem.withActions .itemDetails .fieldGroup .field label
    {
        width:47%;
        text-align:right;
    }

    ul.detailedList .listItem.withActions .itemDetails .fieldGroup .field span
    {
        width:50%;
    }
    
    /* TWO COL FIELDGROUPS USED IN TEST SCORES - APPLICATION */
    .smContentArea ul.detailedList li .listItem.withActions .itemDetails.cols.two .fieldGroup
    {
        width:100%;
        clear:both;
    }

    .smContentArea ul.detailedList li .listItem.withActions .itemDetails.cols.two .fieldGroup .field,
    .smContentArea ul.detailedList li .listItem.withActions .itemDetails.cols.one .fieldGroup .field
    {
        border-bottom:solid 1px rgb(207,207,207);
    } 

    .smContentArea .flexWidget.large .fieldGroup.splitField > .field.inline.currency /* REFACTOR FOR BROAD USAGE */
    {
        width:100%;
        padding:.5em;
    }

    .smContentArea .fieldGroup div.field.inline.headerField
    {
        padding-bottom:.5em;
    }

    .smContentArea .widgetControls button:last-child
    {
        float:none;
        margin:1em 0;
    }

    /* END MAX 700PX */

    /* ORIGINALLY MAX 768PX */
    .smContentArea #sm360Profile.flexWidget
    {
        width:99.5% !important;
    }

    .smContentArea .flexWidget.small,
    .smContentArea .flexWidget.smid
    {
        width:49.5%;
    }

    .smContentArea .flexWidget.medium,
    .smContentArea .flexWidget.large
    {
        width:99.5%;
    }
    
    .smContentArea  ul.triBlock > li > span
	{
		font-size:1.2em;
	}
	
	.smContentArea  .flexWidget
	{
		min-height:0;
	}

    .smContentArea .flexWidget .widgetContent
    {
        max-height:none;
    }
    
    /* THEME OVERRIDES */
    .nav-container .menu
	{
		padding-top:0;
	}
	
	.page-template-trs-page-sidebar .blog-sidebar
	{
		display:block;
	}
	
	.page-template-trs-page-sidebar .blog-sidebar .menu
	{
		width:100%;
	}
	
	.page-template-trs-page-sidebar .blog-sidebar .menu > li
	{
		padding:0;
		display:block;
		float:left;
		clear:both;
		width:100%;
		margin:0;
	}
	
	.page-template-trs-page-sidebar .blog-sidebar .menu > li > a
	{
		display:block;
		float:left;
		clear:both;
		width:100%;
		padding:1em 0;
	}
	
	.trsContentWrapper .container /* REMOVES EXCESS PADDING WHEN MOBILE */
	{
		padding:0;
	}
	
	.top-bar.offscreen-menu:not(.overlay-header) .row.nav-menu
	{
		padding-top:0;
	}
	
	.top-bar.offscreen-menu:not(.overlay-header) .smQuickLinks
	{
		top:5px;
		width:100%;
	}
	
	.top-bar.offscreen-menu:not(.overlay-header) .smQuickLinks .smProfileBlock
	{
        display:block;
        float:left;
        clear:both;
        width:100%;
	}
	
	.top-bar.offscreen-menu:not(.overlay-header) .smQuickLinks .smProfileBlock .offscreen-toggle.sm-authenticated
	{
		top:0;
		margin-top:0;
		width:100%;
		border-bottom:solid 1px #999;
		padding-bottom:.5em;
		margin-bottom:.5em;
	}

    .offscreen-menu-authenticated 
    {
        overflow: visible !important;
        position: relative !important;
    }

    /* MVS 09-28-15 - OPTIONS UPDATES: Adding options to hold buttons and such */
    .smContentArea ul.detailedList > li .listItem .field.inline.options
    {
        float: none;
        clear: both;
        max-width: none;
        margin-right: 0;
        padding: 1em 0;
        border-top: 1px solid;
        border-bottom: 1px solid;
        text-align: center;
    }
    /* END OPTIONS UPDATES */

    /* END MAX 768PX */

    /* ORIGINALLY MAX 900PX */
    .smContentArea .widgetContent div.uploadControl > div:last-child > div.field.inline:after,
	.smContentArea .widgetContent div.uploadControl > div:last-child > span:after
	{
		content: " | ";
		margin: 0 .5em;
	}

	.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(1),
	.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(2),
	.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(3),
	.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(4),
	.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(5)
	{
		float:left;
		clear:none;
	}
    /* END MAX 900PX */

    /* THIRD POST IN HOME PAGE BLOG LAYOUT IN IPAD PORTRAIT */
    div.row .col-md-4.col-sm-6:last-child
    {
        width:100%;
    }

    div.row .col-md-4.col-sm-6:last-child img
    {
        width:100%;
    }

    .nav-container .menu
    {
        padding-top:0 !important;
    }

    .smStickyFooter .badge .icon
    {
        margin: 0 auto;
        height: 27px;
    }


    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup
    {
        width:100%;    
        border-bottom: solid 1px;
    }

    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field
    {
        width:100%;    
    }

    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field label,
    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field input[type="text"],
    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field select
    {
        width:50%;  
        clear:none;  
    }

    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field label
    {
        float:left;
        text-align:right;
        padding-right:1.5em;
    }

    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field span,
    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field input[type="text"],
    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field select
    {
        float:left;
        max-width:none;
    }

    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field.small input[type="text"],
    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field.medium input[type="text"],
    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field.large input[type="text"]
    {
        max-width:100%;
    }


    .smContentArea #degreeShoppingScenarios .optional_context,
    .smContentArea #degreeShoppingButton .optional_context
    {
        display: none;
    }
    .smContentArea span.isPhone
    {
        display: inline !important;
    }
    .smContentArea .applicationSidebar .appsecList > li
    {
        width: auto;
        min-width: 0;
    }
    .smContentArea .applicationSidebar
    {
        width: auto;
    }
    
    .smContentArea #TestScoreForm .dataDisplayView ul.detailedList
    {
        min-height: 115px;
    }
    
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > .field.no-border,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > .field.no-border,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > .field.no-border,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > .field.no-border
    {
        padding: 0;
        margin: 0;
        border: 0;
    }
    .smContentArea .applicationContent .fieldGroup .fieldGroup,
    .smContentArea#smStudentProfile .fieldGroup .fieldGroup,
    .smContentArea #smStudentBilling .fieldGroup .fieldGroup,
    .smContentArea #FullInquiryContainer .fieldGroup .fieldGroup
    {
        padding: 0;
    }
    
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > .field,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > .field,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > .field,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > .field
    {
        padding: 10px 0;
    }

    
    .smContentArea #portalAppUsername p.fieldText
    {
        width:100%;
        padding:0 1em;
    }

    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > .field > select,
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > input,
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > label,
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > .comboSearch,
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.radioGroup > div,
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > input.portalDate,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > .field > select,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field > input,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field > label,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field > .comboSearch,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field.radioGroup > div,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field > input.portalDate,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > .field > select,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field > input,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field > label,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field > .comboSearch,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field.radioGroup > div,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field > input.portalDate,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > .field > select,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field > input,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field > label,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field > .comboSearch,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field.radioGroup > div,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field > input.portalDate,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field > p.fieldText
    {
        width: 80% !important;
        max-width: 80% !important;
        margin: 0;
        margin-left: 5%;
        text-align: left;
        line-height: 1.5;
    }
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field.hsDateRange
    {
        text-align: center;
    }
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.hsDateRange > label,
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.hsDateRange > input.portalDate,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field.hsDateRange > label,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field.hsDateRange > input.portalDate,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field.hsDateRange > label,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field.hsDateRange > input.portalDate,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field.hsDateRange > label,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field.hsDateRange > input.portalDate
    {
        max-width: 100% !important;
        width: 100% !important;
        margin: 5px 0;
    }
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field.radioGroup > label,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field.radioGroup > label,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field.radioGroup > label,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field.radioGroup > label
    {
        padding: 0;
    }
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > .comboSearch input,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field > .comboSearch input,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field > .comboSearch input,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field > .comboSearch input
    {
        width: 100% !important;
        max-width: 100% !important;
    }
    .smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > label.requiredLabel,
    .smContentArea#smStudentProfile .fieldGroup.split.fiftyfifty > div.field > label.requiredLabel,
    .smContentArea #smStudentBilling .fieldGroup.split.fiftyfifty > div.field > label.requiredLabel,
    .smContentArea #FullInquiryContainer .fieldGroup.split.fiftyfifty > div.field > label.requiredLabel
    {
        line-height: 24px !important;
    }

    .smContentArea .applicationContent .fieldGroup div.comboSearchResults,
    .smContentArea#smStudentProfile .fieldGroup div.comboSearchResults,
    .smContentArea #smStudentBilling .fieldGroup div.comboSearchResults,
    .smContentArea #FullInquiryContainer .fieldGroup div.comboSearchResults
    {
        width: auto;
        max-width: 70%;
    }

    .smContentArea .courseList.phoneDetails > li
    {
        display: block;
        float: left;
        clear: both;
        width: 100%;
        border-style: solid;
        border-width: 1px 0;
        border-color: #999;
        background-color: #fff;
        padding: .5em;
        margin-bottom: .25em;
    }
    .smContentArea .courseList.phoneDetails > li > .courseSummary > span
    {
        display: block;
        float: left;
        clear: both;
        width: 100%;
        margin-bottom: .5em;
        color: rgb(92,92,92);
        font-weight: bold;
    }
    .smContentArea .courseList.phoneDetails.isPhone ul.portalDefault > li
    {
        float: left;
        margin-right: 5%;
    }
    .smContentArea .reqMilestonesList .milestoneHeader
    {
        padding: 1em;
    }
    .smContentArea .reqMilestonesList .milestoneHeader h3
    {
        width: 100%;
        margin: 0;
    }
    .smContentArea .reqMilestonesList .milestoneHeader h3:after
    {
        content: ":";
    }
    .smContentArea .reqMilestonesList .milestoneDetails .cols.two div.col
    {
        width: 100%;
    }
    .smContentArea .reqMilestonesList .milestoneDetails .cols.two div.field.inline > label,
    .smContentArea .reqMilestonesList .milestoneDetails .cols.two div.field.inline > span
    {
        width: 100%;
        margin: 0 0 .5em;
    }
    .smContentArea .courseList.phoneDetails.isPhone ul.portalDefault > li > label
    {
        font-weight: normal !important;
        margin-right: .5em;
    }
    .smContentArea .courseList.phoneDetails.isPhone ul.portalDefault > li > span
    {
        color: #333;
    }

    #trsPageFooter .container > div.row > div
    {
        text-align: center;
        margin-bottom: 2.5%;
    }
    #trsPageFooter .social-icons
    {
        top: 0;
        right: 0;
    }
    #trsPageFooter .container > div.row > div:nth-child(3) span.sub
    {
        top: 0 !important;
    }
    #trsPageFooter .footerAddress address,
    #trsPageFooter .footerAddress img
    {
        float: none;
        clear: both;
        margin-right: 0;
        padding-right: 0;
    }
    #trsPageFooter footer
    {
        padding-top: 7%;
        padding-bottom: 4%;
    }

    
    /* widgetControls Date Range */
    .fieldGroup.inline.dateRange
    {
        display:block;
        float:left;
        clear:both;
        width:100%;
        max-width:70%;
    }

    .fieldGroup.inline.dateRange > div.field.inline:first-child,
    .fieldGroup.inline.dateRange > div.field.inline:last-child
    {
        width:100%;
        float:left;
    }

    .fieldGroup.inline.dateRange > div.field.inline:first-child > label,
    .fieldGroup.inline.dateRange > div.field.inline:last-child > label
    {
        width:50%;
        text-align:right;
    }

    .fieldGroup.inline.dateRange > div.field.inline > button
    {
        width:40%;
        float:right;
    }

    /* OPTION 1 */
    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .fieldGroup:first-child
    {
        float: left;
        clear: none;
        width: 50%;
    }

    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .fieldGroup:last-child
    {
        float: right;
        clear: none;
        width: 50%;
    }

    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .fieldGroup .field.inline
    {
        display: block;
        width: 100%;
        float: left;
        clear: both;
    }

    /* OPTION 2 */
    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .field.inline
    {
        display: inline-block;
        vertical-align: top;
        line-height: 1.5;
        border-right: solid 1px #999;
        margin-right: .5em;
        padding-right: .5em;
        width:29%;
        color:#333;
    }


    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .field.inline > span,
    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .field.inline > button
    {
        clear: left;
    }

    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .field.inline:last-child
    {
        border: none;
    }
    
    #smRecordAttendance.flexWidget.large > .widgetHeader > h2
    {
        display:block !important;
        line-height:1;
        padding:.5em;
    }

    #smRecordAttendance .contextUtility
    {
        min-width:351px;
    }

    .termContainer .termSummary table th,
    .termContainer .termSummary table td
    {
        padding: .5em;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px)
{
    .smContentArea #sm360Holds.flexWidget .widgetContent,
    .smContentArea #sm360FinancialAid.flexWidget .widgetContent
    {
        height: 305px;
    }
    #trsPageFooter .footerAddress img
    {
        min-width: 450px;
        max-width: 70%;
    }
}

@media only screen and (max-width: 768px)
{
    .top-bar .nav-menu .smQuickLinks .alt-font
    {
        display: none !important;
    }
    .row.nav-menu .col-sm-3.col-md-2.columns > a
    {
        top: 0 !important;
        left: 0 !important;
    }
    .smHeaderMessageBox
    {
        padding: 1em !important;
        background-color: rgba(0,0,0,.6) !important;
    }
    .smHeaderMessageBox h1
    {
        font-size: 32px !important;
        line-height: 40px !important;
    }
}

@media only screen and (max-width:990px)
{
    /* USED IN PORTAL HOUSING */
    .checklistCols.dynamic
    {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 2em;
        -moz-column-gap: 2em;
        column-gap: 2em;
        -webkit-column-rule: 1px outset #dfdfdf;
        -moz-column-rule: 1px outset #dfdfdf;
        column-rule: 1px outset #dfdfdf;
    }
}

/* Portrait tablets and small desktops */
@media only screen and (min-width: 768px) and (max-width: 991px) 
{
    
    /* ORIGINALLY - MIN-WIDTH 701PX */
    ul.detailedList .listItem.withActions .itemDetails .fieldGroup .field
    {
        display:inline-block;
        margin: 0 1em 0 0;
        border:0;
        float:none;   
    }

   .smContentArea ul.detailedList li .listItem .itemDetails.cols.two .fieldGroup
    {
        padding:0 .5em;
    }

    .smContentArea ul.detailedList li .listItem .itemDetails.cols.two .fieldGroup .field
    {
        border-bottom:solid 1px rgb(207,207,207);
    }
    /* END MIN 701PX*/

    /* ORIGINALLY MIN-WIDTH 768PX */
    .flexWidget.large .fieldGroup.splitField > .field.inline.currency
    {
        width:auto;
        display:inline-block;
        padding:.5em;
        border-left:solid 1px #dfdfdf;
    }

    .smFancyLinkList h3
    {
        min-height:70px;
        text-align:left;
    }

     /* NEWSROOM HEADERS */
    .postid-196 .top-bar.offscreen-menu,
    .postid-194 .top-bar.offscreen-menu,
    .postid-192 .top-bar.offscreen-menu
    {
        background-color:#ffffff;
    }

    section#post-196,
    section#post-194,
    section#post-192
    {
        padding-top:40px;
    }

    .smContentArea .fieldGroup.split.fiftyfifty > .field > select
    {
        max-width:350px;
    }
    /* END MIN 768PX */

    /* ORIGINALLY MIN-WIDTH 840PX */
    .flexWidget.large .fieldGroup.splitField > .field.inline.currency
    {
        width:auto;
        display:inline-block;
        padding:.5em;
        border-style:solid;
        border-width:0 1px 0 0;
        border-color: #dfdfdf;
        float:none;
    }

    .flexWidget.large .fieldGroup.splitField > .field.inline.currency:last-child
    {
        border-right:0;
    }

    .flexWidget.large.noMinHeight
    {
        min-height:0;
    }
    /* END MIN 840PX */

    /* ORIGINALLY MIN 769PX - MAX 1024 */
    .smContentArea #sm360Profile.flexWidget
    {
        width:99.5% !important;
    }

    .smContentArea .flexWidget.small,
    .smContentArea .flexWidget.smid
    {
        width: 49.5%;
    }

    .smContentArea .flexWidget.medium,
    .smContentArea .flexWidget.large
    {
        width: 99.5%;
    }
    
    .smContentArea ul.triBlock > li > span
	{
		font-size:1.1em;
	}
	
	.smContentArea ul.triBlock > li > label
	{
		bottom:10%;
	}

    .smContentArea .flexWidget .widgetContent
    {
        max-height:none;
    }
    /* END 769-1024 */
    
    .smContentArea #sm360Holds.flexWidget .widgetContent,
    .smContentArea #sm360FinancialAid.flexWidget .widgetContent
    {
        height: 300px;
    }
    
}

@media only screen and (max-width: 992px)
{
    .smContentArea #registrationContainer .widgetControls .radioGroup
    {
        width:100%;
        padding-bottom:.5em;
    }

    .smContentArea #registrationContainer div.radioGroup > label
    {
        float:none;
        padding:.25em 0;
        font-size: .9em;
        margin-top:.25em;    
    }

    .smContentArea #registrationContainer .widgetControls .widgetActions
    {
        width:100%;
        text-align:center;
        border-style:solid;
        border-width:1px 0;
        border-color:#dfdfdf;
    }

    .smContentArea #registrationContainer .widgetControls .widgetActions #SessionSection.visible,
    .smContentArea #registrationContainer .widgetControls .widgetActions #DateSection.visible
    {
        display:inline-block;
        float:none;
        margin-top:0;
    }

    .smContentArea #registrationContainer .widgetControls .widgetActions button
    {
        float:none;
    }
}

/* Can this be merged into 992 or does it have to be separate? */
@media only screen and (min-width: 990px)
{
    #smRecordAttendance .attendanceControls > button > span.btnText
    {
        display:block;
        float:left;
        margin-right:.5em;
    }

    #smRecordAttendance .legendBar
    {
        display:none;
    }

    #smRecordAttendance .actionBar .fRight .moreOptionsWrap
    {
        display:block;
    }

    #smRecordAttendance .moreOptionsBar .moreOptionsWrap,
    #smRecordAttendance .moreOptionsBar .moreOptionsWrap.active,
    #smRecordAttendance .actionBar .fRight > button
    {
        display:none !important;
    }

    #smRecordAttendance .attendanceControls .hoursInput
    {
        float:left;
    }

    #smRecordAttendance .attendanceControls .commentsInput
    {
        float:left;
        margin:.25em;
        width:285px;
        max-width:100%;
    }

    #smRecordAttendance .attendanceControls .commentsInput > input[type="text"]
    {
        width:100%;
    }
}

/* Landscape tablets and medium desktops */
@media only screen and (min-width: 992px) 
{
    
    /* ORIGINALLY MIN 900PX */
    .smContentArea ul.detailedList > li .listItem .itemSummary.responsive
	{
		display:block;
		float:left;
		max-width:85%;
		margin: .5em;
		margin-top:.35em;    
	}

	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > div.field.inline:after,
	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > span:after
	{
		content: " | ";
		margin: 0 .5em;
		color:#999999;
		font-size:1rem;
        	position:relative;
        	top:-1px;
	}

	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > span:last-child:after,
	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > div.field.inline:last-child:after
	{
		content: "";
		margin-left:0;
	}
	
	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > div.field.inline,
	.smContentArea ul.detailedList > li .listItem .itemSummary > span:nth-child(1),
	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > span:nth-child(2),
	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > span:nth-child(3),
	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > span:nth-child(4),
	.smContentArea ul.detailedList > li .listItem .itemSummary.responsive > span:nth-child(5)
	{
		float:left;
		clear:none;
	}

    .smContentArea ul.detailedList .listItem.withActions .itemSummary.responsive
    {
        max-width:80% !important;
    }
    /* END MIN 900PX */

    /* ORIGINALLY MIN-WIDTH 1024PX */
    .smContentArea .flexWidget.small
    {
        width:24.5%;
    }

    .smContentArea .flexWidget.smid
    {
        width:32.75%;
    }

    .smContentArea .flexWidget.medium
    {
        width:49.5%;
    }

    .smContentArea .flexWidget.large
    {
        width:99.5%;
    }

    .smContentArea .flexWidget.large .widgetContent
    {
        max-height:none;
    }    
    /* END MIN 1024PX*/

    /* BOOTSTRAP QUERY */
    .container
    {
        width: 970px;
    }

    .smContentArea ul.detailedList.housingChecklist > li .listItem .itemSummary.responsive
    {
        margin:0;
    }
}

@media only screen and (max-width: 1023px)
{
    div.main-container .smLandingPageHeader header
    {
        padding-top: 115px !important;
    }
    .top-bar .nav-menu > .columns.col-sm-9.col-md-10
    {
        display: none !important;
    }
    .top-bar .nav-menu > .columns
    {
        text-align: right !important;
        padding: 0 !important;
        float: left !important;
    }
    .top-bar .nav-menu > .col-sm-3.col-md-2.columns
    {
        width: 100% !important;
    }
    .top-bar .nav-menu
    {
        margin-bottom: 14px !important;
    }
    div.columns > div.offscreen-toggle
    {
        top: 0 !important;
        margin-right: .5em !important;
        text-align: right !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) 
{
    .smContentArea #sm360Billing .fieldGroup .field
    {
	    padding-bottom: 0;
    }
    .smContentArea#smStudentProfile #mailingAddressListContainer .itemSummary .fieldGroup:nth-child(2)
    {
        clear: none;
    }
}

/* Large desktops and laptops */
@media only screen and (min-width: 1200px) 
{
    /* BOOTSTRAP QUERY */
    .container
    {
        width: 1200px;
    }

    .housingChecklist li .listItem .itemSummary > span:last-of-type
    {
        line-height:2 !important;
    }

    .listItem .itemSummary > span.fullWrap
    {
        clear:none;
    }
}

/* PIVOT PHONE to iPAD PORTRAIT OVERRIDES */
@media only screen and (min-width:320px) and (max-width:767px)
{
    .mobile-toggle
    {
        display:none !important;
    }

    .top-bar .logo.logo-wide
    {
        bottom:7px !important;
    }
}

/* ANYTHING LESS KICKS OVER TO iPAD LANDSCAPE */
@media only screen and (min-width: 768px) {
    #smRegisteredCourses .drawerCourseList > li .icon.inspectIcon
    {
        display: block;
    }
    

    #smRegisteredCourses .drawerCourseList > li .itemContext
    {
        right: 0.25em;
        top: 0.5em;
    }

    #smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon
    {
	    background-image: none;
        overflow: visible;
        width: 0;
        z-index: 0;
        background-color: none;
        border: 0;
        cursor: default;
    }
    #smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu
    {
        position: relative;
        float: right;
        top: 0;
        display: block;
        width: auto;
        padding: 0;
        left: 0;
        min-width: 320px;
        background-color: transparent;
        box-shadow: none;
        border: 0;
    }

    #smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu > div {
        float: right;
    }

    #smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu li
    {
        width: auto;
        clear: none;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        transition: all 300ms ease;
        border-radius: 2px;
        border: 1px solid #3498db;
        font-family: Raleway, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #3498db;
        padding: 0 1em;
        margin: 0 .25em;
        line-height: 2.5;
    }
    #smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu li:hover
    {
        background-color: #3498db;
        color: white;
    }


    .smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field
    {
        border:0;
    }
    
    .degreeContainer .degreeHeader div.field
    {
        display:inline-block;
        width:auto;
        clear:none;
        line-height:1;
        vertical-align:middle;
        min-height:0;
        padding: 0 1em;
    }

    .degreeContainer .degreeHeader div.field label
    {
        margin-right:.5em;
    }

    .degreeContainer .degreeHeader div.field span
    {
        text-align:left;
        clear:none;
    }
}

@media only screen and (min-width:767px) and (max-width:1023px)
{
    #menu-main-menu
   {
        display:none;
    }

    .offscreen-toggle
    {
        display:block;
    }

    nav .columns
    {
        width:100%;
    }

    /* ANCHOR THAT WRAPS THE LOGO */    
    nav .columns > a
    {
        float:left;
    }

    .smProfileLogin
    {
        display:inline-block;
        float:none;
    }

    .top-bar.offscreen-menu.nav-sticky .columns
    {
        padding:0;
    }

    .smQuickLinks > span
    {
        display:none;
    }

    /* STICKY NAV OPTIONS */

    .nav-sticky .smQuickLinks
    {
        text-align:center;
    }

    .top-bar.nav-sticky .nav-menu
    {
        padding-top:0;
    }

    .top-bar.nav-sticky .smQuickLinks
    {
        top:0;
        padding:.5em 0;
        margin:0;
        height:auto;
    }

    .top-bar.nav-sticky .smQuickLinks .smProfileLogin
    {
        margin-top:0;
    }
}

@media only screen and (min-width: 768px)
{
    /* My Profile */
    .smContentArea#smStudentProfile .focusedEditing .flexWidget:not(.activeEditing)
    {
        display: none;
    }
    
    /* My Profile */
    .smContentArea#smStudentProfile .activeEditing
    {
        width: 100% !important;
    }

    /* My Profile */
    .smContentArea#smStudentProfile .activeEditing .detailedList > li:not(.editorShowing)
    {
        display: none;
    }

    /* My Profile */
    .smContentArea#smStudentProfile .activeEditing .widgetContent
    {
        max-height: none;
    }

    /* My Profile */
    .smContentArea#smStudentProfile .activeEditing .fieldGroup.split.fiftyfifty > .field > select,
    .smContentArea#smStudentProfile .activeEditing .fieldGroup.split.fiftyfifty > div.field > input,
    .smContentArea#smStudentProfile .activeEditing .fieldGroup.split.fiftyfifty > div.field > label,
    .smContentArea#smStudentProfile .activeEditing .fieldGroup.split.fiftyfifty > div.field > .comboSearch,
    .smContentArea#smStudentProfile .activeEditing .fieldGroup.split.fiftyfifty > div.field.radioGroup > div,
    .smContentArea#smStudentProfile .activeEditing .fieldGroup.split.fiftyfifty > div.field.hsDateRange,
    .smContentArea#smStudentProfile .activeEditing .fieldGroup.split.fiftyfifty > div.field > input.portalDate
    {
        clear: none;
        width: 45%;
    }
}

@media only screen and (min-width:768px) and (max-width:1023px)
{
    .top-bar.offscreen-menu
    {
        background:rgba(0,0,0,.5);
    }

    .logo.logo-wide
    {
        position:static;
        bottom:-6px !important;
    }

    .social-icons
    {
        margin-right:10px !important;
    }

    .smLandingPageHeader .col-sm-12,
    .smLandingPageHeader .vc_col-sm-12
    {
        margin-top:-40px !important;
    }

    .smLandingPageHeader .vc_col-sm-12.applicantLogin div.background-image-holder
    {
        top:0;
    }

    .background-image-holder.parallax-background
    {
        top:-2%;
    }

    .top-bar.overlay-bar.offscreen-menu .smProfileHeader .smProfileDetails
    {
        color:#333;
    }

    .top-bar.overlay-bar.offscreen-menu .smProfileHeader
    {
        display:block;
        float:left;
        clear:both;
        width:100%;
    }

    .offscreen-container .smMenuContainer,
    .offscreen-container-authenticated .smMenuContainer
    {
        padding:0;
    }

    .top-bar.nav-sticky .nav-menu div.offscreen-toggle
    {
        margin-top:0;
    }

    .smLandingPageHeader .smHeaderLogin
    {
        margin-top:7.5em;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1023px)
{
    .smLandingPageHeader header.signup
    {
        padding-top: 160px !important;
    }
}

@media only screen and (min-width:770px) and (max-width:1023px)
{
    .top-bar.overlay-bar.offscreen-menu .smProfileHeader .smProfileDetails
    {
        color:#fff;
    }

    .freezeHtml
    {
        overflow:hidden;
    }
}

/* PIVOT iPAD OVERRIDES */
/* PIVOT iPad SPECIFIC */
@media only screen and (max-device-width: 786px) and (orientation: portrait)
{
    .main-container.reveal-nav, .footer-container.reveal-nav
    {
        -webkit-transform: translate3d(-280px, 0, 0);
        -moz-transform: translate3d(-280px, 0, 0);
        transform: translate3d(-280px, 0, 0);
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)
{
    .flex-direction-nav
    {
        display: none;
    }

    .product-right .product-image
    {
        max-width: 640px;
        right: -100px;
        bottom: 50px;
    }

    .feature-box .inner
    {
        width: 100%;
    }

    .blog-snippet-2 li .icon
    {
        width: 15%;
    }

    .blog-snippet-2 li .title
    {
        width: 85%;
    }

    .map-overlay .col-md-4
    {
        width: 40%;
    }

    .map-overlay .col-md-offset-4
    {
        margin-left: 30%;
    }

    .hero-divider
    {
        height: auto;
        padding: 80px 0px 90px 0px;
    }

    .hero-divider .video-wrapper
    {
        display: none;
    }

    .video-wrapper
    {
        display: none;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)
{
    .top-bar
    {
        position: absolute;
    }

    nav
    {
        max-height: 80px;
        overflow: hidden;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        transition: all 500ms ease;
    }

    nav .logo
    {
        margin-bottom: 32px;
    }

    nav .columns
    {
        width: 100%;
    }

    nav .social-icons
    {
        display: none;
    }

    .open-nav
    {
        max-height: 8000px;
    }

    .nav-menu
    {
        padding-top: 24px;
    }

    .top-bar .logo
    {
        bottom: 0px;
    }

    .top-bar .logo.logo-wide
    {
        bottom: 10px;
        margin-bottom: 22px;
    }

    .mobile-toggle
    {
        font-size: 32px;
        display: none;
        position: absolute;
        top: 24px;
        right: 16px;
        cursor: pointer;
        color: #333;
    }

    .overlay-bar
    {
        background: #fff;
    }

    .overlay-bar .menu li a
    {
        color: #777;
        padding-bottom: 0px;
    }

    .overlay-bar .menu li a:hover
    {
        color: #333;
    }

    .overlay-bar .has-dropdown:after
    {
        color: #333;
    }

    .has-dropdown
    {
        bottom: 0px;
    }

    .menu
    {
        top: 0px;
    }

    .menu li
    {
        float: none;
        margin-bottom: 16px;
        margin-right: 0px;
    }

    .subnav
    {
        position: relative;
        background: none;
    }

    .subnav li
    {
        margin-bottom: 0px;
    }

    .subnav .col-md-3
    {
        margin-bottom: 32px;
    }

    .flex-direction-nav
    {
        display: none;
    }

    header.signup .photo-form-wrapper
    {
        margin-bottom: 32px;
    }

    .product-right .btn
    {
        margin-bottom: 16px;
    }

    .product-right .product-image
    {
        right: -320px;
        bottom: 60px;
        max-width: 670px;
    }

    .wood-divider.product-right .product-image
    {
        position: relative;
        top: -5%;
    }

    .selector-tabs li
    {
        margin-bottom: 16px;
    }

    .client-row img
    {
        max-width: 100px;
    }

    .inline-video-wrapper
    {
        margin-top: 32px;
    }

    .pricing-tables .no-pad
    {
        padding: 0px 15px;
    }

    .pricing-tables .no-pad-left
    {
        padding-left: 15px;
    }

    .pricing-tables .no-pad-right
    {
        padding-right: 15px;
    }

    .pricing-table
    {
        margin-bottom: 30px;
        border-radius: 25px !important;
        border: 2px solid rgba(255,255,255,0.2) !important;
    }

    footer.details .social-icons li
    {
        margin-bottom: 16px;
    }

    .blog-snippet-2 li .icon
    {
        width: 15%;
    }

    .blog-snippet-2 li .title
    {
        width: 85%;
    }

    .hero-divider
    {
        height: auto;
        padding: 80px 0px 90px 0px;
    }

    .hero-divider .video-wrapper
    {
        display: none;
    }

    .video-wrapper
    {
        display: none;
    }

    .instafeed ul li:nth-child(n+5)
    {
        display: none;
    }

    .instafeed ul li
    {
        width: 25%;
    }

    .project.col-sm-4
    {
        width: 50%;
    }

    #submission-strip
    {
        min-width: 300px;
        max-width: 300px;
    }

    footer.short-2
    {
        padding-bottom: 0px;
    }

    footer.short-2 span
    {
        margin-right: 32px;
    }

    footer.short-2 .contact-action
    {
        position: relative;
        width: 100%;
        height: 150px;
        margin-top: 80px;
    }

    footer.short-2 .align-vertical
    {
        padding-top: 64px !important;
    }

    .row.nav-menu div.col-sm-3.col-md-2.columns
    {
        padding:.5em 0;
    }

    nav.top-bar .logo.logo-wide
    {
        bottom:0;
        margin-bottom:0;
    }

    div.offscreen-toggle
    {
        position:relative;
        top:15px;
        right:10px;
    }

    div.smQuickLinks
    {
        display:block;
        position:static;
        margin-top:1em;
        border-bottom:solid 1px #999;
        padding-bottom:.5em;
        text-align:center;
        min-height:40px;
    }

    div.smQuickLinks .smProfileLogin
    {
        margin-top:0;
    }

    .top-bar.offscreen-menu.overlay-bar:not(.nav-sticky) .smProfileLogin .login_wid > li > input[type="submit"]
    {
        border-color:#999;
        color:#999;
    }

    div.smQuickLinks span
    {
        display:none;
    }

    nav.top-bar
    {
        max-height:none;
    }

    .nav-menu .offscreen-toggle.sm-authenticated
    {
        top:-.3em;
    }

    .offscreen-menu
    {
        overflow:hidden !important;
    }

    .offscreen-menu.showOverflow
    {
        overflow:visible !important;
    }

    .freezeHtml
    {
        overflow:hidden;  
        position:fixed;  
    }

    .nav-menu div.offscreen-toggle.sm-authenticated
    {
        display:block;
        float:left;
        clear:both;
        width:100%;
        padding:0;
        right:0;
    }

    .nav-menu .offscreen-toggle.sm-authenticated > .icon
    {
        display:block;
    }
    
    div.smQuickLinks span
    {
        display: inline;
    }
    
}

/* Content-based breaks */
@media only screen and (max-width:530px)
{
    #smRecordAttendance table td.attendDetails .field > label
    {
        margin:0;
    }

    #smRecordAttendance table td.attendDetails .myStatus
    {
        margin-top:-.5em;
        clear:left;
    }

    #smRecordAttendance .contextUtility .selectedDate
    {
        font-size:.8em;
    }
}

@media only screen and (min-width:250px) and (max-width:650px)
{
    #newStyle > li > .listItem .itemDetails ul.scheduleDetails .field.inline
    {
        width:27%;
    }
}

@media only screen and (max-width:600px)
{
    .smContentArea .flexWidget > .widgetHeader
    {
        text-align: left;
    }
    .smContentArea .flexWidget > .widgetHeader > h2
    {
        margin-left:.5em;
    }
    .smContentArea .flexWidget > .widgetHeader span.widgetIcon + h2
    {
        margin-left: 3em;
    }
    .smRegistrationCourseSearch .superSearch input[type="text"]
    {
        width:100%;
        margin-bottom:.5em;
    }

    .flexWidget .widgetHeader div.fieldContainer
    {
        position:static;
        text-align:center;
        margin-top:.5em;
        margin-bottom:-.5em;
    }

    .flexWidget .widgetHeader div.fieldContainer > div.field
    {
        display:inline-block;
        vertical-align:middle;
    }
    
    /* SOME AD-HOC STYLING - TURN THIS INTO FINAL CLASSING AND STANDARDIZE - OTHERWISE THIS IS ONLY FOR THE HOUSING DEMO TO SEG */
    .widgetControls.standardPattern > .fieldGroup.inline:last-of-type
    {
        width:100%;
        clear:both;
    }
}

@media only screen and (max-width:694px)
{
    .regStudent .isPhone .fieldGroup div.field
    {
        width:100%;
        float:left;
        clear:both;
    }

    .regStudent .isPhone .fieldGroup div.field label
    {
        clear:none;
        width:50%;
        text-align:right;
        padding-right:1em;
    }

    .regStudent .isPhone .fieldGroup div.field span
    {
        float:right;
        clear:none;
        width:50%;
    }

    .regPaymentOptions .fieldGroup
    {
        text-align:center;    
    }

    .regPaymentOptions .fieldGroup .field
    {
        margin:0em;
    }

    .regPaymentOptions .fieldGroup .field.inline label
    {
        margin-right:.5em;
    }

    .regPaymentOptions .fieldGroup .field.inline span,
    .regPaymentOptions .fieldGroup .field.inline select
    {
        margin:0;
        max-width:40%;
    }

    #reg_singleStudentSummary > h3
    {
        text-align:center;
        margin:0;
    }

    

    #smPreviewCourses .smCoursesCart .smSlideupHeader > h3
    {
        float:none;
        text-align:center;
        margin-bottom:.5em;    
    }

    #smPreviewCourses .smCoursesCart .smSlideupHeader > .fRight
    {
         float:left;
        clear:both;
        width:100%;
        margin-bottom:.5em;   
    }

    #smPreviewCourses .smCoursesCart .smSlideupHeader > .fRight > button:first-child
    {
        float:left;
    }

    #smPreviewCourses .smCoursesCart .smSlideupHeader > .fRight > button:last-child
    {
        float:right;
    }

}

@media only screen and (min-width:695px) and (max-width:767px)
{
    .regStudent .isPhone .fieldGroup div.field
    {
        margin: 0 1em;
        line-height:1;
        border:0;
    }
}

@media only screen and (max-height: 767px)
{
    .top-bar.offscreen-menu.nav-sticky
    {
        position: absolute !important;
    }
}

@media only screen and (max-width:767px)
{
    .checklistCols.dynamic
    {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-gap: 2em;
        -moz-column-gap: 2em;
        column-gap: 2em;
        -webkit-column-rule: none;
        -moz-column-rule: none;
        column-rule: none;
        max-width:90%;
        margin:0 auto;
        float:none;
    }
}

@media only screen and (max-width:768px)
{
    #dailySessionSelection
    {
        max-width:280px;
        white-space:normal;
        clear:both;
    }
}

@media only screen and (max-width:991px)
{
    .widgetSample
    {
        margin-top:1em;
    }
}

@media only screen and (max-width: 1199px)
{
    #smStudentFinancialAid #packageForm div.flexWidget:first-child  .widgetContent
    {
        max-height: none;
    }
    #smStudentFinancialAid #packageForm div.flexWidget:first-child  .widgetContent .fieldGroup.half
    {
        width: 100%;
    }
}

@media only print
{
    .smContentArea,
    .smContentArea *
    {
        font-size:8pt !important;
    }    

    .nav-container,
    .footer-container,
    .aq-block-aq_page_header_block
    {
        display:none;
    }

    .flexWidget
    {
        height:auto;
        overflow:visible;
    }

    .smContentArea .flexWidget .widgetContent
    {
        max-height:none;
    }

    .termSummary,
    .termContainer
    {
        page-break-inside: avoid;
    }

    .printWatermark
    {
        display:block;
        position:absolute;
        top:45px;
        z-index:999999;
        color:#999 !important;
        opacity:.15;
        width:100%;
        background-image:url('../img/svg/unofficial_transcript_watermark.svg') !important;
        background-size:80% !important;
        background-repeat:repeat !important;    
        height:100%;
        left:0;
    }

    .degreeContainer .degreeHeader div.field
    {
        min-height:0;
    }

    .degreeContainer .termHeader
    {
        background-color:#ebebeb !important;
        padding:.25em 0;
    }

    .smContentArea .widgetHeader h2
    {
        display:inline-block !important;
        float:left !important;
        clear:both;
        width:100%;
        font-weight:bold !important;
        color:#000 !important;
        background-color:#cacaca !important;
        font-size:10pt !important;
        line-height:3 !important;
    }

    .smContentArea .widgetHeader.primaryHeader h2
    {
        font-size:12pt !important;
        background-color:#000 !important;
        color:#fff !important;
        line-height:25pt !important;
    }

    .printOnly
    {
        display:inline-block;
    }

    .primaryHeader span.printOnly,
    #printedOnDate
    {
        color:#fff !important;
        font-size:10pt !important;
    }

    #printTranscript
    {
        display:none;
    }

    .primaryHeader span.printOnly
    {
        position:absolute;
        right:10px;
    }
}
