@import url("./Global_CSS/sm.u4-theme-base.css");
@import url("./Global_CSS/sm.theme-overrides.css");
@import url("./Global_CSS/sm.portal-accents.css");
@import url("./Global_CSS/sm.portal-content.css");
@import url("./Global_CSS/sm.portal-icons.css");
@import url("./Global_CSS/sm.portal-queries-new.css");
@import url("./Global_CSS/sm.portal-temporary.css");

body {
    font-family:Open Sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* PORTAL HEADER STYLES HERE NOW - ONLY FOR DEVs, NOT DEPLOYMENT */
.portalHeader
{
    display:block;
    box-sizing:border-box;
    float:left;
    clear:both;
    width:100%;
    padding:.5em;
    font-family:arial, sans-serif;
    font-weight:bold;
    color:white;
    background-color:rgb(126, 126, 126);
}

.portalHeader > span.menuIcon
{
    float:left;
}

#mainLogo
{
    float:left;
}

#loginForm
{
    padding-top:3px;
    margin-right:30px;
    display:none;
}

#linkMenu
{
    position:absolute;
    left:0;
    top:41px;
    border-style:solid;
    border-width:0 1px 1px 0px;
    border-color:#999999;
    background-color:#ffffff;
    box-shadow:0px 6px 1.5em rgba(0,0,0,.3);
}

#linkMenu ul > li > a
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:.5em 1em;
    box-sizing:border-box;
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#999999;
}

#linkMenu ul > li:last-child a
{
    border:0;
}

#linkMenu ul > li > a:hover
{
    
}

@media (max-width:480px)
{
    .portalHeader
    {
        display:none !important;    
    }
}


/* END PORTAL HEADER STYLES */
