@import url('sm.portal-accents.css');
@import url('sm.portal-icons.css');
/* PORTAL PARENT CONTAINER STRUCTURE */
/* CSS HELPERS */
/* Simple Clearfix */
.smContentArea .cf:before,
.smContentArea .cf:after,
.smStickyFooter .cf:before,
.smStickyFooter .cf:after
{
    content: " ";
    display: table;
}

.smContentArea .cf:after,
.smStickyFooter .cf:after
{
    clear: both;
}

.smContentArea
{
	font-size:15px;
    max-width:1200px; /* THIS MATCHES THE THEMES WIDEST BREAKPOINT */
    margin:0 auto;
    padding:1em 0;
}

/* THE THEME MAX WIDTH BREAKPOINT IS 1200px -- TOO WIDE FOR MAIN CONTENT WITHOUT A SIDEBAR - THIS MITIGATES THAT ISSUE */
.smContentArea .optimalReading
{
    max-width:960px !important;
    margin:0 auto !important;
    float:none !important;
}

.u4sm-theme .fullWidth
{
    max-width: 100% !important;
    width: 100% !important;
}

.smContentArea button,
.smModal button
{
    padding:0 1em;
    min-width:0;
    margin:0 .25em;
    line-height:2.5;
    vertical-align:top;
}

.smContentArea button[disabled],
.smContentArea button[disabled="disabled"],
.smStickyFooter button[disabled="true"]
{
	opacity:.5;
	cursor:not-allowed;
}

.smContentArea div,
.smContentArea li,
.smContentArea span,
.smContentArea p,
.smContentArea ul,
.smStickyFooter div,
.smStickyFooter li,
.smStickyFooter span,
.smStickyFooter p,
.smStickyFooter ul
{
	box-sizing: border-box;
}

/* GREAT PORTAL FORM HEADING FORMAT */
.smContentArea form h2,
.smContentArea .displayHeader h2,
.smContentArea .displayHeader h3,
.smContentArea form h3
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    max-width: 95%;
    text-align:center;
    margin:.25em 0;
    color:rgb(51,51,51);
}

.smContentArea form h2,
.smContentArea form .fieldGroup h2,
.smContentArea .displayHeader h2
{
    font-size:22.5px; /* 22.5px */
    line-height:44px;
    font-weight:300;
}

.smContentArea form h3,
.smContentArea form .fieldGroup h3,
.smContentArea .displayHeader h3
{
    font-size:18px; /* 18px */
    line-height:32px;
    font-weight:normal;
}

/* ALL OF THE 'MESSAGING' SHOULD BE ABSTRACTED TO USE THE NEWER FORMULA BELOW (SEE 12/1/2016 COMMENT) */

/* PORTAL MESSAGE BOXES - USING TWO DIFFERENT ELEMENTS - SHOULD BE STANDARDIZED TO NEW FORMAT */
.smContentArea .applicationContentArea .templateContainer > div > h3,
.smContentArea #AccountContainer > div > h3,
.smContentArea .friendlyMessage
{
    padding:1em 10em;
    text-align:center;
    line-height:1.5;
    background-color:#E7FFDA;
    color:green;
    max-width:100%;
}

/* USED IN 
D:\DEV\CAMSMosaicv1\CAMS\Sites\WordPressPlugin\plugins\U4SM-adaptor\assets\pages\Prototype-CareerAdvancement.html
D:\DEV\CAMSMosaicv1\CAMS\Sites\WordPressPlugin\plugins\U4SM-adaptor\assets\templates\ApplicationTemplate.html */

.smContentArea .notificationMessage
{
    display:block;
    padding:.5em;
    text-align:center;
    background-color:rgb(255,224,172);
    color:rgb(125,68,6);
    font-size:.8em;
    margin: 0 0 1em 0;
}

/* NOT USED 
.smContentArea .warningMessage
{
    padding:1em;
    text-align:center;
    line-height:1.5;
    background-color:#ffdada;
    color:#800000;
    margin-left:.25em;
    max-width:100%;
} */

/* USED IN PortalApplicationTemplate.html */
.smContentArea .reviewMessage
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:1em;
    text-align:center;
    line-height:1.5;
    color:#756c2f;
    background-color:#FFFFAB;
    border:solid 1px #AA0;
}


/* NOT USED 
.smContentArea .noItemsMessage
{
    text-align:center;
    padding:.5em;
    color:#333;
    font-style:italic;
}
*/

/* NEW MESSAGES FORMULA - ADDED 12/1/2016 */
/* Generic Actions/Messaing Widget */
p.msg
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:1em;
    color:#000;
    text-align:left;
    font-weight:400;
    font-size:15px;
    margin:0;
    background-repeat:no-repeat;
}

/* SEVERITY - 0 - JUST ADDITIONAL INFO */
p.msg.info
{
    background-image: url('../img/svg/icon_msgInfo.svg');
    background-color: #e9f6ff;
    color:#002a89;
}

/* SEVERITY - 1 - "HEADS UP" */
p.msg.alert
{
    background-image: url('../img/svg/icon_msgAlert.svg');
    background-color: #fffcdb;
    color:#766d00;
}

/* SEVERITY - 2 - "CAUTION" */
p.msg.warning
{
    background-image: url('../img/svg/icon_msgWarning.svg');
    background-color: #fff2e2;
    color:#d95a00;
}

/* SEVERITY - 3 - "ERORR!" */
p.msg.error
{
    background-image: url('../img/svg/icon_msgError.svg');
    background-color: #fff5f5;
    color:#F00;
}

/* NOTE TO SELF: WHY THE NARROW SCOPING HERE? WHY NOT USE THE ABOVE? */
.widgetContent p.msg
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:1em;
    color:#000;
    text-align:left;
    font-weight:400;
    font-size:15px;
    margin:0;
}

/* SEVERITY - 0 - JUST ADDITIONAL INFO */
.widgetContent p.msg.info
{
    background-image:url(../img/svg/icon_msgInfo.svg);
    background-color: #e9f6ff; /* THEME IRRESPECTIVE */
    color:#002a89; /* THEME IRRESPECTIVE */
}

/* SEVERITY - 1 - "HEADS UP" */
.widgetContent p.msg.alert
{
    background-image:url(../img/svg/icon_msgAlert.svg);
    background-color: #fffcdb; /* THEME IRRESPECTIVE */
    color:#766d00; /* THEME IRRESPECTIVE */
}

/* SEVERITY - 2 - "CAUTION" */
.widgetContent p.msg.warning
{
    background-image:url(../img/svg/icon_msgWarning.svg);
    background-color: #fff2e2; /* THEME IRRESPECTIVE */
    color:#d95a00; /* THEME IRRESPECTIVE */
}

/* SEVERITY - 3 - "ERORR!" */
.widgetContent p.msg.error
{
    background-image:url(../img/svg/icon_msgError.svg);
    background-color: #fff5f5; /* THEME IRRESPECTIVE */
    color:#F00; /* THEME IRRESPECTIVE */
}


.widgetContent .msg.whiteBg
{
    background-color:#fff !important;
}

.widgetContent .msg.lightBg
{
    background-color:#f7f7f7 !important;
}

.widgetContent .msg.medBg
{
    background-color:#f4f4f4 !important;
}

.widgetContent .msg > span
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    line-height:1.25;
}

.widgetContent .msg > span > button
{
    background-color:#fff;
    border-color:#999;
}

.widgetContent .msg > span > button:hover
{
    color:#000;
}

.widgetContent .msg.conditional
{
    display:none;
}

.widgetContent .msg.conditional.active
{
    display:block;
}

.widgetContent .msg.info,
.widgetContent .msg.alert,
.widgetContent .msg.warning,
.widgetContent .msg.error
{
    background-repeat:no-repeat;
    background-size:3rem;
    background-position:1rem 50%;
    padding-left:3.5em;
    line-height:1.5;
}

.widgetContent .msg.info > span,
.widgetContent .msg.alert > span,
.widgetContent .msg.warning > span,
.widgetContent .msg.error > span
{
    display:block;
    padding-left:2.5em;
}


/* END 'MESSAGES' PATTERN */

/* BEGIN WIDGET 'CONTENT BLOCKS' */
/* GENERIC CONTAINERS FOR WIDGET CONTENT AREA */
.widgetContent .contentBlock
{
    display:inline-block;
    float:none;
    clear:both;
    width:100%;
    padding:0 1em;
    background-color:#fff;
    border-top:solid 1px #
}

/* SIZING OVERRIDE FOR BEST READING LEGIBILITY */

.widgetContent .contentBlock.optimalReading
{
    padding-top:.5em;
}

/* ALL H2's WITHIN */
.widgetContent .contentBlock > h2
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    line-height:1.25;
    padding:.25em;
    text-align:center;
}

/* COLOR OVERIDES */
.widgetContent .contentBlock.whiteBg
{
    background-color: #fff;
}

.widgetContent .contentBlock.lightBg
{
    background-color:#f7f7f7;
}

.widgetContent .contentBlock.medBg
{
    background-color:#f4f4f4;
}
/* END WIDGET 'CONTENT BLOCKS' */

/* ACTIONLIST CONSTRUCT - SCOPED AS CHILD OF WIDGET CONTENT */
/* GENERIC ACTION LIST - CAN BE CHECKBOXES OR RADIO BUTTONS - LIKE A ToS FORM */
.widgetContent .actionList ul > li
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:.5em;
    border-bottom:solid 1px #efefef;
}

.widgetContent .actionList li .field
{
    display:inline-block;
    float:none;
    width:100%;
    margin:0 auto;
}

.widgetContent .actionList li .field > input[type="checkbox"],
.widgetContent .actionList li .field > label

{
    float:left;
    clear:none;
}

.widgetContent .actionList li .field > label
{
    margin-bottom:0;
}

.widgetContent .actionList li .field > input[type="checkbox"]
{
    margin:.5em;
}

.widgetContent .actionList li .field > label
{
    font-weight:normal;
    max-width:90%;
    padding-left:1em;
    text-align:left;
    line-height:1.5;
}

.widgetContent .actionList li .field > label:after
{
    content:none;
}

.widgetContent .actionList .actionsGroup
{
    display:inline-block;
    text-align:center;
    width:100%;
    padding:.5em;
}

.widgetContent .actionList .actionsGroup button
{
    margin:.5em 0 0 0;
}

/* END ACTIONLIST */


/* DYNAMIC COLUMN OF CHECKBOX CHOICES - AS USED IN PORTAL HOUSING FORM FOR SPECIAL NEEDS & OPTIONS */
/* CHECKLIST RENDERING */
.checklistCols div.field.checkBox
{
    margin:.5em 0;
    text-align:left;
}

.checklistCols div.field.checkBox > label
{
    display:inline;
    float:none;
    font-weight:normal;
    max-width:90%;
    line-height:1.25;
}

.checklistCols div.field.checkBox > label:after
{
    content:none;
}

.checklistCols div.field.checkBox > input[type="checkbox"]
{
    display:inline;
}

div.field input[type="checkbox"]~label[for]
{
    cursor:pointer;
}

/* DYNAMIC COLUMNS FOR CHECKLISTS */
.checklistCols.dynamic
{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 2em;
    -moz-column-gap: 2em;
    column-gap: 2em;
    -webkit-column-rule: 1px outset #dfdfdf;
    -moz-column-rule: 1px outset #dfdfdf;
    column-rule: 1px outset #dfdfdf;
    padding:.5em 1em;
}

.checklistCols.dynamic div.field.checkBox
{
    float: none;
    border: none;
}

.smContentArea .fieldGroup.split.fiftyfifty div.field.checkboxList > label
{
    width:100%;
    text-align:center;  
}

.smContentArea .fieldGroup.split.fiftyfifty div.field.checkboxList > label:after
{
    content:none !important;
}


/* END DYNAMIC COLUMN CHECKBOXES */
/* HOUSING & ROOMMATE PREFERENCE DETAILED LIST OVERRIDES */
/* HOUSING PREFERENCES */
.smContentArea ul.detailedList.housingPreferences li .listItem .itemSummary,
.smContentArea ul.detailedList.roommatePreferences li .listItem .itemSummary
{
    max-width:100%;
    width:100%;
    margin:0;
    position:relative;
}

.housingPreferences li .listItem .itemSummary > button
{
    float:right;
}

.housingPreferences li .listItem .itemSummary > span.fullWrap:after
{
    content:none;
}

/* THIS OVERRIDES THE NORMAL ELLIPSE BEHAVIOR AS AN ALTERNATIVE TO HIDING TEXT - ONLY USED IN PORTAL HOUSING CURRENTLY */
.smContentArea ul.detailedList > li .listItem .itemSummary > span.fullWrap
{
    overflow:visible !important;
    white-space:normal !important;
    line-height:1.25 !important;
    clear:both;
    max-width:100%;
    margin-top:.25em;
}

.smContentArea ul.detailedList.housingPreferences > li .listItem .itemSummary > span.fullWrap,
.smContentArea ul.detailedList.roommatePreferences > li .listItem .itemSummary > span.fullWrap
{
    max-width:80%;
}

.housingPreferences li .listItem .itemSummary > button,
.roommatePreferences li .listItem .itemSummary > button,
.flexWidget .widgetHeader div.fieldContainer
{
    position:absolute;
    top:50%;
    right:.5em;
    transform: translateY(-50%);
}

/* FOR HOUSING & ROOMMATE PREFERENCE FORMS - FIFTY-FIFTY SPLIT */
/* THESE ARE TOO NARROWLY SCOPED */
.smContentArea .housingForm div.field > select,
.smContentArea .roommateForm div.field > select
{
    clear:none;
}

.fieldGroup.split.fiftyfifty.housingForm > div.field:nth-child(odd)
{
    background-color:#efefef;
}

.fieldGroup.split.fiftyfifty.housingForm
{
    margin-bottom:.5em;
}

.housingForm .checklistCols > div > div.field.checkBox,
.housingForm .checklistCols > div > div.field.checkBox > label
{
    width:100%;
}

.housingPreferences li .listItem.itemComplete > .itemSummary
{
    max-width:88%;
}

/* FIELD CONTAINER CONSTRUCT */
.flexWidget .widgetHeader div.fieldContainer div.field
{
    
}

.flexWidget .widgetHeader div.fieldContainer div.field > label
{
    color:#fff;
    font-weight:normal;
    margin:0 .5em;
}

.flexWidget .widgetHeader div.fieldContainer div.field > label:after
{
    content:none;
}

.flexWidget .widgetHeader div.fieldContainer div.field > input[type="checkbox"]
{
    float:left;
    margin:.5em 0;
}
/* END FIELD CONTAINER CONSTRUCT */


/* END HOUSING & ROOMMATE PREFERENCE DETAILED LIST OVERRIDES */
.smContentArea span.field-validation-valid
{
	display:none;
}

.smContentArea input[type="text"],
.smContentArea select,
.smContentArea input[type="password"],
.smContentArea textarea,
.smStickyFooter input[type="text"],
.smStickyFooter select,
.smStickyFooter input[type="password"],
.smStickyFooter textarea
{
    padding: 0 .5em;
    border-style:solid;
    border-width:1px;
    border-color:rgb(207,207,207);
    -webkit-appearance:none;
    border-radius:0;
}

.smContentArea input[type="text"]:focus,
.smContentArea input[type="password"]:focus,
.smContentArea textarea:focus,
.smContentArea select:focus,
.smStickyFooter input[type="text"]:focus,
.smStickyFooter input[type="password"]:focus,
.smStickyFooter textarea:focus,
.smStickyFooter select:focus
{
    border-color:#7d7d7d;
}

.smContentArea select,
.smStickyFooter select
{
    -webkit-appearance:none;
    -moz-appearance:none;
    padding: 0 .5em;
    border-style:solid;
    border-width:1px;
    border-radius:0;
    background-color:#fff;
    color:rgb(51,51,51);
    background-image: url('../img/svg/icon_disclosureDown.svg');
    background-repeat:no-repeat;
    background-size:20px;
    padding-right:30px;
    background-position:95% 50%;
}

.smContentArea select[multiple],
.smStickyFooter select[multiple]
{
    background-image:none;
}

.smContentArea input.hasDatepicker,
.smContentArea input.date,
.smModal input.hasDatepicker,
.smModal input.date,
.smContentArea input.portalDate,
.smModal input.portalDate,
.smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > input.portalDate,
.smContentArea input[type="text"].portalDate,
.smStickyFooter input.hasDatepicker,
.smStickyFooter input.date,
.smStickyFooter input.portalDate,
.smStickyFooter .applicationContent .fieldGroup.split.fiftyfifty > div.field > input.portalDate,
.smStickyFooter input[type="text"].portalDate
{
	max-width:105px !important;
}

.smContentArea input.hasDatepicker,
.smContentArea input.date,
.smModal input.hasDatepicker,
.smModal input.date
{
    /* float:left !important; WHY DO I NEED THIS? */
}


/* TEMP LOGIN/LOGOUT BUTTON */
.smLoginBlock a.smButton
{
    color:#000;
    background-color:#fff;
    padding:.25em .5em;
    margin: 1em .5em;
    display:block;
}

.smLoginBlock a.smButton:hover
{
    background-color:rgb(59, 141, 189);
    color:#fff;
}

/* PORTAL HELPERS - FLOAT, CENTER, ETC... */
.smContentArea .tLeft,
.smModal .tLeft,
.smStickyFooter .tLeft
{
	text-align:left !important;
}

.smContentArea .tRight,
.smModal .tRight,
.smStickyFooter .tRight
{
	text-align:right !important;
}

.smContentArea .tCenter,
.smModal .tCenter,
.smStickyFooter .tCenter
{
	text-align:center !important;
}

.smContentArea .fLeft,
.smModal .fLeft,
.smStickyFooter .fLeft
{
	float:left !important;
}

.smContentArea .fRight,
.smModal .fRight,
.smStickyFooter .fRight
{
	float:right !important;
}

.smContentArea .floatBox,
.smModal .floatBox,
.smStickyFooter .floatBox
{
	display:block;
	float:left;
	clear:both;
	width:100%;
}

.smContentArea .smHyphen,
.smModal .smHyphen,
.smStickyFooter .smHyphen
{
    display:inline;
    margin:0 .5em;
}

.smContentArea h2.noResults,
.smModal h2.noResults,
.smStickyFooter h2.noResults
{
	text-align:center;
}

.smContentArea .cols .col,
.smModal .cols .col,
.smStickyFooter .cols .col
{
    display:block;
    float:left;
    clear:none;
}

.smContentArea .cols.one .col,
.smModal .cols.one .col,
.smStickyFooter .cols.one .col
{
    width:100%;
}

.smContentArea .cols.two .col,
.smModal .cols.two .col,
.smStickyFooter .cols.two .col
{
    width:50%;
}

.smContentArea .cols.three .col,
.smModal .cols.three .col,
.smStickyFooter .cols.three .col
{
    width:33%;
}

.smContentArea .cols.four .col,
.smModal .cols.four .col,
.smStickyFooter .cols.four .col
{
    width:25%;
}

.smContentArea .cols.five .col,
.smModal .cols.five .col,
.smStickyFooter .cols.five .col
{
    width:20%;
}

/* OVERRIDDEN IN MEDIA QUERIES */
.smContentArea .isPhone,
.smStickyFooter .isPhone
{
	display:none;
}

/* GENERIC DETAILED LIST ITEM */
/* REFACTOR SPECIFIC ITEM MARKUP TO USE GENERIC WHERE POSSIBLE */
.smContentArea ul.detailedList > li,
.smModal ul.detailedList > li,
.smStickyFooter ul.detailedList > li
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	margin: 0 0 .25em 0;
    cursor:pointer;
}

.smContentArea ul.detailedList > li .listItem,
.smModal ul.detailedList > li .listItem,
.smStickyFooter ul.detailedList > li .listItem
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	position:relative;
	border:solid 1px rgb(207,207,207);
	padding:1em .5em;
	background-color: #e6e8e9;
	line-height:1em;
}

.smContentArea ul.detailedList > li .listItem > .buttonGroup,
.smContentArea ul.detailedList > li .listItem > .itemDetails > .buttonGroup,
.smModal ul.detailedList > li .listItem > .buttonGroup,
.smModal ul.detailedList > li .listItem > .itemDetails > .buttonGroup,
.smStickyFooter ul.detailedList > li .listItem > .buttonGroup,
.smStickyFooter ul.detailedList > li .listItem > .itemDetails > .buttonGroup
{
	float:right;
	position:relative;
    margin-top:.5em; /* ADDED TO 360 PAGE FOR MESSAGES REPLY-ARCHIVE BUTTONS */
    width:auto;
}

.smContentArea ul.detailedList > li .listItem > .buttonGroup > button,
.smContentArea ul.detailedList > li .listItem > .itemDetails > .buttonGroup > button,
.smModal ul.detailedList > li .listItem > .buttonGroup > button,
.smModal ul.detailedList > li .listItem > .itemDetails > .buttonGroup > button,
.smStickyFooter ul.detailedList > li .listItem > .buttonGroup > button,
.smStickyFooter ul.detailedList > li .listItem > .itemDetails > .buttonGroup > button
{
	margin:0;
    min-width:0;
    padding:0 1em;
}

.smContentArea ul.detailedList > li.detailsShowing,
.smContentArea ul.detailedList > li.editorShowing,
.smModal ul.detailedList > li.detailsShowing,
.smModal ul.detailedList > li.editorShowing,
.smStickyFooter ul.detailedList > li.detailsShowing,
.smStickyFooter ul.detailedList > li.editorShowing
{
	background-color:#999999;
}

.smContentArea ul.detailedList > li.detailsShowing .listItem,
.smContentArea ul.detailedList > li.editorShowing .listItem,
.smModal ul.detailedList > li.detailsShowing .listItem,
.smModal ul.detailedList > li.editorShowing .listItem,
.smStickyFooter ul.detailedList > li.detailsShowing .listItem,
.smStickyFooter ul.detailedList > li.editorShowing .listItem
{
	border-color:rgb(51, 173, 255);
	box-shadow: inset 0px 0px 0.5em rgba(115,238,255,.5);
	-webkit-transition: all 1s;
	transition: all 1s;
}

.smContentArea ul.detailedList > li.detailsShowing .itemSummary > span:nth-child(1),
.smModal ul.detailedList > li.detailsShowing .itemSummary > span:nth-child(1),
.smStickyFooter ul.detailedList > li.detailsShowing .itemSummary > span:nth-child(1)
{
	color: rgb(51,173,255) !important;
}

.smContentArea ul.detailedList > li .listItem > span.icon,
.smModal ul.detailedList > li .listItem > span.icon,
.smStickyFooter ul.detailedList > li .listItem > span.icon
{
	float:left;
	background-size:80%;
}

.smContentArea ul.detailedList > li .listItem span.icon.detailsIcon,
.smModal ul.detailedList > li .listItem span.icon.detailsIcon,
.smStickyFooter ul.detailedList > li .listItem span.icon.detailsIcon
{
	display:block !important;
    float:none !important;
	position:absolute;
	top:10px;
	right:5px;
	left:auto;
	margin:0 !important;
}

.smContentArea ul.detailedList > li:last-child,
.smModal ul.detailedList > li:last-child,
.smStickyFooter ul.detailedList > li:last-child
{
	margin: 0;
}

.smContentArea ul.detailedList .listItem .itemDetails,
.smContentArea ul.detailedList .listItem .itemEditor,
.smModal ul.detailedList .listItem .itemDetails,
.smModal ul.detailedList .listItem .itemEditor,
.smStickyFooter ul.detailedList .listItem .itemDetails,
.smStickyFooter ul.detailedList .listItem .itemEditor
{
	display:none;
	float:left;
	clear:both;
	width:100%;
	cursor:default;
}

.smContentArea ul.detailedList .listItem .itemDetails.active,
.smContentArea ul.detailedList .listItem .itemEditor.active,
.smModal ul.detailedList .listItem .itemDetails.active,
.smModal ul.detailedList .listItem .itemEditor.active,
.smStickyFooter ul.detailedList .listItem .itemDetails.active,
.smStickyFooter ul.detailedList .listItem .itemEditor.active
{
	display:block;
	margin-top:.5em;
}

.smContentArea ul.detailedList .listItem .itemEditor.active,
.smModal ul.detailedList .listItem .itemEditor.active,
.smStickyFooter ul.detailedList .listItem .itemEditor.active
{
	margin-top:0;
	border-top:0;
}

/* DEFAULT ITEM SUMMARY LAYOUT */
.smContentArea ul.detailedList > li .listItem .itemSummary,
.smModal ul.detailedList > li .listItem .itemSummary,
.smStickyFooter ul.detailedList > li .listItem .itemSummary
{
	display:block;
	float:left;
	max-width:85%;
	margin: 0 .5em;
}

.smContentArea ul.detailedList > li .listItem .itemSummary > span,
.smModal ul.detailedList > li .listItem .itemSummary > span,
.smStickyFooter ul.detailedList > li .listItem .itemSummary > span
{
	max-width:100%;
	white-space:nowrap;
	text-overflow:ellipsis;
    overflow:hidden;
}

.smContentArea ul.detailedList > li .listItem .itemSummary > span:nth-child(1),
.smModal ul.detailedList > li .listItem .itemSummary > span:nth-child(1),
.smStickyFooter ul.detailedList > li .listItem .itemSummary > span:nth-child(1)
{
	display:block;
	font-size:1em;
	color:rgb(102,102,102);
	font-weight:bold;
	line-height: 1.5;
}

.smContentArea ul.detailedList > li .listItem .itemSummary > div.field.inline,
.smContentArea ul.detailedList > li .listItem .itemSummary > span:nth-child(2),
.smContentArea ul.detailedList > li .listItem .itemSummary > span:nth-child(3),
.smContentArea ul.detailedList > li .listItem .itemSummary > span:nth-child(4),
.smContentArea ul.detailedList > li .listItem .itemSummary > span:nth-child(5),
.smModal ul.detailedList > li .listItem .itemSummary > div.field.inline,
.smModal ul.detailedList > li .listItem .itemSummary > span:nth-child(2),
.smModal ul.detailedList > li .listItem .itemSummary > span:nth-child(3),
.smModal ul.detailedList > li .listItem .itemSummary > span:nth-child(4),
.smModal ul.detailedList > li .listItem .itemSummary > span:nth-child(5)
{
	float:left;
	clear:both;
	font-size:.9em;
	color:rgb(61,61,61);
    line-height:2;
}

/* JITG - 09/16/2015 - DEGREE AUDIT PROGRAM SELECTION */

.smModal ul.detailedList > li
{
    border-bottom:solid 1px #dfdfdf;
}

.smModal ul.detailedList > li .listItem
{
    background-color:transparent;
    border:0;
    margin:0;
    padding:.5em 1em;
    float:left;
    max-width:90%;
}

.smModal ul.detailedList > li button
{
   float:right;
   margin: 2em 1.5em 0 0;
}

.smModal ul.detailedList > li .listItem .itemSummary.responsive
{
    max-width:none;
    width:100%;
    margin:0;
}

.smModal ul.detailedList > li .listItem .itemSummary.responsive > span:nth-child(1)
{
    margin:.5em 0;
    font-size:1.25em;
    line-height:1.25;
}

.smModal ul.detailedList > li .listItem .itemSummary.responsive .fieldGroup
{
    margin-top:1em;
    margin-bottom:.5em;
}

.smModal ul.detailedList > li .listItem .itemSummary.responsive .fieldGroup > .field.inline
{
    display:inline-block;
    margin: 0 1em 0 0;
}

.smModal ul.detailedList > li .listItem .itemSummary.responsive .fieldGroup > .field > label
{
    font-weight:normal;
}

.smModal ul.detailedList > li .listItem .itemSummary.responsive .fieldGroup > .field > label:after
{
    content:": ";
}


.smContentArea .isPhone ul.phoneDetails > li > span:nth-child(n+3)
{
    padding: 0.5em 0;
}

.smContentArea .isPhone ul.phoneDetails > li > span:nth-child(3)
{
    width: 100%;
}
.smContentArea .isPhone ul.phoneDetails > li > span:nth-child(4)
{
    width: 50%;
    clear: left;
}

.smContentArea .isPhone ul.phoneDetails > li > span:nth-child(n+5)
{
    width: 50%;
    float: right;
    clear: right;
}

.smContentArea ul.detailedList > li .listItem li span.icon.detailsIcon,
.smModal ul.detailedList > li .listItem li span.icon.detailsIcon
{
    display: none !important;
}

/* SPECIAL EXCEPTIONS SCOPED TO ONLY TEST SCORES - EVENTUALLY BROADEN */
.smContentArea ul.detailedList > li .listItem .itemSummary.responsive.testScores > span:after
{
    content:"";
}
.smContentArea ul.detailedList > li .listItem .itemSummary.responsive.testScores > .fieldGroup
{
    display:block;
    width:50%;
}
.smContentArea ul.detailedList > li .listItem .itemSummary.responsive.testScores > .fieldGroup:first-child
{
    float:left;
    clear:none;
}
.smContentArea ul.detailedList > li .listItem .itemSummary.responsive.testScores > .fieldGroup:last-child
{
    float:right;
    clear:none;
}
.smContentArea ul.detailedList > li .listItem .itemSummary.responsive.testScores > .fieldGroup > .field.inline
{
    width:100%;
    clear:both;
}

.smContentArea ul.detailedList > li .listItem .field.inline.options
{
    float: right;
    margin-right: 3em;
    max-width: 235px;
}
.smContentArea ul.detailedList > li .listItem .field.inline.options span:not(.icon)
{
    float: none;
    vertical-align: bottom;
    line-height: 25px;
}

#degreeAuditListContainer .listItem
{
    max-width:70%;
}

#degreeAuditListContainer .smModal ul.detailedList > li .listItem .itemSummary > span
{
    white-space:normal;
    text-overflow:initial;
    overflow:visible;
}




/* SUMMARY TABLE CELL */
.smContentArea td.summaryCell > span
{
	display:block;
	float:left;
	clear:both;
}

.smContentArea td.summaryCell > span:nth-child(1)
{
	font-weight:bold;
	margin-bottom:.5em;
	color:#888888;
}

.smContentArea td.summaryCell > span:nth-child(2),
.smContentArea td.summaryCell > span:nth-child(3),
.smContentArea td.summaryCell > span:nth-child(4)
{
	font-size:1em;
}

.smContentArea .isPhone ul.phoneDetails > li
{
	display:block;
	float:left;
	width:100%;
	border-bottom:solid 1px #afafaf;
	padding:.5em;
}

.smContentArea .isPhone ul.phoneDetails > li > span:nth-child(2)
{
	display:block;
	font-size:1.25em;
	color:#888888;
	font-weight:bold;
	line-height:1.5em;
}

.smContentArea .isPhone ul.phoneDetails > li > span:nth-child(n+3)
{
	float:left;
	font-size:.9em;
	color:rgb(61,61,61);
}

.smContentArea .isPhone ul.phoneDetails > li > .smProfileImage
{
	float:left;
}

.smContentArea .isPhone ul.phoneDetails > li > .smProfileImage > span
{
	height:50px;
	width:50px;
	margin-right:.5em;
}

.smContentArea .isPhone ul.phoneDetails > li > ul.socialIcons > li
{
	margin:.25em;
}

.smContentArea .isPhone ul.phoneDetails > li > ul.socialIcons > li span
{
	height:30px;
	width:30px;
    display:list-item;
}

.smContentArea ul.phoneNumbersList
{
    clear:both;
}

.smContentArea ul.phoneDetails ul.phoneNumbersList
{
    clear:none;
}

.smContentArea ul.phoneDetails ul.phoneNumbersList > li
{

}

.smContentArea ul.phoneDetails ul.phoneNumbersList > li > span
{
    color:#000;
    display:block;
    margin:1em 0;
}

/* LIST ITEM WITH ACTIONS */
.smContentArea ul.detailedList .listItem.withActions .listItemActions
{
    float:right;
}

/* WHEN LIST ITEMS HAVE DETAILS AND ACTIONS */
.smContentArea ul.detailedList li[data-hasdetails="true"] .listItemActions
{
    margin-right:2.5em;
}

.smContentArea ul.detailedList .listItem.withActions .listItemActions > button
{
    min-width:0;
    border-width:1px;
    background-color:#fff;
    padding:.25em;
    box-shadow: 0 0 .1em rgba(0,0,0,.2);
}

.smContentArea ul.detailedList .listItem.withActions .listItemActions > button:hover
{
    box-shadow: 0 0 .5em rgba(0,0,0,.5);
}

/* NO EFFECT UNTIL TEXT IS ADDED */
.smContentArea ul.detailedList .listItem.withActions .listItemActions > button > span.icon
{
    float:left;
}

/* COLS INSIDE itemDetails */
.smContentArea ul.detailedList li .listItem .itemDetails.cols
{
    margin:0;
    padding:0;
}

.smContentArea ul.detailedList li .listItem .itemDetails.cols.one
{
     text-align:center;
}

.smContentArea ul.detailedList li .listItem .itemDetails.cols.two .fieldGroup
{
    width:50%;
    clear:none;
}

/* .listItem STATES - FIRST USED IN My Housing - Prototype */
/* ADDED SPECIFIC STYLING FOR DETAILED LIST ITEMS - SOME SPECIFIC TO HOUSING, but 'pending' and 'complete' COULD LIKELY BE BROADER */
ul.detailedList li .listItem.itemPending,
ul.detailedList li .listItem.itemComplete,
ul.detailedList li .listItem.roommateRequest
{
    background-repeat:no-repeat;
    background-position: 0 50%;
    background-size:4rem;
}

ul.detailedList li .listItem.itemPending .itemSummary,
ul.detailedList li .listItem.itemComplete .itemSummary,
ul.detailedList li .listItem.roommateRequest .itemSummary
{
    margin-left:4rem !important;
}

ul.detailedList li .listItem.itemPending
{
    background-image:url(../img/svg/dark/icon_circleExclamation.svg);
    background-color:#fff2f2 !important;
    background-size:3rem;
    background-position:.5em 50%;
}

ul.detailedList li .listItem.itemComplete
{
    background-image:url(../img/svg/dark/icon_checkBoxChecked.svg);
    background-color:#f2fff2 !important;
}

ul.detailedList li .listItem.roommateRequest
{
    background-image:url(../img/svg/dark/icon_people2.svg);
    background-size:3rem;
    background-position:.5em 50%;
}


/* END My Housing */


/* END GENERIC DETAILED LIST ITEMS */

/* FIELD GROUPS */
/* FIELDGROUP WITH EDIT ELEMENTS */
.smContentArea .fieldGroup.editor,
.smStickyFooter .fieldGroup.editor
{
	background-color:rgb(240, 240, 240);
}

.smContentArea .fieldGroup.editor > div.field.inline,
.smStickyFooter .fieldGroup.editor > div.field.inline
{
	padding: .5em 0;
	margin: 0 .5em;
}

.smContentArea .fieldGroup div.field,
.smStickyFooter .fieldGroup div.field
{
    padding:5px 0;
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#E0E0E0;
}

.smContentArea .fieldGroup div.field > span.icon.info,
.smStickyFooter .fieldGroup div.field > span.icon.info
{
    position:absolute;
    height:25px;
    width:25px !important;
    top:5.5px;
    right:7px;
    border-radius:3em;
    background-color:#ffffff;
    background-image: url('../img/svg/dark/icon_questionMark.svg');
    background-size:100%;
    cursor:pointer;
    border-style:solid;
    border-width:1px;
    border-color:#999999;
}

.smContentArea .fieldGroup div.field > span.icon.info.active,
.smStickyFooter .fieldGroup div.field > span.icon.info.active
{
    border-style:solid;
    border-width:1px;
    border-color:#3B8DBD;
    box-shadow:inset 0px 0px 5px rgba(59, 141, 189, 0.49);
}

.smContentArea .fieldGroup div.field > span.icon.info.active:after,
.smStickyFooter .fieldGroup div.field > span.icon.info.active:after
{
    top: 18px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #3B8DBD;
	border-width: 7px;
    right:4px;
}

.smContentArea .fieldGroup div.field > div.fieldHelp
{
    display:none;
}

.smContentArea .fieldGroup div.field > div.fieldHelp > p
{
    padding:.5em;
    background-color:#ffffff;
    line-height:1em;
    text-align:center;
    color:#333333;
}

.smContentArea .fieldGroup div.field > div.fieldHelp.active
{
    display:block;
    float:right;
    clear:both;
    width:95%;
    position:relative;
    margin-top:8px;
    border-style:solid;
    border-width:1px;
    border-color:#3B8DBD;
    margin-right:10px;
}

.smContentArea .fieldGroup div.field:last-child,
.smStickyFooter .fieldGroup div.field:last-child
{
    border-bottom:0;
}

.smContentArea .fieldGroup.editor > div.field > label,
.smStickyFooter .fieldGroup.editor > div.field > label
{
	float:left;
}

.smContentArea .fieldGroup.editor > div.field > input,
.smStickyFooter .fieldGroup.editor > div.field > input
{
	width:auto;
}

.smContentArea .fieldGroup.editor > div.field > input.hasDatepicker,
.smContentArea .fieldGroup.editor > div.field > button.ui-datepicker-trigger
{
	float:left;
}

.smContentArea div.field > label,
.smStickyFooter div.field > label
{
	color:#525252; /* REACT */
	float:left;
	clear:both;
}

.smContentArea div.field > span:not(.icon),
.smContentArea div.field > input[type="text"],
.smContentArea div.field > select,
.smContentArea div.field > textarea,
.smStickyFooter div.field > span:not(.icon),
.smStickyFooter div.field > input[type="text"],
.smStickyFooter div.field > select,
.smStickyFooter div.field > textarea
{
	color: #333333;
	display:block;
	float:left;
	clear:both;
}

.smContentArea label:after,
.smStickyFooter label:after
{
	content:":";
}

.smContentArea div.field.currency,
.smStickyFooter div.field.currency
{
	float:left;
	width:100%;
}

.smContentArea div.field.headerField,
.smStickyFooter div.field.headerField
{
	text-align:center;
	float:none;
	padding:.25em;
	background-color: rgb(255,255,255);
	margin:0;

    /* Adding to fix my account - see what else breaks */
    float:left;
    clear:both;
    width:100%;
}

.smContentArea div.field.fullField,
.smStickyFooter div.field.fullField
{
    width:100%;
}

.smContentArea div.field.headerField > label,
.smContentArea div.field.headerField > span,
.smStickyFooter div.field.headerField > label,
.smStickyFooter div.field.headerField > span
{
	float:none;
}

.smContentArea div.field.currency > label,
.smStickyFooter div.field.currency > label
{
	float:left;
	text-align:right;
}

.smContentArea div.field.currency > span,
.smStickyFooter div.field.currency > span
{
	float:right;
	text-align:right;
}

.smContentArea ul.fields > li,
.smStickyFooter ul.fields > li
{
	margin:.5em 0;
}

.smContentArea .fieldGroup,
.smStickyFooter .fieldGroup
{
	display:block;
	float:left;
	clear:both;
	width:100%;
    padding: 0 .5em;
}

.smContentArea .fieldGroup h3.msgBlock,
.smStickyFooter .fieldGroup h3.msgBlock
{
    margin:0;
    line-height:1.5;
    padding:.5em;
    font-size:1em;
}

.smContentArea .fieldGroup .field,
.smStickyFooter .fieldGroup .field
{
	display:block;
	float:left;
}

.smContentArea .fieldGroup div.field.no-border,
.smStickyFooter .fieldGroup div.field.no-border
{
    border-bottom: 0;
    padding: 0;
}

.smContentArea .fieldGroup.stacked > div.field,
.smStickyFooter .fieldGroup.stacked > div.field
{
	clear:both;
	width:100%;
	margin:.25em 0;
}

.smContentArea .fieldGroup div.field.inline,
.smStickyFooter .fieldGroup div.field.inline
{
	margin:.25em 0;
    float:none;
    display:inline-block;
    vertical-align:top;
    border:0;
}

.smContentArea .fieldGroup div.field.inline.headerField,
.smStickyFooter .fieldGroup div.field.inline.headerField
{
    width:100%;
}

.smContentArea div.field.inline > label,
.smContentArea div.field.inline > span,
.smContentArea div.field.inline > input,
.smContentArea div.field.inline > textarea,
.smContentArea div.field.inline > select,
.smStickyFooter div.field.inline > label,
.smStickyFooter div.field.inline > span,
.smStickyFooter div.field.inline > input,
.smStickyFooter div.field.inline > textarea,
.smStickyFooter div.field.inline > select
{
	display:inline-block;
	clear:none;
}

.smContentArea div.field.inline > label,
.smStickyFooter div.field.inline > label
{
	margin-right:.5em;
}

.smContentArea .fieldGroup div.field > label,
.smStickyFooter .fieldGroup div.field > label
{
    font-weight:normal !important;
}

.smContentArea .fieldGroup .field > h2,
.smStickyFooter .fieldGroup .field > h2
{
    text-align:center;
    font-weight:bold !important;
    margin:0 !important;
    border-top:0;
    color: #afafaf;
}

.smContentArea input.portalSmallText,
.smStickyFooter input.portalSmallText
{
    width: 4em;
}

.smContentArea p.fieldText,
.smStickyFooter p.fieldText
{
    color:#000;
}

.smContentArea .fieldGroup.split.fiftyfifty .field .fieldText p.fieldText.tCenter,
.smStickyFooter .fieldGroup.split.fiftyfifty .field .fieldText p.fieldText.tCenter
{
    text-align:center;
}


.smContentArea .fieldGroup.split.fiftyfifty .field .fieldText /* USED IN APPLICATION - TO SPECIFY USERNAME WILL BE GENERATED FOR YOU MESSAGE */
{
    float:left;
}

.smContentArea .fieldGroup.split.fiftyfifty .field .fieldText.centered
{
    float:none;
    text-align:center;
}

/* DATA ENTRY AND DISPLAY VIEWS - USED IN ADDING COLLEGES, HIGHSCHOOLS, TEST SCORES */
.smContentArea .dataDisplayView
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    margin-bottom:1em;
}

.smContentArea .dataDisplayView.hidden
{
    display:none;
}

.smContentArea .dataDisplayView .displayHeader
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    border-bottom:solid 1px #dfdfdf;
    text-align:center;
    line-height:2.25;
}

.smContentArea .dataEntryView .displayHeader h4
{
    width:100%;
}

.smContentArea .dataDisplayView .displayHeader h4,
.smContentArea .dataEntryView .displayHeader h4
{
    display:inline-block;
    text-align:center;
}

.smContentArea .dataDisplayView .displayHeader button
{
    float:right;
    margin-top:0;
    margin-right:1em;
}

.smContentArea .dataDisplayView .displayHeader button span.icon
{
    float:left;
    margin-right:.5em;
}

.smContentArea .dataDisplayView ul.detailedList
{
    display:block;
    float:left;
    clear:both;
    width:99%;
    margin-top:.5em;
    margin-left:.1em;
}

/* FOR THE DEFAULT "NOTHING HERE" MESSAGE AT TOP OF LIST */
.smContentArea .dataDisplayView ul.detailedList li.noneAdded
{
    text-align:center;
    margin:1em 0;
    font-size:1.3em;
    font-style:italic;
}

.smContentArea .dataEntryView.applicationContent
{
    display:none;
}

.smContentArea .dataEntryView.applicationContent.active
{
    display:block;
}

.smContentArea .dataEntryView .buttonBar
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:1em 0;
    text-align:center;
    margin-top:1em;
}

.smContentArea .dataEntryView .fieldGroup.radioGroup.split.fiftyfifty > .field > div
{
    position:relative;
    top:-5px;
}

/* BELOW IS FOR TEST SCORES */

.smContentArea .dataEntryView .additionalFields
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smContentArea .dataEntryView .additionalFields .additionalFieldsHeader
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
}

.smContentArea .dataEntryView .additionalFields .additionalFieldsHeader > h2
{
    font-size:1.5em;
}

.smContentArea .dataEntryView .additionalFields .additionalFieldsHeader h2 > span.icon
{
    display:inline-block !important;
    position:relative;
    top:4px;
}

.smContentArea .dataEntryView .additionalFields .additionalFieldsDetails
{
    display:none;
}

.smContentArea .dataEntryView .additionalFields .additionalFieldsDetails.active
{
    display:block;
}

.smContentArea .dataEntryView .additionalFields .additionalFieldsDetails > h3
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
    font-size:1.2em;
}

/* GENERIC REQUIRED & DISABLED FIELD RULES */
.smContentArea div.field > label.requiredLabel::before
{
    content: " * ";
    color:#ff0000;
    font-size:25px;
    position:relative;
    top:6px;
    left:4px;
}

.smContentArea input[type="text"][disabled="True"],
.smContentArea input[type="text"][disabled="disabled"],
.smContentArea textarea[disabled="disabled"], input[readonly="readonly"],
textarea[readonly="readonly"], textarea[disabled="disabled"],
input[readonly="readonly"]:focus, .smContentArea input[readonly="ReadOnly"]
{
    background-color:rgba(150,150,150,.5) !important;
    border: transparent !important;
    color:#ffffff !important;
}

/* GENERIC FIELD VALIDATION RULES */
.smContentArea div.field.error,
.smContentArea .input-validation-error
{
    background-color:rgb(255, 206, 206);
}

.smContentArea div.field > label.requiredLabel,
.smContentArea div.field.error > label.requiredLabel
{
    line-height:1em;
    color:#333333;
}

.smContentArea div.field.error > label.requiredLabel
{
    font-weight:bold;
}

.smContentArea div.field.error > input.error,
.smContentArea div.field.error > input.required,
.smContentArea div.field.error > select
{
    border-style:solid;
    border-width:1px;
    border-color:#ff0000;
    background-color:#fff;
}

.smContentArea div.field > span.field-validation-valid,
.smContentArea div.field.error > span.field-validation-valid
{
    display:none !Important;
}

span.msg.noResults
{
    display:block;
    text-align:center;
    width:100%;
    font-style:italic;
    color:#999;
}

/* Validation Messages / Error Messages for Doc Checklist - should be for everywhere else too..*/
.validationErrors
{
    background-color:#efefef !important;
    margin-bottom: 4em;
}

.validationErrors p
{
    text-align:center;
    padding:.5em;
    background-image: url('../img/svg/dark/icon_alertTriangle.svg');
    background-repeat:no-repeat;
	background-size:30px;
    padding-left:3em;
    text-align:left;
    font-size:1em;
    background-position:5px 50%;
    background-color:#FFCFCF;
    border:solid 1px #F00;
    color:#F00;

}

.validationErrors > div
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    margin-bottom:1em;
}

.validationErrors li
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    margin-bottom:1rem;
    text-indent: .5rem;
    padding: 1rem;
}

.validationErrors li:last-child
{
    margin-bottom:0;
}

.disabled
{
    opacity:.2;
    cursor:not-allowed !important;
}

/* FOR VALIDATION CONTAINER & LABEL */
.smContentArea div.field.error > label.error
{
    display:block;
    width:100% !important;
    text-align:center !important;
    margin-left:0px !important;
    color:rgb(73,73,73);
    position:relative;
    top:5px;
}

.smContentArea div.field.error > label.error:after
{
    content: "";
}

/* PORTAL BLOCK UI - COMMON USAGE */
.blockUI.blockOverlay
{
    background-color:rgb(255,255,255) !important;
    opacity:.5;
}

.blockUI.blockMsg.blockElement
{
    display:block !Important;
    top:35% !important;
    left:30% !important;
    border:solid 1px #999999 !important;
    padding:.5em !important;
    box-shadow:0px 0px 1.5em rgba(0,0,0,.3) !important;
}

.blockUI.blockMsg.blockPage
{
    border: solid 1px rgb(170,170,170) !important;
    box-shadow:0px 0px 1.5em rgba(0,0,0,.5);
    min-width:300px;
    left:50% !important;
    top:50% !important;
    transform:translate3d(-50%,-50%,0);
    -webkit-transform:translate3d(-50%,-50%,0);
    -moz-transform:translate3d(-50%,-50%,0);
}

.blockUI.blockMsg.blockPage h1
{
    padding:0;
    margin:1em;
}

.blockUI.blockMsg > h1.longerMsg
{
    line-height:1.25;
    font-size:1.75em;
}

.blockUI.blockMsg > h1.longestMsg
{
    line-height:1.25;
    font-size:1.5em;
}

.blockUI.blockMsg > p > a
{
    display:block;
    margin:1em;
    font-weight:bold;
    text-decoration:underline;
}

/* RADIO GROUPS */
.smContentArea div.radioGroup
{
	padding:0 .5em;
}

.smContentArea div.radioGroup > div.field
{
	/* DISABLING FOR APPLICATION margin: .5em; */
}

.smContentArea div.radioGroup > label
{
	float:left;
	padding:.5em;
}

.smContentArea div.radioGroup input[type=radio]
{
	display:none;
}

.smContentArea div.radioGroup input[type=radio] + label
{
	border-left: 1px solid #e6e6e6;
	border-right: 1px solid #e6e6e6;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #b3b3b3;
	display:inline-block;
	padding: 4px 12px;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 20px;
	color: #9B9B9B;
	text-align: center;
	text-shadow: 0 1px 1px rgba(255,255,255,0.75);
	vertical-align: middle;
	cursor: pointer;
	background-color: #f5f5f5;
	background-repeat: repeat-x;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
	background-image: linear-gradient(to bottom,#fff,#e6e6e6);
}

.smContentArea div.radioGroup input[type=radio] + label:after
{
	content:"" !important;
}

.smContentArea div.radioGroup input[type=radio]:checked + label
{
	background-image: none;
	//outline: 0;
	-webkit-box-shadow: inset 0 0px 6px rgba(115,238,255,.5),0 1px 2px rgba(0,0,0,0.05);
	-moz-box-shadow: inset 0 0px 6px rgba(115,238,255,.5),0 1px 2px rgba(0,0,0,0.05);
	box-shadow: inset 0 0px 6px rgba(115,238,255,.5),0 1px 2px rgba(0,0,0,0.05);
	background-color:#e0e0e0;
	border-style:solid;
	border-width:1px;
    border-radius:2px;
	border-color:rgb(51, 173, 255);
    color:#000;
}

.smContentArea div.radioGroup > div
{
    margin-top:.25em; /* ADDED FOR RADIO BUTTONS ON ADD HIGHSCHOOL PORTION OF APPLICATION */
}

/* RESPONSIVE FIELDGROUP */
.smContentArea .fieldGroup.responsive > div.field
{
	display:inline-block;
	margin:0 .5em;
    border:0;
}

.smContentArea .fieldGroup.responsive > div.field > label
{
	display:block;
	float:left;
	clear:none;
	margin-right:.5em;
}

.smContentArea .fieldGroup.responsive > div.field > span
{
	display:block;
	float:left;
	clear:none;
}

/* FIELDGROUP STACKING */
.smContentArea .fieldGroup.half
{
	width:50%;
	float:left;
	clear:none;
	padding:0 1em;
}

.smContentArea .fieldGroup.half > .headerField
{
	padding:0;
	background-color:rgba(0,0,0,0);
	border-style:solid;
	border-width: 1px 0;
}

/* SPLIT FIELDGROUPS */
.smContentArea .fieldGroup.split > div.field > label
{
	width:48%;
	float:left;
	text-align:right;
	margin-right:1em;
}

.smContentArea .fieldGroup.split > div.field > span
{
	width:48%;
	float:left;
}

/* 20/80 */
.smContentArea .fieldGroup.split.twentyEighty > div.field > label
{
	width:10%;
}

.smContentArea .fieldGroup.split.twentyEighty > div.field > span,
.smContentArea .fieldGroup.split.twentyEighty > div.field > input,
.smContentArea .fieldGroup.split.twentyEighty > div.field > textarea
{
	width:80%;
}

/* 50/50 FIELDS */
.smContentArea .fieldGroup.split.fiftyfifty > div.field
{
	float:left;
	width:100%;
    position:relative;
}

.smContentArea .fieldGroup.split.fiftyfifty > div.field > input
{
	float:none;
	clear:none;
}

.smContentArea .fieldGroup.split.fiftyfifty > div.field > label,
.smContentArea .fieldGroup.split.fiftyfifty > div.field > span,
.smContentArea .fieldGroup.split.fiftyfifty > div.field > input,
.smContentArea .fieldGroup.split.fiftyfifty > div.field > textarea,
.smContentArea .fieldGroup.split.fiftyfifty > div.field > .fieldText
{
	width:45%;
}

.smContentArea .fieldGroup.split.fiftyfifty:last-child
{
    border-bottom: 0px;
}

/* 40/60 */
.smContentArea .fieldGroup.split.fortySixty > div.field > label
{
	width:40%;
}

.smContentArea .fieldGroup.split.fortySixty > div.field > span,
.smContentArea .fieldGroup.split.fortySixty > div.field > span
{
	width:60%;
}

/* 60/40 */
.smContentArea .fieldGroup.split.sixtyForty > div.field > label
{
	width:60%;
}

.smContentArea .fieldGroup.split.sixtyForty > div.field > span,
.smContentArea .fieldGroup.split.sixtyForty > div.field > input,
.smContentArea .fieldGroup.split.sixtyForty > div.field > textarea
{
	width:40%;
}

/* BASIC TABSET */
.smContentArea .tabSet,
body.u4sm-theme .tabSet
{
	display:block;
	float:left;
	width:100%;
}

.smContentArea .tabSet > ul,
body.u4sm-theme .tabSet > ul
{
	list-style:none;
	margin:0;
	padding:0;
	width:100%;
}

.smContentArea .tabSet > ul:first-child,
body.u4sm-theme .tabSet > ul:first-child
{
	display:block;
	float:left;
	clear:both;
	position:relative;
}

.smContentArea .tabSet .activeTab,
body.u4sm-theme .tabSet .activeTab
{
	background-color:#efefef;
	border-style:solid;
	border-width:1px 1px 0px 1px;
	border-color:#cfcfcf;
	color:#000000;
}

.smContentArea .tabSet > ul:first-child > li[data-activate-tab],
body.u4sm-theme .tabSet > ul:first-child > li[data-activate-tab]
{
	display:block;
	float:left;
	padding:.5em;
	cursor:pointer;
	min-height:45px;
    line-height:1.5em;
    color:#666666;
}

.smContentArea .tabSet ul:first-child > li[data-activate-tab].active,
body.u4sm-theme .tabSet ul:first-child > li[data-activate-tab].active
{
    color: #000000;
}

.smContentArea .tabSet > ul:first-child > li > button,
body.u4sm-theme .tabSet > ul:first-child > li > button
{
	float:right;
}

.smContentArea .tabSet > ul:first-child > li > span.icon,
body.u4sm-theme .tabSet > ul:first-child > li > span.icon
{
	float:left;
	width:20px;
	height:20px;
	margin:0 .5em 0 0;
}

.smContentArea .tabSet ul:first-child > li[data-activate-tab] > span.badge,
body.u4sm-theme .tabSet ul:first-child > li[data-activate-tab] > span.badge
{
	display:inline-block;
	padding:.5em 1em;
	min-width:30px;
	text-align:center;
	background-color:#ffffff;
	color:#1E4F6B;
	margin:0 .25em;
	border-radius:1em;
	font-weight:bold;
}

.smContentArea [data-activate-tab].activeTab > span.badge,
body.u4sm-theme [data-activate-tab].activeTab > span.badge
{
	box-shadow: 0px 0px .5em rgba(0,0,0,.2);
}

.smContentArea [data-tab-content] h2,
body.u4sm-theme [data-tab-content] h2
{
	color:#333333;
	margin:.5em 0;
	margin-top:.25em;
	float:left;
}

.smContentArea .tabSet > ul:first-child > li:last-child,
body.u4sm-theme .tabSet > ul:first-child > li:last-child
{
	border-right-width: 1px;
    position:relative;
    top:-1px;
}

.smContentArea .tabSet > ul:last-child,
body.u4sm-theme .tabSet > ul:last-child
{
	float:left;
	clear:both;
	border-style:solid;
	border-width:1px;
	border-color:#cfcfcf;
	background-color:#efefef;
	margin-top:-2px;
	padding-bottom:.5em;
}

.smContentArea .tabSet > ul:last-child > li,
body.u4sm-theme .tabSet > ul:last-child > li
{
	display:none;
	padding:.5em;
}

.smContentArea .tabSet li.buttonTab,
body.u4sm-theme .tabSet li.buttonTab
{
	padding: .8em .5em !important;
}

/* COMBO SEARCH - THESE ARE APPENDED TO BODY - SO, NOT SCOPED TO "smContentArea" */
.comboSearch > span.icon
{
    position:relative;
    left:-3rem;
    top:-.4rem;
}

.comboSearch > input[type="text"]
{
    width:250px !important;
    color:rgb(51, 51, 51);
}

/* span.resultsNotFound
{
    background-image:url('../img/svg/icon_warningTriangle.svg');
} */

/* span.resultsFound
{
    background-image: url('../img/svg/icon_informationalIcon.svg');
} */

.comboSearchResults > ul
{
    width:100%;
}

.comboSearchResults > ul > li
{
    padding:1rem;
    border-style:solid;
}

span.hilite
{
    background-color:yellow;
    color:#000000;
    text-decoration:underline;
}

.comboSearchResults > ul > li.active > span.hilite
{
    color:#000000;
}

div.comboSearchResults
{
    position:absolute;
    width:284px;
    min-height:10px;
    border-style: solid;
    border-width: 1px;
}

.comboSearchResults
{
	background-color:#efefef;
}

.comboSearchResults > ul > li
{
	padding:1rem;
	border-style: solid;
	border-width: 0 0 1px 0;
	border-color:rgba(150,150,150,.3);
}

button[data-search-button="true"]
{
    padding-left:.5rem;
}

/* USED FOR COMBO-BOX TEXT BOX - Loading indicator */
.comboLoading
{
    background: url('../img/loader.gif') no-repeat right center !important;
    background-size: 15px !important;
}
/* COMBO BOX END */

/* RESPONSIVE MENU */
.smContentArea .itemMenu,
.smContentArea .responsiveMenu
{
	display:block;
}

.smContentArea .responsiveMenu span.menuIcon
{
	display:none;
	width:25px;
	height:25px;
	background-size:100%;
	background-position:0 0;
}

.smContentArea a.subItemTrigger
{
	background-repeat: no-repeat;
	background-size:25px;
	text-indent: 1.5em;
	background-position: 5px 50%;
}

.smContentArea .responsiveMenu ul,
.smContentArea .responsiveMenu ul li ul
{
	list-style:none;
	margin:0;
	padding:0;
}

.smContentArea .responsiveMenu > ul > li
{
	display:inline-block;
	position:relative;
}

.smContentArea .responsiveMenu > ul > li > ul
{
	display:none;
	position:absolute;
}

.smContentArea .responsiveMenu > ul > li > ul.active
{
	display:block;
	min-width:150px;
	background-color:#dfdfdf;
}

.smContentArea .responsiveMenu > ul > li > ul > li > a
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	padding:.5em;
	margin:.25em 0;
	text-decoration:none;
}

.smContentArea .responsiveMenu > ul > li > a
{
	display:block;
	background-color: #efefef;
	padding:.5em;
	text-decoration:none;
}
/* END RESPONSIVE MENU */

/* FLEX WIDGET BASIC RULES */
.smContentArea .flexWidget
{
	/* background-color:#F7F7F7; REMOVING BG COLOR FROM BASE WIDGET FOR BETTER FLEXIBILITY */
	float:left;
	margin:.25%;
	position:relative;
	color:#afafaf;
	position:relative;
	min-height:300px;
}

.smContentArea .flexWidget .widgetContent
{
	max-height:250px;
    overflow-y:auto;
    padding:.25em; /* ADDED FOR ALL FLEXWIDGETS CONTENT ON 360 */
}

.smMessages .messageBody > iframe
{
    border:0;
    box-shadow:inset 0px 0px .5em rgba(0,0,0,.5);
}

.smContentArea .flexWidget .widgetContent.noMaxHeight
{
    max-height:none !important;
}

.smContentArea .flexWidget.noMinHeight
{
    min-height:0 !important;
}

.smContentArea .flexWidget .widgetContent.noPad,
.smContentArea .noPad
{
    padding:0 !important;
}

.smContentArea .flexWidget .widgetContent .buttonGroup
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
    margin:1em 0;
}

.smContentArea .flexWidget .widgetContent .buttonGroup > button
{
    padding:.25em 2em;
}

.smContentArea .flexWidget ul
{
	list-style:none;
	margin:0;
	padding:0;
}

.smContentArea .flexWidget.tiny
{
	width: 24%;
}

.smContentArea .flexWidget.small
{
	width:32%;
    font-size:100%;
}

.smContentArea .flexWidget.medium
{
	width:65%;
}

.smContentArea .flexWidget.large
{
	width:99.5%;
}

.smContentArea .flexWidget.half
{
    width:49.5%;
}

.smContentArea .flexWidget.only
{
    width:100%;
    margin:0;
}

.smContentArea .flexWidget.only .widgetContent
{
    max-height:none;
}

.smContentArea .widgetHeader,
.smContentArea .widgetSubHeader
{
    text-align:center;
}

.smContentArea .widgetHeader,
.smContentArea .widgetSubHeader,
.smContentArea .widgetContent,
.smContentArea .widgetControls
{
	display:block;
	float:left;
	width:100%;
	clear:both;
	position:relative;
}

.smContentArea .widgetControls
{
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#dfdfdf;
    padding:0 .5em;
}

.smContentArea .widgetSubHeader
{
    padding:.25em;
}

.smContentArea .widgetContent .notPhone
{
    clear:both; /* Firefox-Specific Fix */
}

.smContentArea .widgetHeader > span.widgetIcon
{
	display:block;
	position:absolute;
	height:100%;
	width:40px;
	background-color:rgba(255,255,255,.2);
	top:0;
	left:0;
	background-size: 70%;
	background-position:50%;
	background-repeat:no-repeat;
}

.smContentArea .widgetHeader.transparent > span.widgetIcon
{
	background-size: 50%;
}

.smContentArea .widgetHeader button
{
    color:#fff;
    border:solid 1px #fff;
}

.smContentArea .widgetHeader > span.icon
{
	position:absolute;
	height:100%;
	width:50px;
	top:0;
	left:auto;
	right:0;
	background-size:50%;
	-webkit-transition: all .5s;
	transition: all .5s;
}

.smContentArea .widgetHeader > button
{
	position:absolute;
	left:auto;
	right:5px;
	top:7px;
    z-index: 9;
}

.smContentArea .widgetHeader h2
{
	text-align:center;
	color:#ffffff;
	font-size:1.25em;
    display:inline !important;
    float:none !important;
    line-height:2;
}

.smContentArea .widgetHeader .widgetActions
{
    position:absolute;
    right:.5em;
    top:.5em;
}

/* WIDGET CONTROLS RULES -- FOR flexWidget Fields, Options, etc... */

.smContentArea .widgetControls,
#smRecordAttendance .widgetControls
{
    text-align:center;
}

.smContentArea .widgetControls .fieldGroup div.field.inline
{
    border:0;
    margin:0;
}

.smContentArea .widgetControls .fieldGroup.inline
{
    display:inline-block;
    width:auto;
    float:none;
    clear:none;
}

.smContentArea .widgetControls button
{
    margin:.5em 0;
}

.smContentArea .widgetControls button:last-child
{
    float:right;
}

.smContentArea .widgetControls .smDateRangeInputs
{
    display:inline-block;
    padding-top:.2em;
    margin:0 1em;
}

.smContentArea .widgetControls .warningMessage
{
    margin:0;
}

#smRecordAttendance .widgetControls
{
    background-color:#7c878e;
    height:35px;
}

/* REVISION OF 'WIDGET CONTROLS' & STUFF WITHIN  - THIS IS FOR CONTROLS THAT LOAD WIDGET CONTENT - E.g. DATE-RANGE/PERIOD SELECTION FIELDS GO INSIDE THIS */
/* CLASSING NARROWLY UNTIL ABLE TO REFACTOR ALL INTO COMMON PATTERN */
/* TODO: STANDARDIZE */
.widgetControls.standardPattern
{

}

.widgetControls.standardPattern .fieldGroup
{
    padding:0 !important;
}

.widgetControls.standardPattern .fieldGroup.inline:first-of-type
{
    float:left;
}

.widgetControls.standardPattern .fieldGroup.radioGroup.inline:first-of-type > label
{
    
}

.widgetControls.standardPattern .fieldGroup.inline:last-of-type
{
    float:right;
}

#smRecordAttendance .widgetContent > div.paneContent > p.msg.noResults
{
    padding:1em;
    line-height:1;
    text-align:center;
    font-style:italic;
    display:block;
    color:#666;
    background-color:#EAFFEA;
}

/* END WIDGET CONTROL RULES */

.smContentArea .widgetContent h3
{
	font-weight:normal;
	font-size:1em;
	margin:.25em 0;
    /* max-width:95%; REMOVING FOR GREEN LOGIN MESSAGE BOX IN APPLICATION */
}

/* ADDED FOR MY ACCOUNT PAGE TO COMBINE ACCOUNT INFORMATION AND ACCOUNT MEMBERSHIPS */
.smContentArea .widgetContent h3 > b
{
    font-weight:bold;
}

.smContentArea .widgetContent > p
{
	color:#000000;
	display:block;
	margin:.5em 0;
}
/* END BASIC FLEXWIDGET RULES */

/* RESPONSIVE FILE UPLOAD */
.smContentArea .widgetContent div.uploadControl
{
	display:block;
	float:left;
	clear:both;
	width:100%;
}

.smContentArea .widgetContent div.uploadControl.fileUploaded > div:first-child > label
{
	display:none;
}

.smContentArea .widgetContent div.uploadControl.filedUploaded > div:first-child > a
{
	display:block;
}

.smContentArea .widgetContent div.uploadControl > div:first-child
{
	width:20%;
	float:left;
	clear:none;
	text-align:center;
}

.smContentArea .widgetContent div.uploadControl label.uploadBox
{
	cursor:pointer;
}

.smContentArea .widgetContent div.uploadControl label.uploadBox > span.icon
{
	background-size:120%;
	height:50px;
	width:50px;
	margin:0 auto;
}

.smContentArea .widgetContent div.uploadControl label.uploadBox > input[type="file"]
{
	opacity:0;
}

.smContentArea .widgetContent div.uploadControl > div:first-child > label:after
{
	content: "";
}

.smContentArea .widgetContent div.uploadControl > div:first-child > div.fileThumb
{
	display:none;
}

.smContentArea .widgetContent div.uploadControl > div:first-child > div.fileThumb > a
{
    display:block;
}

.smContentArea .widgetContent div.uploadControl > div:first-child > div.fileThumb > a > img
{
	height:50px;
	width:50px;
}

.smContentArea .widgetContent div.uploadControl label.uploadBox.hidden,
.smContentArea .widgetContent div.loadingIndicator
{
	display:none;
}

.smContentArea .widgetContent label.uploadBox,
.smContentArea .widgetContent div.loadingIndicator.active,
.smContentArea .widgetContent div.uploadControl > div:first-child > div.fileThumb.active
{
	display:block;
}

.smContentArea .widgetContent div.uploadControl > div:first-child > div.fileThumb.active > .resetUpload
{
    margin-top:1em;
}

.smContentArea .widgetContent div.loadingIndicator > span.loadingSpinner
{
	float:none;
	display:inline-block;
	height:50px;
	width:50px;
}

/* CHANGE PLACEHOLDER TEXT TO FILENAME */
.smContentArea .widgetContent div.loadingIndicator span.loadingText,
.smContentArea .widgetContent div.uploadControl span.uploadText
{
	display:block;
}

.smContentArea .widgetContent div.uploadControl span.uploadText
{
    margin-top:.5em;
}

.smContentArea .widgetContent div.uploadControl > div:first-child > a
{
	display:none;
}

.smContentArea .widgetContent div.uploadControl > div:last-child
{
	width:80%;
	float:left;
	clear:none;
}

.smContentArea .widgetContent div.uploadControl > div:last-child > span,
.smContentArea .widgetContent div.uploadControl > div:last-child > div.field.inline
{
	display: inline-block;
}

.smContentArea .widgetContent div.uploadControl > div:last-child > span:after,
.smContentArea .widgetContent div.uploadControl > div:last-child > div.field.inline:after
{
	content: "|";
	margin: 0 .5em;
}

.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(1)
{
	display:block;
	font-size:1em;
	color:rgb(102,102,102);
	font-weight:bold;
}

.smContentArea .widgetContent div.uploadControl > div:last-child > span:last-child:after
{
	content: "";
}

.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(2),
.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(3),
.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(4),
.smContentArea .widgetContent div.uploadControl > div:last-child > span:nth-child(5)
{
	float:left;
	clear:both;
	font-size:.9em;
	color:rgb(61,61,61);
}
/* END FILE UPLOAD RULES */

/* FLEX WIDGET COLORS */
.smContentArea .flexWidget.lightBg
{
    background-color:#F7F7F7;
    border:solid 1px #fff;
    box-shadow: 0 0 .5em rgba(0,0,0,.2);
}

.smContentArea .flexWidget.darkBg
{
    background-color: #e9e9e9;
}

.smContentArea .widgetHeader.green,
.smContentArea .widgetSubHeader.green
{
	background-color:#3C5D5B;
}

.smContentArea .widgetHeader.forestGreen,
.smContentArea .widgetSubHeader.forestGreen
{
    background-color: #59997E;
}

.smContentArea .widgetHeader.green.sub,
.smContentArea .widgetSubHeader.green.sub
{
	background-color:rgb(131, 162, 159);
}

.smContentArea .widgetHeader.mustard,
.smContentArea .widgetSubHeader.mustard
{
    background-color:#675A00;
}

.smContentArea .widgetHeader.blue,
.smContentArea .widgetSubHeader.blue
{
	background-color:rgb(86, 108, 131);
}

.smContentArea .widgetHeader.blue.sub,
.smContentArea .widgetSubHeader.blue.sub
{
	background-color: rgb(131, 147, 162);
}

.smContentArea .widgetHeader.orange,
.smContentArea .widgetSubHeader.orange
{
	background-color:#C14D1F;
}

.smContentArea .widgetHeader.red,
.smContentArea .widgetSubHeader.red
{
	background-color:rgb(143, 17, 5);
}

.smContentArea .widgetHeader.purple,
.smContentArea .widgetSubHeader.purple
{
	background-color:rgb(124, 86, 131);
}

.smContentArea .widgetHeader.marine,
.smContentArea .widgetSubHeader.marine
{
	background-color:#1e4f6b;
}

.smContentArea .widgetHeader.deepOcean,
.smContentArea .widgetSubHeader.deepOcean
{
    background-color:rgb(28, 68, 90);
}

.smContentArea .widgetHeader.indigo,
.smContentArea .widgetSubHeader.indigo
{
    background-color:rgb(56, 77, 116);
}

.smContentArea .widgetHeader.transparent,
.smContentArea .widgetSubHeader.transparent
{
	background-color: rgba(0,0,0,0);
}

.smContentArea .widgetHeader.white,
.smContentArea .widgetSubHeader.white
{
	background-color:rgba(255,255,255,1);
}

.smContentArea .widgetHeader.transparent,
.smContentArea .widgetHeader.white,
.smContentArea .widgetSubHeader.transparent,
.smContentArea .widgetSubHeader.white
{
	border-bottom: solid 1px rgb(201, 201, 201);
}

.smContentArea .widgetHeader.transparent > h2,
.smContentArea .widgetHeader.white > h2,
.smContentArea .widgetSubHeader.transparent > h2,
.smContentArea .widgetSubHeader.white > h2
{
	color:#666666;
}
/* END FLEX WIDGET HEADER COLORS */

/* FLEX WIDGET HEADER CONTENT RULES -  SOCIAL ICONS & PROFILE INFO */
.smContentArea ul.socialIcons
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	text-align:center;
}

.smContentArea ul.socialIcons > li
{
	display:inline-block;
	margin:.5em;
}

.smContentArea ul.socialIcons > li span
{
	height:40px;
	width:40px;
	background-color:rgba(255,255,255,.7);
	border-radius: 3em;
	opacity:.8;
    background-size:80%;
    background-repeat:no-repeat;
    background-position:50%;
}

/* WHEN IN TABLE CELL */
.smContentArea td ul.socialIcons > li span {
    display: list-item
}

.smContentArea td ul.socialIcons > li span,
.smContentArea ul.socialIcons > li span
{
	height:30px;
	width:30px;
}

.smContentArea ul.socialIcons > li span:hover
{
	box-shadow:0px 0px 1.5em rgba(0,0,0,.5);
	background-color:rgba(255,255,255,1);
	opacity:1;
}

.smContentArea .smProfileImage
{
	margin:.25em;
}

.smContentArea .smProfileImage > span
{
	display:block;
	width:95px;
	height:95px;
	text-align:center;
	overflow:hidden;
	border-radius:3em;
	margin:1em auto;
	box-shadow: 0px 0px .3em rgba(0,0,0,.5);
	border:solid 1px #ffffff;
}

.smContentArea td .smProfileImage > span
{
	height:50px;
	width:50px;
	margin:0;
}

.smContentArea .smProfileImage > span > img
{
	max-width:100%;
    width:120%;
}

.smContentArea .flexWidget address
{
	margin:.25em 0;
    color:#333;
    line-height:1;
    margin-bottom:.5em;
}

.smContentArea .widgetHeader span.visitLinkIcon
{
	left:auto;
	right:0;
	background-color:rgba(0,0,0,0);
	background-size:50%;
	-webkit-transition: all .5s;
	transition: all .5s;
	cursor:pointer;
}

.smContentArea .widgetHeader span.visitLinkIcon:hover
{
	background-color:rgba(0,0,0,.1);
}

/* ITEM MENU */
.smContentArea .flexWidget .widgetHeader .itemMenu
{
	position:absolute;
	top:10px;
	right:10px;
}

.smContentArea .itemMenu > span.menuIcon
{
	position:relative;
}

.smContentArea .itemMenu > ul
{
	display:none;
	list-style:none;
	margin:0;
	padding:0;
}

.smContentArea .itemMenu > ul.active
{
	display:block;
	position:absolute;
	right:0;
	min-width:150px;
	background-color:rgba(255,255,255,1);
	border:solid 1px rgba(255,255,255,1);
	box-shadow: 0px 0px 1.5em rgba(0,0,0,.3);
	z-index:1;
}

.smContentArea .itemMenu > ul > li > a
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	padding:.5em;
	border-bottom:solid 1px #efefef;
	box-sizing:border-box;
}

.smContentArea .itemMenu > ul > li > a:hover
{
	background-color:#efefef;
	color:#000000;
}

/* SM PORTAL CUSTOM RESPONSIVE MODAL */
/* A BUNCH OF STUFF IS SCOPED TO 'smContentArea' SO THOSE RULES DIDN'T APPLY TO ITEMS WITHIN .smModal - Refactor to scope via universal class of body.u4sm-theme - this is the new 'base' class for all U4SM Portal stuff - then can override as needed per page or paradigm  */
/* TODO: STANDARDIZE */
.smOverlay
{
    background-color:rgba(0,0,0,.4);
    width:100%;
    position:absolute;
    z-index:100;
    height:100%;
    top:0;
    cursor:not-allowed;
}

.smOverlay > .smModal
{
    cursor:default;
}

.smModal
{
    display:block;
    float:none;
    width:95%;
    background-color:#fff;
    opacity:0;
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    position:relative;
    border-radius:2px;
    max-width:1170px;
    max-height: calc(100vh - 100px);
    min-height: 150px;
    margin: 0;
    top:50%;
    left:50%;
    transform:translate3d(-50%,-50%,0);
    -webkit-transform:translate3d(-50%,-50%,0);
    -moz-transform:translate3d(-50%,-50%,0);
}

.smModal.active
{
    opacity:1;
    box-shadow: 0px 0px 1.5em rgba(0,0,0,.6),inset 0px 0px .5em rgb(219,219,219);
    border:solid 1px #fff;
}

.smModalHeader,
.smModalContent,
.smModalFooter
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smModalHeader,
.smModalFooter 
{
    background-color: #eee;
}

.smModalHeader
{
    border-top:solid 1px #dfdfdf;
    border-bottom:solid 1px rgb(207,207,207);
}

.smModalHeader > h2
{
    float:left;
    margin:.25em;
    max-width:85%;
    font-size:1.5em;
    line-height:1.2;
    padding:.25em;
}

.smModal.noFooter .smModalFooter
{
    display:none;
}

.smModalContent
{
/* MVS updates for responsive dialogs */
    max-height: calc(100vh - 200px);
    min-height: 56px;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
}

.smModalContent input[type="text"] 
{
    padding-left: .5em;
}

.smModal .smModalClose
{
    display:block;
    float:right;
    margin:.5em;
    padding:0;
    width:30px;
    height:30px;
    text-align:center;
    letter-spacing:0;
}

.smModal .smModalClose.simpleX 
{
    text-transform: lowercase;
}

.smModal .smModalFooter
{
    border-bottom:solid 1px #dfdfdf;
    border-top:solid 1px rgb(207,207,207);
    padding:.5em .25em;
}

.smModal .smModalFooter > button:last-child
{
    float:right;
}

.smFreeze
{
    position:fixed;
    overflow:hidden;
    width:100%;
}

.smModalContent table
{
    width:100%;
}

.smModalContent table > thead > tr
{
    background-color:#efefef;
}

.smModalContent table > thead > tr,
.smModalContent table > tbody > tr
{
    border-style:solid;
    border-width: 0 0 1px 0;
    border-color:#dfdfdf;
}

.smModalContent table > tbody > tr:nth-child(even)
{
    background-color:#f6f6f6;
}

.smModalContent table > thead > tr > th,
.smModalContent table > tbody > tr > td
{
    padding:.5em;
    line-height:1;
}

.smModalContent .fieldGroup .field 
{
    margin: .5rem;
}

.smModalContent .fieldGroup 
{
    background: linear-gradient(180deg, #fff 0%, #eee 75%);
    padding: .5em;
}

.smModalContent .fieldGroup + .fieldGroup 
{
    border-top: 1px solid #ddd;
}

.smModalContent .fieldGroup > :first-child 
{
    margin-top: 1rem;
}

/* GENERIC SMALL DIALOG STYLES */
.smModal.smallDialog
{
    max-width:500px;
    max-height:264px;
    overflow:hidden;
    margin:0;
}

.smModal.smallDialog .smModalHeader > h2
{
    margin: 0 0 0 .5em;
    font-size:1.5em;
}

.smModal.smallDialog .smModalHeader > button
{
    border-radius:0;
    max-width:50px;
    min-width:0;
}

.smModal.smallDialog .smModalContent
{
    max-height:170px;
    overflow:auto;
}

.smModal.smallDialog .smModalContent .buttonGroup
{
    position:absolute;
    bottom:1em;
    width:100%;
    text-align:center;
}

/* GENERIC MEDIUM DIALOG STYLES */
.smModal.mediumDialog
{
    max-width:600px;
}

/* COMBINING WITH 'ALERT' TO MAKE NON-CLOSABLE INFORMATIVE DIALOG */
.smModal.basicMessage
{
    
}

.smModal.basicMessage .smModalContent
{
    
}

.smModal.basicMessage .smModalContent p.msg
{
    padding:1em;
}

.smModal.basicMessage .smModalHeader
{
    background-color:transparent;
}

.smModal.basicMessage .smModalClose
{
    display:none;
}

/* ON SUCCESS */
.smModal.basicMessage.success .smModalHeader
{
    background-color:#f2fff2 !important;
}

.smModal.basicMessage.success .smModalHeader h2
{
    color:green;
    font-weight:bold;
}

.smModal.basicMessage.success .smModalContent
{
    
}
/* END PORTAL MODAL CORE RULES */

/* PORTAL DEFAULT TABLES */
.smContentArea table.portalDefault,
.smModal table.portalDefault
{
	line-height:1.5em;
	width:100%;
}

.smContentArea table.portalDefault h3,
.smModal table.portalDefault h3
{
	font-size:1.25em;
	margin:.5em 0;
	line-height:1em;
}

.smContentArea table.portalDefault span.headingSpan
{
    margin-right:1em;
}

.smContentArea table.portalDefault tbody tr,
.smModal table.portalDefault tbody tr
{
	border-bottom: solid 1px rgba(150,150,150,.1);
}

.smContentArea table.portalDefault tbody tr:last-child,
.smModal table.portalDefault tbody tr:last-child
{
    border-bottom:0;
}

.smContentArea table.portalDefault tbody > tr:nth-child(even),
.smModal table.portalDefault tbody > tr:nth-child(even)
{
	/* background-color:#dfdfdf; */
    background-color:#fff;
}

.smContentArea table.portalDefault td,
.smContentArea table.portalDefault th,
.smModal table.portalDefault td,
.smModal table.portalDefault th,
body.u4sm-theme table.portalDefault tr td
{
	padding:.5em;
    text-align:left;
	line-height:1em;
}

.smContentArea table.portalDefault th,
.smModal table.portalDefault th
{
	background-color:#dfdfdf;
	color:#666666;
}

.smContentArea table.portalDefault tr td,
.smModal table.portalDefault tr td,
body.u4sm-theme table.portalDefault tr td
{
	color:#333333;
	vertical-align:middle;
}

.smModalContent table th,
.smModalContent table td
{
    padding:.5em;
}



/* jQUERY DATEPICKER */
.ui-datepicker
{
	border-style:solid;
	border-width: 1px;
	border-color:rgb(165, 165, 165);
	box-shadow: 0px 0px .5em rgba(0,0,0,.2);
	background-color:#ffffff;
	min-width:210px;
}

.ui-datepicker-header
{
	line-height:1em;
	background-color:#efefef;
	padding:.5em;
}

.ui-datepicker-prev > span.ui-icon,
.ui-datepicker-next > span.ui-icon
{
	text-indent:0;
	cursor:pointer;
}

.ui-datepicker-prev
{
	float:left;
}

.ui-datepicker-next
{
	float:right;
}

.ui-datepicker-title
{
	text-align:center;
}

table.ui-datepicker-calendar
{
	width:100%;
}

table.ui-datepicker-calendar > tbody td
{
	border:solid 1px #efefef;
	border-collapse: collapse;
	text-align:center;
}

td.ui-datepicker-today
{
	background-color:#3788B8;
}

td.ui-datepicker-today > a
{
	color:#ffffff;
}

button.ui-datepicker-trigger
{
	background-color:rgba(0,0,0,0) !important;
	background-image: url(../img/svg/dark/icon_calendar.svg) !important;
	width:20px;
	height:20px;
	min-width:0;
	background-repeat:no-repeat;
	background-size:100%;
	background-position:50% 50%;
	background-position-x:50%;
	background-position-y:50%;
	border:0;
	margin:.15em .25em;
}

/* PAGING CONTROLS */
.smContentArea .pagerWrap
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	margin-bottom:-.5em;
	text-align:center;
}

.smContentArea .pagerWrap span.icon
{
	height:33px;
	width:33px;
	background-size:50%;
	cursor:pointer;
    border:solid 1px #fff;
    margin:1px;
    cursor:pointer;
    border-radius:2px;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
}

.smContentArea .pagerWrap span.icon:hover
{
	background-color:#3498db;
}

.smContentArea .pagerWrap span.icon.disabled:hover
{
    background-color:transparent;
}


.smContentArea .pagerWrap span.firstPage,
.smContentArea .pagerWrap span.previousPage
{
	float:left;
}

.smContentArea .pagerWrap span.lastPage,
.smContentArea .pagerWrap span.nextPage
{
	float:right;
}

.smContentArea .pagerWrap span.currentPage
{
	display:inline-block;
	line-height:2em;
    color: #fff;
}

.smContentArea .pagerWrap span.currentPage > input
{
	width:20px;
	margin:0 auto;
	text-align:center;
}

/* PAGING CONTROL REFACTORED TO USE BUTTONS INSTEAD OF ICONS - BETTER FOR SEMANTICS AND LESS COMPLEXITY FOR KEYBOARD CONTROL */
/* GET RID OF OLD PAGING RULES ABOVE WHEN CAN BE TESTED */
/* TODO: STANDARDIZE */
.smContentArea .pagerWrap button[data-records-nav-dir],
body.u4sm-page .pagerWrap button[data-records-nav-dir]
{
    margin:0;
    padding:0;
    border:0;
}



/*********************************** WIDGET-SPECIFIC RULES ***************************/
/* HOLDS WIDGET */
.smContentArea #sm360Holds .holdItem
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	background-color:rgb(255, 197, 190);
	color:rgb(187, 0, 0);
	padding:0 .5em;
	position:relative;
}

.smContentArea #sm360Holds .holdItem > p
{
	float:left;
	margin:.75em 0;
	width:80%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow:hidden;
}

.smContentArea #sm360Holds .holdItem > span.holdsItemIcon
{
	float:left !important;
	margin:.5em;
}

.smContentArea #sm360Holds ul.detailedList > li .listItem .itemSummary > span:nth-child(1)
{
    font-weight:normal;
}

.smContentArea #sm360Holds ul.detailedList > li .listItem
{
    background-color: rgb(255,225,225);
}

.smContentArea #sm360Holds ul.detailedList > li .listItem .itemSummary
{
    max-width:89%;
}

.smContentArea #sm360Holds ul.detailedList > li .listItem .itemSummary span
{
    color:red;
    display:block;
    float:left;
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    line-height:20px;
}

/* PROFILE WIDGET */
.smContentArea #sm360Profile address > div
{
    font-size:.8em;
    color:rgb(51,51,34);
}

/* BILLING SUMMARY WIDGET */
.smContentArea #sm360Billing .widgetContent div.fieldGroup.responsive
{
    text-align:center;
}

.smContentArea #sm360Billing .widgetContent div.fieldGroup.responsive > div.field
{
    float:none; /* ADDED FOR BILLING WIDGET ON 360 */
}

.smContentArea  #sm360Billing ul.triBlock
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	margin:0;
	box-sizing:border-box;
}

.smContentArea #sm360Billing ul.triBlock > li
{
	display:block;
	float:left;
	width:33.3%;
	position:relative;
	color:#ffffff;
	height:75px;
	text-align:center;
}

.smContentArea #sm360Billing ul.triBlock > li > div.field > label
{
	position:absolute;
	bottom:10%;
	left:0;
	right:0;
	margin-left:0;
	margin-right:0;
	color:#ffffff;
}

.smContentArea #sm360Billing ul.triBlock > li > div.field > label:after,
.smContentArea label.questionLabel:after
{
	content: "" !important;
}

.smContentArea #sm360Billing ul.triBlock > li > div.field > span
{
	position:absolute;
	top:25%;
	left:0;
	right:0;
	margin-left:0;
	margin-right:0;
	color:#ffffff;
	font-size: 1.3em;
	font-weight:bold;
}

.smContentArea #sm360Billing ul.triBlock > li:nth-child(1)
{
	background-color:#4E736D;
}

.smContentArea #sm360Billing ul.triBlock > li:nth-child(2)
{
	background-color:#4e655f;
}

.smContentArea #sm360Billing ul.triBlock > li:nth-child(3)
{
	background-color:#445551;
}

.smContentArea #sm360Billing ul.triBlock > li:last-child
{
	display:block;
	float:left;
	clear:both;
	width:99.9%;
	height:87px;
	background-color:#394643;
}

.smContentArea #sm360Billing ul.triBlock > li:last-child > label
{
	bottom:10px;
}

.smContentArea #sm360Billing ul.triBlock > li:last-child > span
{
	top:10px;
}

.smContentArea #sm360Billing ul.inlineFields
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	box-sizing:border-box;
	border-style:solid;
	border-width:1px 0;
	padding:.5em 0;
}

.smContentArea #sm360Billing ul.inlineFields > li
{
	display:inline-block;
	margin:0;
}

.smContentArea #sm360Billing ul.inlineFields > li div.field
{
	text-align:center;
}

.smContentArea #sm360Billing ul.inlineFields > li > div.field label,
.smContentArea #sm360Billing ul.inlineFields > li > div.field span
{
   float:none;
}

.smContentArea #sm360Billing ul.inlineFields.two > li
{
	width:49.5%;
}

.smContentArea #sm360Billing ul.inlineFields.three > li
{
	width:32.5%;
}

.smContentArea #sm360Billing ul.inlineFields.four > li
{
	width:24.5%;
}

/* SM STUDENT BILLING */
#smStudentBilling .field.inline.headerField
{
    padding:.5em;
    font-weight:bold;
}

#smStudentBilling .fieldGroup div.field.inline
{
    border-bottom-color:#9e9e9e;
} 


/* COMMUNICATIONS WIDGET */
.smContentArea #sm360Communications .messageItem
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	position:relative;
	border:solid 1px rgb(207,207,207);
	padding:.5em;
	background-color:rgb(233,233,233);
}

.smContentArea #sm360Communications .detailsShowing .messageItem
{
	border-color:rgb(51, 173, 255);
	box-shadow: inset 0px 0px 0.5em rgba(115,238,255,.5);
}

.smContentArea #sm360Communications .messageItem > span.icon
{
	float:left;
	margin: .5em 0;
	background-size:80%;
}

.smContentArea #sm360Communications .messageBasicDetails
{
	display:block;
	float:left;
	max-width:85%;
	margin:0 .5em;
}

.smContentArea #sm360Communications .messageBasicDetails > span
{
	max-width:100%;
	white-space: nowrap;
	text-overflow:ellipsis;
}

.smContentArea #sm360Communications .messageBasicDetails > span:nth-child(1) /* From */
{
	display:block;
	font-size:1em;
	color:rgb(102,102,102);
}

.smContentArea #sm360Communications .messageBasicDetails > span:nth-child(2) /* Subject */
{
	clear:both;
	color:rgb(119,119,119);
	float:left;
	font-size:.8em;
}

.smContentArea #sm360Communications .messageBasicDetails > span:nth-child(3) /* Subject */
{
	clear:both;
	color:rgb(119,119,119);
	float:left;
	font-size:.8em;
}

.smContentArea #sm360Communications .messageItem .messageFrom,
.smContentArea #sm360Communications .messageItem .messageSubject
{
	display:block;
	float:left;
	max-width:85%;
	white-space: nowrap;
	text-overflow:ellipsis;
}

.smContentArea #sm360Communications .messageItem .messageTimeDate
{
	float:right;
	margin-right:10px;
}

.smContentArea #sm360Communications .messageControls
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	text-align:right;
	margin:.25em 0;
}

.smContentArea #sm360Communications .messageFullDetails
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	padding:.25em;
	border:solid 1px #dfdfdf;
	margin:.25em 0;
}

.smContentArea #sm360Communications .listItem .messageHeader
{
	border-bottom:solid 1px #cfcfcf;
}

.smContentArea #sm360Communications .listItem .messageHeader > .fieldGroup.stacked
{
    margin:-1.5em 0 .5em 0;
}

.smContentArea #sm360Communications .listItem .messageHeader > .fieldGroup.stacked > .field
{
    margin:0;
    padding:0;
    border:0;
}

.smContentArea #sm360Communications .listItem .messageBody
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	padding:.25em;
	border:solid 1px rgba(179,179,179,1);
	margin:.25em 0;
}

.smContentArea #sm360Communications .messageHeader > .fieldGroup
{
	margin: .5em 0;
}

/* SPECIAL FOR NEW/REPLY MESSAGE AREA */
.smContentArea #sm360Communications .newOrReplyMessage .fieldGroup:first-child > div.field > label
{
	width: 60px;
	text-align:right;
	padding-right:.5em;
}

.smContentArea #sm360Communications .newOrReplyMessage .fieldGroup:first-child > div.field > span,
.smContentArea #sm360Communications .newOrReplyMessage .fieldGroup:first-child > div.field > input
{
	width:75%;
}

.smContentArea #sm360Communications .newOrReplyMessage div.field > textarea
{
	width:98%;
	margin:.5em 0;
	min-height:200px;
	resize:vertical;
}

/* FINANCIAL AID WIDGET */
.smContentArea #sm360FinancialAid .fieldGroup div.field.inline,
.smContentArea .fieldGroup div.field.inline
{
    border-bottom:solid 1px #dfdfdf;
}

.smContentArea #sm360FinancialAid .fieldGroup div.field.inline:last-child
{
    border:0;
}

.smContentArea #sm360Enrollments table.portalDefault tr td:nth-child(4) .field
{
    padding: 0.5em 0;
}
.smContentArea #sm360Enrollments table.portalDefault tr td:nth-child(4) .field:last-child
{
    display: none;
}

.smContentArea #sm360Communications .newOrReplyMessage .fieldGroup:nth-child(2) > div.field > .cke
{
    width: 75%;
    display: inline-block;
}

.smContentArea #sm360Communications .newOrReplyMessage .fieldGroup:first-child > div.field > label,
.smContentArea #sm360Communications .newOrReplyMessage .fieldGroup > div.field > label
{
    width: 75px;
}

.u4sm-theme .cke_top,
.u4sm-theme .cke_bottom
{
    background-image: none !important;
}

/*********************************** END WIDGET-SPECIFIC RULES ***************************/


/*********************************** PAGE-SPECIFIC RULES ***************************/
/* GUIDED APPLICATION RULES */
.smContentArea .templateContainer,
.smContentArea .summaryContainer,
.smContentArea .summaryContainer .summaryHeader,
.smContentArea .applicationHeader > .sectionNav,
.smContentArea .applicationContent,
.smContentArea .applicationContent .sectionHeader,
.smContentArea .applicationContent .sectionFooter
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smContentArea .applicationContainer
{
    display:none;
}

.smContentArea .applicationContainer section.applicationContent.first-child
{
    padding:.25em;
}

.smContentArea .applicationHeader
{
    display:none;
    text-align:center;
}

.smContentArea .applicationHeader > .sectionNav
{
    margin-top:.5em;
}

.smContentArea .applicationSaveSummary-off
{
    display:none;
    float:left;
    clear:both;
    width:100%;
    padding:.5em;
    text-align:center;
    font-size:1.2em;
}

.smContentArea .applicationFeeSummary
{
    display:block;
    z-index:1;
    padding:.5em;
    text-align:center;
    background-color:rgb(255,224,172);
    color:rgb(125,68,6);
    font-size:.8em;
    margin: 0 0 1em 0;
}

/* APPLICATION MEDIUM DIALOG RULES */
.smModal.mediumDialog .smModalContent .partialApplicationContainer thead
{
    background-color:#efefef;
}

.smModal.mediumDialog .smModalContent .partialApplicationContainer tbody tr
{
    border-bottom:solid 1px #dfdfdf;
    cursor:pointer;
}

.smModal.mediumDialog .smModalContent .partialApplicationContainer tbody tr:hover
{
    background-color:#C6F6FF;
}

.smContentArea .applicationSaveSummary-off span.incremental-detail
{
    font-size: .75em;
}

/* APPLICATION SMALL DIALOG RULES */
.smModal.smallDialog .smModalContent .partialApplicationContainer > p
{
    line-height:1;
    margin:.5em 0;
}

.smModal.smallDialog .smModalContent .partialApplicationContainer thead
{
    background-color:#efefef;
}

.smModal.smallDialog .smModalContent .partialApplicationContainer thead th
{
    line-height:1;
    padding:.5em;
}

.smModal.smallDialog .smModalContent .partialApplicationContainer tbody tr
{
    border-bottom:solid 1px #dfdfdf;
    cursor:pointer;
}

.smModal.smallDialog .smModalContent .partialApplicationContainer tbody tr td
{
    padding:.5em;
}

.smModal.smallDialog .smModalContent .partialApplicationContainer tbody tr:hover
{
    background-color:#C6F6FF;
}

.smModal.smallDialog .smModalContent .partialApplicationContainer tr.smClickRow > td:last-child
{

}

/* APP CONTENT AREA */
.smContentArea .templateContainer
{
    overflow-y:auto;
    overflow-x:hidden;
}

.smContentArea .templateContainer .fieldGroup h3
{
    line-height:1.5em;
    padding:.5em;
    max-width:100%;
}

.smContentArea .applicationContent .sectionHeader button,
.smContentArea .applicationContent .sectionFooter button
{
    border-radius:0;
    position:relative;
    display:block;
    min-width:0;
    height:44px;
    border:solid 1px #fff;
    background-color:rgb(110, 147, 171);
    color:#fff;
    margin:0;
}

.smContentArea .applicationContent .sectionHeader button:hover,
.smContentArea .applicationContent .sectionFooter button:hover,
.smContentArea .applicationContent .sectionHeader button:focus,
.smContentArea .applicationContent .sectionFooter button:focus
{
    background-color:rgb(52, 152, 219);
}

.smContentArea .applicationContent .sectionHeader button.disabled,
.smContentArea .applicationContent .sectionFooter button.disabled,
.smContentArea .applicationContent .sectionHeader button.disabled:hover,
.smContentArea .applicationContent .sectionFooter button.disabled:hover,
.smContentArea .applicationContent .sectionHeader button.disabled:focus,
.smContentArea .applicationContent .sectionFooter button.disabled:focus
{
    background:none;
}

.smContentArea .applicationContent .sectionHeader,
.smContentArea .applicationContent .sectionFooter
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
    overflow:hidden;
    position:relative;
    border:solid 1px rgb(207, 207, 207);
    background-color:rgb(86,108,131);
    color:#fff;
}

.smContentArea .sectionHeader > div.sectionTitle
{
    text-align:center;
    background-color:rgb(233, 233, 233);
}

.smContentArea .applicationContent > .sectionHeader > div.sectionTitle
{
    /* display:inline-block !important; */
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    -webkit-transform:translateX(-50%);
    -moz-transform:translateX(-50%);
    background-color:transparent;
}

.smContentArea .applicationContent .sectionHeader > div.sectionTitle > h2,
.smContentArea .sectionHeader > div.sectionTitle > h2
{
    display:inline;
    color:#fff;
    font-size:1.5em;
}
.smContentArea .applicationContent .sectionHeader > div.sectionTitle .stepIndicator
{
    display:inline-block;
    position:relative;
    display:none;
}

.smContentArea .applicationContent #FullInquiryContainer .sectionHeader > div.sectionTitle > h2,
.smContentArea #FullInquiryContainer .sectionHeader > div.sectionTitle > h2
{
    color:#666;
}

#FullInquiryContainer .fieldGroup.split.fiftyfifty .field > label:not(.requiredLabel)
{
    margin-left:.25em;
}

.smContentArea .applicationContent .sectionHeader #goToPreviousSectionButton span.icon,
.smContentArea .applicationContent .sectionHeader #goToNextSectionButton span.icon,
.smContentArea .applicationContent .sectionFooter #goToPreviousSectionButtonBottom span.icon,
.smContentArea .applicationContent .sectionFooter #goToNextSectionButtonBottom span.icon
{
    background-size:40px;
}

.smContentArea .applicationContainer
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smContentArea .applicationContainer input[type="password"]
{
    padding:0;
}

.smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > label
{
    font-weight:600;
    margin:0 .5em 0 0;
}

/** INLINE STEP LIST **/
.smContentArea .applicationSidebar
{
    /* disabling after query refactor overflow:hidden; */
}

.smContentArea .applicationSidebar
{
    float:left;
    text-align:left;
    width: 29%;
    display:block;
    padding:5px 1px;
}

.smContentArea .applicationContentArea
{
    float:right;
    width: 70.5%;
    padding:5px 0;
}

.smContentArea .appsecList > li
{
    display: block;
    width:100%;
    min-width: 160px;
}

.smContentArea .appsecList .appsecSpacer
{
    display:none;
}

.smContentArea .applicationSidebar
{
    width:25%;
}

.smContentArea .applicationContentArea
{
    width:74%;
    margin-left:.25em;
}

.smContentArea .applicationHeader ul.appsecList
{
    display:inline-block;
    float:none;
    margin:0 auto;
}

.smContentArea .applicationHeader .appsecList > li,
.smContentArea .applicationSidebar .appsecList > li
{
    display: block;
    float:left;
    margin: 0 .25em;
    border: 0;
    cursor: pointer;
    position:relative;
}

.smContentArea .applicationHeader .appsecList > li
{
    clear:none;
}

.smContentArea .applicationSidebar .appsecList > li
{
    clear:both;
    margin:2.5px 0;
    height:33px;
    border-top-right-radius:2px;
    border-bottom-right-radius:2px;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;
}

.smContentArea .applicationHeader .appsecList > li.active,
.smContentArea .applicationSidebar .appsecList > li.active
{
    opacity:1;
    overflow:visible;
    box-shadow:0 0 .5em rgba(0,0,0,.2);
}

.smContentArea .applicationHeader .appsecList > li > span.appsecIndex,
.smContentArea .applicationSidebar .appsecList > li > span.appsecIndex
{
    display: block;
    float: left;
    clear: none;
    background-color: #A9A9A9;
    color: white;
    padding: 0px;
    height: 33px;
    line-height: 35px;
    width: 35px;
    text-align: center;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
    border-top-left-radius:2px;
    border-bottom-left-radius:2px;
    box-shadow:inset 0 0 1px rgb(74, 74, 74),0 0 .5em rgba(0, 0, 0, 0.1);
}

.smContentArea .applicationHeader .appsecList > li.active > span.appsecIndex,
.smContentArea .applicationSidebar .appsecList > li.active > span.appsecIndex
{
    background-color: #3B8DBD;
    font-weight:bold;
    box-shadow:0 0 .5em rgba(0, 0, 0, 0.2)
}

.smContentArea .applicationHeader .appsecList > li > span.appsecTitle,
.smContentArea .applicationSidebar .appsecList > li > span.appsecTitle
{
    padding: 5px;
    float:left;
    clear:none;
    display:none;
}

.smContentArea .applicationSidebar .appsecList > li > span.appsecTitle
{
    display:block;
    padding:0 10px !important;
    line-height:2.25;
    width:80%;
    margin-right:-100%;
    color:#000000;
    background-color:rgb(237,237,237);
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
    border-top-right-radius:3px;
    border-bottom-right-radius:3px;
}

.smContentArea .applicationHeader .appsecList > li.active > span.appsecTitle,
.smContentArea .applicationSidebar .appsecList > li.active > span.appsecTitle
{
    display:block;
    padding: 0px;
    display:block;
    float:left;
    clear:none;
    color:#fff;
    background-color:rgb(59, 141, 189);
    font-weight:bold;
}

.smContentArea .applicationHeader .appsecList > li.active > span.appsecTitle
{
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    width:40px;
}

.smContentArea .appsecItem > span.appsecStatus
{
    display:block;
    float:right;
    width:25px;
    height:33px;
    clear:none;
    padding:5px;
    background-color:rgb(212,212,212);
    border-top-right-radius:2px;
    border-bottom-right-radius:2px;
}

.smContentArea .applicationHeader .appsecList > li.appsecSpacer
{
    opacity:1;
    border:0;
    cursor:default;
    background-image: url('../img/svg/dark/icon_advanceSingle.svg');
    background-repeat:no-repeat;
    background-position:50%;
    background-size:35px;
    width:15px;
    height:30px;
}

/* STATUS on ACTIVE section */
.smContentArea .appsecList .appsecItem span.appsecStatus,
.smContentArea .summaryContainer .appsecItem span.appsecStatus
{
    background-repeat:no-repeat;
    background-size:60%;
    background-position:50%;
    -webkit-transition: background-color .5s ease;
    border-top-right-radius:2px;
    border-bottom-right-radius:2px;
}

/* STATUS - IS Required, NOT Completed */
.smContentArea .appsecItem.appsecReqtrueComfalse > span.appsecStatus
{
    background-color:rgb(255, 228, 228) !important;
    background-image: url('../img/svg/icon_asterisk_red.svg');
}

/* STATUS - NOT Required, NOT Completed  */
.smContentArea .appsecItem.appsecReqfalseComfalse > span.appsecStatus
{
    background-color: #efefef;
    background-image: url('../img/svg/icon_checkmark_grey.svg');
}

/* STATUS - IS Required, IS Completed  */
/* STATUS - NOT Required, IS Completed  */
.smContentArea .appsecItem.appsecReqtrueComtrue > span.appsecStatus,
.smContentArea .appsecItem.appsecReqfalseComtrue > span.appsecStatus
{
    background-color: #E9FFE9 !important;
    background-image: url('../img/svg/icon_checkmark_green.svg');
}

/* PORTAL APPLICATION - FIELDGROUP OVERRIDES */
.smContentArea .applicationContent .fieldGroup
{
    padding:0;
}

.smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > label,
.smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > span,
.smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > input,
.smContentArea .applicationContent .fieldGroup.split.fiftyfifty > div.field > textarea
{
	width:35%;
}

.smContentArea .summaryContainer div.summaryHeader
{
    background-color:#DFDFDF;
    border-style:solid;
    border-width:1px 0;
    border-color:#ffffff;
    font-weight:bold;
}

.smContentArea .summaryContainer div.summaryHeader > h3
{
    float:left;
    padding-left:.5em;
    font-weight:bold;
}

.smContentArea .summaryContainer div.summaryHeader > span.appsecStatus
{
    height:39px;
    width:39px;
    background-size:50%;
}

.smContentArea .summaryContainer div.summaryHeader > button.sectionLink
{
    display:block;
    float:right;
    margin:.35em .5em;
}

.smContentArea .summaryContainer div.fieldGroup
{
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#CFCFCF;
}

.smContentArea .summaryContainer .fieldGroup.stacked div.field.inline
{
    padding:0;
    margin:.5em;
}

.smContentArea .summaryContainer div.fieldGroup div.field
{
    border:0;
}

.smContentArea .summaryContainer .fieldGroup > p
{
    color:#000000;
    font-size:1.25em;
    padding:.5em;
    background-color:#FFFFAB;
}

.smContentArea .summaryContainer div.fieldGroup div.field > button
{
    font-size:1.25em;
    padding:.5em;
}

.smContentArea .summaryContainer .fieldGroup.split > div.field
{
    padding:0;
    margin:0;
}

.smContentArea .summaryContainer .fieldGroup .field span.address
{
    clear:both;
    width:100%;
}

.smContentArea .summaryContainer .fieldGroup table
{
    width:100%;
}

.smContentArea .summaryContainer .fieldGroup table thead tr
{
    background-color:#F2F2F2;
    color:#999;
}

.smContentArea .summaryContainer .fieldGroup table thead th
{
    font-weight:normal;
    color:#666;
}

.smContentArea div.validation-summary
{
    padding: 5px;
    background-color: rgb(241, 177, 177);
    color: black;
}

.smContentArea div.validation-summary > span
{
    font-weight: bold;
    color: black;
}

.smContentArea div.validation-summary > ul > li.validation-message > span
{
    font-weight: bold;
    color: black;
}

/* TEST SCORES SUMMARY */
.smContentArea .summaryContainer .scoresSection
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smContentArea .summaryContainer .scoresSection > h4
{
    text-align:center;
    padding:1em 0;
    padding-bottom:.5em;
}

.smContentArea .summaryContainer .scoresContainer
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    border:solid 1px #dfdfdf;
    padding:.5em;
    margin:.5em 0;
    background-color:#ffffff;
}

.smContentArea .summaryContainer .scoresContainer > .fieldGroup:last-of-type
{
    border:0;
}

.smContentArea .summaryContainer .scoresContainer > h4
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
    padding:.5em;
    margin-top:.5em;
}

.smContentArea .summaryContainer .scoresContainer .fieldGroup
{

}

.smContentArea .summaryContainer .scoresContainer .fieldGroup.headerGroup
{
    width:100%;
    background-color:#E7E7E7;
    padding-top:.5em;
    border:solid 1px #cfcfcf;
}

.smContentArea .summaryContainer .scoresContainer .fieldGroup .field.inline
{
    margin:.25em .5em;
}




/* SPECIFIC BUTTON COLORING */
#goToSummaryButton,
#submitApplicationButton
{
    background-image:none;
    border:solid 1px #ffffff;
    text-shadow: none;
    box-shadow:none !important;
    color:#ffffff;
}

#goToSummaryButton
{
    background-color:#FF8D00 !important;
    position:absolute;
    right:0;
}

#submitApplicationButton
{
    background-color:#11AB00 !important;
}

button.attend_present.active /* THEME IRRESPECTIVE */
{
    color: #5d7731;
    border-bottom: 1px solid #799545;
    text-shadow: 0px 1px 0px #d5e8aa;
    background: #cae285;
    background: -webkit-gradient(linear, left top, left bottom, from(#cae285), to(#a3cd5a));
    background: -moz-linear-gradient(top, #cae285, #a3cd5a);
    box-shadow: inset 1px 1px 0 #cce3a1;
}

button.attend_absent.active /* THEME IRRESPECTIVE */
{
    color: #773131;
    border-bottom: 1px solid #954545;
    text-shadow: 0px 1px 0px #E8B8AA;
    background: #E29285;
    background: -webkit-gradient(linear, left top, left bottom, from(#E28585), to(#CD6A5A));
    background: -moz-linear-gradient(top, #E28585, #CD6A5A);
    box-shadow: inset 1px 1px 0 #E3A1A1;
}

button.attend_late.active /* THEME IRRESPECTIVE */
{
    color: #996633;
    border-bottom: 1px solid #b98a37;
    text-shadow: 0px 1px 0px #fedd9b;
    background: #feda71;
    background: -webkit-gradient(linear, left top, left bottom, from(#feda71), to(#febe4d));
    background: -moz-linear-gradient(top, #feda71, #febe4d);
    box-shadow: inset 1px 1px 0 #fee9aa;
}

button.attend_late > span.icon
{
    background-size: 1.35rem !important;
}

button.attend_excused.active /* THEME IRRESPECTIVE */
{
    color: #515f6a;
    border-bottom: 1px solid #62727e;
    text-shadow: 0px 1px 0px #c4d0da;
    background: #becbd6;
    background: -webkit-gradient(linear, left top, left bottom, from(#becbd6), to(#8da5b7));
    background: -moz-linear-gradient(top, #becbd6, #8da5b7);
    box-shadow: inset 1px 1px 0 #d8e0e6;
}

/* DISABLED BUTTON STYLE FOR PORTAL */
button.submitGradesBtn
{
    padding: 0 .5em !important
}

button.disabledState,
button.disabledState:hover
{
    padding: .25em .5em !important;
    font-size:.7em !important;
    color:#999 !important;
    border-color:#999 !important;
    opacity:.4;
    cursor:not-allowed;
    font-style:italic;
    background:none;
}

/* INCREMENTAL SAVE */
.smContentArea .applicationSaveSummary
{
    display:block;
    position:absolute;
    z-index:1;
    height:100%; /* ON RESIZE, ADJUST THIS TO MATCH .sectionHeader HEIGHT */
    width:70%;
    text-align:center;
    color:#00932E;
    background-color:rgb(247, 247, 247);
    font-size:1.2em;
    line-height:2.5;
    left:50%;
    transform:translateX(-50%);
    -webkit-transform:translateX(-50%);
    -moz-transform:translateX(-50%);
}

.smContentArea .applicationSaveSummary span.incremental-detail
{
    font-size: .75em;
}

/* EVENTUAL SPLASH SCREEN FOR GUIDED APP */
.smContentArea .smApplicationSplash
{
    display:none;
}

.smContentArea .smApplicationSplash.active
{
    display:block;
}

.smContentArea .portalApplicationResult
{
    text-align:center;
}

.smContentArea .portalApplicationResult > div
{
    margin:1em 0;
}

.smContentArea .portalApplicationResult > a.button
{
    margin: 1em;
}
/* END PORTAL APPLICATION SPECIFIC RULES */

/* INQUIRY FORM RULES */
#inquiryForm
{
    background-color:#fff;
}

#inquiryForm section
{
    background-color:rgb(247,247,247);
    margin-bottom:.5em;
}

.smContentArea #inquiryForm div.field > select,
.smContentArea #applicationContainer div.field > select,
.smContentArea #PaymentContainer div.field > select,
.smContentArea #profile div.field > select
{
    clear:none;
}


.smContentArea .fieldGroup > div.field.programOption
{
    padding:10px 0;
    border-style:none;
}

.smContentArea .fieldGroup > div.field.programOption.last
{
    padding: 10px 0;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #dfdfdf;
}

.smContentArea div.field.programOption.error,
.smContentArea div.field.programOption.last.error
{
    background-color:rgb(255, 206, 206);
}


.smContentArea .fieldGroup.split.fiftyfifty > div.field > input[type="radio"]
{
    width: auto;
}

/* BORDER FOR INPUT FIELDS IN 50/50 SPLIT */
.smContentArea .fieldGroup.split.fiftyfifty > div.field > input[type="text"],
.smContentArea .fieldGroup.split.fiftyfifty > div.field > input[type="password"]
{
    padding:0 .5em;
    background-color:#fff;
}

.smContentArea div.field.hsDateRange > input[type="text"]
{
    display: inline-block;
}

/* PROFILE PAGE */
.smContentArea #profile .fieldGroup.split.fiftyfifty > div.field > select
{
    max-width:50%;
}

.smContentArea #profile ul.detailedList > li .listItem > .buttonGroup
{
    width:100%;
}

/* My Profile */
.smContentArea#smStudentProfile .relationshipListContainer label
{
    font-weight: normal;
    color: #727272;
}

/* My Profile */
.smContentArea#smStudentProfile .relationshipListContainer span
{
    color: #333;
}

/* My Profile */
.smContentArea#smStudentProfile .wrapWord
{
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
}

/* My Profile (282) & My Financial Aid (274)*/
.smContentArea#smStudentProfile .flexWidget .fieldGroup div.field,
.smContentArea#smStudentFinancialAid .flexWidget .fieldGroup div.field
{
    margin: 0;
    padding: 3.5px 0;
}

/* My Profile */
.smContentArea#smStudentProfile .flexWidget.profileHead .fieldGroup div.field.inline
{
    border: 0;
}

/* My Profile */
.smContentArea#smStudentProfile .flexWidget .widgetContent .buttonGroup
{
    clear: none;
}

/* My Profile */
.smContentArea#smStudentProfile .flexWidget .editorShowing .buttonGroup.inView
{
    display: none;
}

/* My Profile */
.smContentArea#smStudentProfile .flexWidget .detailedList > li:not(.editorShowing) .buttonGroup.inEdit
{
    display: none;
}

/* My Profile */
.smContentArea#smStudentProfile .flexWidget address
{
    line-height: 1.5;
}

/* My Profile */
.smContentArea#smStudentProfile ul.detailedList > li .listItem .itemEditor
{
    padding-top: 1em;
}

/* My Profile */
.smContentArea#smStudentProfile ul.detailedList > li .listItem .itemSummary
{
    max-width: 79%;
    width: 79%
}

/* My Profile */
.smContentArea#smStudentProfile ul.detailedList > li.editorShowing .listItem .itemSummary
{
    max-width: 100%;
    width: 100%
}

/* My Profile */
.smContentArea#smStudentProfile .itemSummary > .fieldGroup
{
    width: 55%;
    float: right;
    clear: none;
}

/* My Profile */
.smContentArea#smStudentProfile .itemSummary > .fieldGroup:first-child
{
    width: 45%;
    float: left;
}

/* My Profile */
.smContentArea#smStudentProfile .flexWidget:not(.profileHead) .widgetContent div.field.inline
{
    width: 100%;
}

/* My Profile */
.smContentArea#smStudentProfile [data-list-item="phoneNumber"] .itemSummary > .fieldGroup:first-child .field
{
    float: left;
    clear: both;
}

/* My Profile */
.smContentArea#smStudentProfile .widgetContent div.field.inline > span
{
    margin-right: .5em;
}

.smProfileBlock .smProfileDetails
{
    float: left;
    max-width: 100%;
    padding-right: 11rem;
    text-align: left;
}
.smProfileBlock .smProfileDetails p
{
    margin: 0;
    color: white;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nav-menu .offscreen-toggle
{
    float: right !important;
    width: auto !important;
    position: absolute !important;
}

/*
 * Accessibility
 * Do not set outline to none
*/
.smProfileBlock button
{
    -webkit-appearance:none;
    padding:.5em;
    margin:.25em;
    /*outline:none;*/
    cursor:pointer;
    transition:all .5s ease;
    min-width:0;
    border: solid 1px #fff !important;
    border-radius: 2px !important;
}
.smProfileBlock button:hover
{
    background-color: #3498DB;
    color: #fff;
}

.smProfileBlock button span.btnText
{
    position: relative;
    margin-right: .5em;
    top: 9px;
    color:#fff;
}

.nav-menu .offscreen-toggle.sm-authenticated .smProfileImage
{
    width: 25px;
    height: 25px;
    background-color: transparent;
}

.nav-menu .offscreen-toggle.sm-authenticated .smProfileImage > span.personIconWhite
{
    width: 25px;
    height: 25px;
}

#secProfileHeader .smProfileLink 
{
    margin: -.5em 0 .5em;
}

#secProfileHeader .smProfileLink a 
{
    color: white;
    text-decoration: underline;
}

#secProfileHeader .smProfileLink a:hover 
{
    text-decoration: none;
}

.u4sm-theme .smContentArea .profileHead .widgetHeader h2 
{
    display: block !important;
    margin: 0;
}

/* END PAGE-SPECIFIC RULES */
































































/* BEGIN PORTAL AUDIT EVALUATION */
.smContentArea .flexWidget.large .fieldGroup.splitField > .field.inline.currency /* REFACTOR FOR BROAD USAGE */
{
    width:auto;
    padding:.5em;
}

.smContentArea ul.detailedList.reqList > li .listItem .itemSummary
{
    max-width:none;
}

.smContentArea ul.detailedList.reqList > li .itemSummary > .itemLongDescription,
.smContentArea ul.detailedList.reqList > li .itemDetails > .itemLongDescription
{
    float:left;
    clear:both;
    width:100%;
    margin-bottom:1em;
    color:rgb(51,51,51);
    line-height:1.5em;
    padding:0 .5em;
}

.smContentArea .detailedList.reqList > li .itemDetails > .itemLongDescription.isPhone
{
    padding-top:.5em;
    margin-bottom:.5em;
}

.smContentArea .detailedList.reqList > li .itemSummary > .itemLongDescription
{
    margin-top:1em;
}

.smContentArea .detailedList.reqList > li .itemSummary > .itemLongDescription.notPhone
{
    margin-top:0;
    margin-bottom:0;
}

.smContentArea ul.detailedList.reqList .listItem .itemDetails.active
{
    padding-top:0;
}

.smContentArea ul.detailedList.reqList > li .listItem .itemSummary > span:nth-child(1)
{
    font-size:1.25em;
    overflow:visible;
    margin-left:.4em;
}

.smContentArea ul.detailedList.reqList > li .listItem .itemSummary > span:nth-child(1):after
{
    content:"";
}

.smContentArea ul.detailedList.reqList > li .listItem .itemSummary > .fieldGroup
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:0 .5em;
    border:0;
}

.smContentArea ul.detailedList.reqList > li .listItem .itemSummary > .fieldGroup > div.field.inline
{
    border-bottom:solid 1px #dfdfdf;
    padding:.25em 0;
}

.smContentArea ul.detailedList.reqList > li .listItem .itemSummary > .fieldGroup > div.field.inline:after
{
    content:"|";
    margin:0 .5em;
    position:relative;
    top:-2px;
}

.smContentArea ul.detailedList.reqList > li .listItem .itemSummary > .fieldGroup > div.field.inline:last-child:after
{
    content:"";
}

.smContentArea .reqList .isPhone
{
    display:none;
}

.smContentArea ul.detailedList.reqList > li .listItem > span.progressIndicator
{
    height: 50px;
    width:50px;
    background-size:80%;
    background-color:#fff;
}

.smContentArea ul.detailedList.reqList > li .listItem span.icon.detailsIcon
{
    top:35px;
}

.smContentArea ul.courseList.isPhone table.portalDefault th
{
    padding:0;
    background:none;
    border-bottom:solid 1px #dfdfdf;
    border-right:solid 1px #dfdfdf;
    padding:0 .25em;
    text-align:center;
}

.smContentArea ul.courseList.isPhone table.portalDefault th:last-child,
.smContentArea ul.courseList.isPhone table.portalDefault td:last-child
{
    border-right:0;
}

.smContentArea ul.courseList.isPhone table.portalDefault td
{
    text-align:center;
    border-right:solid 1px #dfdfdf;
}

.smContentArea ul.detailedList.reqList > li .listItem
{
    padding:.5em 0;
}

.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone th
{
    background-color:rgb(180,180,180);
    color:#fff;
    font-weight:bold;
}

.smContentArea ul.detailedList.reqList button.reqCoursesNeeded
{
    text-align:center;
    line-height:1em;
    padding:1em;
    background-color:#fff;
}

.smContentArea ul.detailedList.reqList button.reqCoursesNeeded:hover
{
    background-color:#3498DB;
    color:#fff;
}

.smContentArea li.reqViewApplicableCourses,
.smContentArea ul.detailedList.reqList .reqViewApplicableCourses > td,
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone th:nth-child(4),
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone th:nth-child(5),
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone th:nth-child(6),
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone th:nth-child(7),
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone td:nth-child(4),
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone td:nth-child(5),
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone td:nth-child(6),
.smContentArea ul.detailedList.reqList > li .listItem > .itemDetails > table.portalDefault.notPhone td:nth-child(7)
{
    text-align:center;
}

/* REQUIREMENTS LEGEND */

.smContentArea .flexWidget ul.reqStatusCodes
{
    background-color:#ffffff;
    border:solid 1px #dfdfdf;
    text-align:center;
    padding:.5em;
    margin-bottom:.5em;
    color:#666;
}

.smContentArea .flexWidget ul.reqStatusCodes > li
{
    display:inline-block;
}

.smContentArea .flexWidget ul.reqStatusCodes > li:after
{
    content: "|";
}

.smContentArea .flexWidget ul.reqStatusCodes > li:last-child:after
{
    content:"";
}

.smContentArea .flexWidget ul.reqStatusCodes b
{
    font-weight:bold;
}

.smContentArea .flexWidget ul.auditReqPercentages
{
    text-align:center;
    background-color:#fff;
    border:solid 1px #dfdfdf;
    margin-bottom:.25em;
    padding-top:.5em;
    color:#666;
}

.smContentArea .flexWidget ul.auditReqPercentages > li
{
    display:inline-block;
    margin:0 1em;
}

.smContentArea .flexWidget ul.auditReqPercentages > li > span
{
    display:block;
    float:left;
    width:15px;
    height:15px;
    margin-top:.27em;
    border:solid 1px #999;
}

.smContentArea .flexWidget ul.auditReqPercentages > li > p
{
    display:block;
    float:left;
    margin-top:1px;
    margin-left:.5em;
}

.smContentArea .auditReqProgress
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    position:relative;
    height:27px;
    background-color:#ffffff;
    margin-top:-.5em;
    margin-bottom:.5em;
    box-shadow:inset 0px 0px 1.5em rgba(0,0,0,.2);
    border:solid 1px #ffffff;
}

.smContentArea .auditReqProgress > span
{
    display:block;
    float:left;
    height:25px;
    line-height:25px;
    text-align:center;
    font-weight:bold;
}

.smContentArea .auditReqCompleted
{
    background-color:rgb(100, 149, 0);
    color:rgb(255,255,255);
}

.smContentArea .auditReqInprogress
{
    background-color:rgb(195, 237, 110);
    color:rgb(99, 120, 55);
}

.smContentArea .auditReqPlanned
{
    background-color:rgb(255, 194, 0);
}

.smContentArea .auditReqProgress > span.auditReqTotal
{
    float:right;
    margin-right:.5em;
    color: #bbb;
    position: absolute;
    right: 0;
}

/* MILESTONES UI */
.smContentArea .reqMilestonesList
{
    display:block;
    float:left;
    clear:both;
    width:98%;
    margin-left: 1% !important;
    margin-top:.5em !important;
}

.smContentArea .reqMilestonesList > li
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    border-style:solid;
    border-width:1px;
    border-color:rgb(207,207,207);
    background-color:rgb(255,255,255);
    margin-bottom:.5em;
}

.smContentArea .reqMilestonesList .milestoneHeader,
.smContentArea .reqMilestonesList .milestoneDetails
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smContentArea .reqMilestonesList .milestoneHeader
{
    padding:.5em;
}

.smContentArea .reqMilestonesList .milestoneHeader > h3
{
    color:rgb(102, 102, 102); /* THEME THIS */
    font-size:1em;
    margin:.5em;
    font-weight:bold;
    float:left;
}

.smContentArea .reqMilestonesList .milestoneHeader > div.field.inline
{
    margin-top:.5em;
    font-size:.9em;
}

.smContentArea .reqMilestonesList .milestoneHeader > h3:after
{
    content:"|";
    margin: 0 .25em 0 .5em;
}

.smContentArea .reqMilestonesList .milestoneDetails
{
    padding:0 1em;
}

.smContentArea .reqMilestonesList .milestoneDetails .cols
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smContentArea .reqMilestonesList .milestoneDetails .cols.two > .col
{
    display:block;
    float:left;
    width:50%;
}

.reqMilestonesList .milestoneDetails .cols.two > .col > div.field.inline
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    margin:.25em 0;
}

.smContentArea .reqMilestonesList .milestoneDetails .cols.three > .col
{
    width:33%;
}

.smContentArea .reqMilestonesList .milestoneDetails .cols.four > .col
{
    width:25%;
}

.smContentArea .reqMilestonesList .milestoneDetails .cols.five > .col
{
    width:20%;
}

.smContentArea .reqMilestonesList .milestoneDetails > p,
.smModal .reqMilestonesList .milestoneDetails > p
{
    color:rgb(51, 51, 51);
    display:block;
    float:left;
    clear:both;
    width:100%;
    margin-bottom:.5em;
}

.smContentArea .reqMilestonesList .milestoneDetails div.field.inline,
.smModal .reqMilestonesList .milestoneDetails div.field.inline
{
    margin:.5em .5em .5em 0;
    display:inline-block;
}

.smContentArea ul.reqCourseList > li,
.smModal ul.reqCourseList > li
{
    display:block;
    box-sizing:border-box;
    float:left;
    clear:both;
    width:100%;
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#dfdfdf;
    padding:.25em 1em;
}

.smContentArea ul.reqCourseList > li p,
.smModal ul.reqCourseList > li p
{
    float:left;
    max-width:90%;
    margin:0;
}

.smContentArea ul.reqCourseList > li > span.icon,
.smModal ul.reqCourseList > li > span.icon
{
    float:right;
}

.smContentArea ul.reqCourseList > li b,
.smModal ul.reqCourseList > li b
{
    font-weight:bold;
}

.smContentArea ul.reqCourseList > li.active,
.smModal ul.reqCourseList > li.active
{
    border-style:solid;
    border-width:1px;
    border-color:rgb(51, 173, 255);
}

.smContentArea ul.reqCourseList > li.active > p,
.smModal ul.reqCourseList > li.active > p
{
    margin-top:.5em;
    font-weight:bold;
}

.smContentArea ul.reqCourseList > li.active b,
.smModal ul.reqCourseList > li.active b
{
    color:rgb(51, 173, 255);
}

.smContentArea ul.reqCourseList > li > .itemDetails,
.smModal ul.reqCourseList > li > .itemDetails
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    border-top:solid 1px #dfdfdf;
    padding:.5em;
    background-color:rgb(247,247,247);
    color:rgb(51,51,51);
    margin: .5em 0;
}

/* END PORTAL AUDIT EVALUATION */

/* SIMULATE BUTTON HOVER */
.force-hover
{
    background: none;
    color: #fff;
    border-color: #3498db;
    background-color: #3498db;
}

/* SM FANCY LINK LIST */
.smFancyLinkList h3
{
	margin-bottom:1em;
    text-align:center;
}

/* SM TEXT IMAGE BLOCKS */
.smTextImageBlock
{
	position:relative;
	overflow:hidden;
}

.smTextImageBlock .imgBlock img
{
	margin:0;
	max-width:100%;
	width:100%;
}

/* smTextImageBlock hoverText - shows image, hover for text */
.smTextImageBlock.hoverText .contentBlock
{
	display:block;
	top:100%;
	opacity:0;
	position:absolute;
	visibility:hidden;
}

.smTextImageBlock.hoverText:hover .contentBlock
{
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(44,62,80,0.9);
	text-align:center;
	transition:all 500ms ease;
	opacity:1;
	visibility:visible;
}

.smTextImageBlock.hoverText:hover .contentBlock h2
{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate3d(-50%,-50%,0);
    -webkit-transform:translate3d(-50%,-50%,0);
    -moz-transform:translate3d(-50%,-50%,0);
}

.smTextImageBlock.hoverText:hover .contentBlock h2 a
{
	color:#fff;
}

.smTextImageBlock.hoverText:hover .contentBlock .blog-snippet-1
{
	margin-top:35px;
}

/* STUDENT REGISTRATION 3-31-15 */
ul.courseSearchResults > li.hasSectionReserved > .listItem
{
    border-color:rgb(220, 220, 220);
    background-color:#fff;
}

ul.courseSearchResults > li.hasSectionReserved.waitListed > .listItem
{
    border-color:#ff3300;
    background-color:rgb(255, 234, 216);
}

/* STANDARD TABLE FORMAT */

ul.courseSearchResults > li.hasSectionReserved > .listItem > .summaryBlock
{
    background-image:url('../img/svg/dark/icon_circleCheckGreen.svg');
    background-repeat:no-repeat;
    background-size:23px;
    padding-left:35px !important;
    background-position:5px 0px;
}

#registrationContainer .itemDetails table
{
    box-sizing:border-box;
    width:100%;
}

#registrationContainer .itemDetails table th:last-child
{
    min-width:200px;
}

#registrationContainer .itemDetails table tbody tr
{
    border-bottom:solid 1px rgba(150,150,150,.1);
}

#registrationContainer .itemDetails table tbody tr td .enrollModeButtons
{
    min-width:0;
    border-radius:0;
    height:2.4rem;
    margin:.3rem;
    padding:0 .5rem;
    line-height:2.4rem;
    display:inline-block;
    cursor:pointer;
    transition-duration:.2s;
    color:#000;
    width:100%;
    text-align:center;
}

#registrationContainer .isPhone .smEnrollmentStatus
{
    display:block;
    float:right;
    text-align:center;
    max-width:60%;
}

#registrationContainer .itemDetails table tbody tr td .smEnrollmentStatus > p,
.isPhone .smEnrollmentStatus > p
{
    line-height:1;
    color:rgb(51,51,51);
    margin-top:.5em;
}

#registrationContainer .itemDetails table tbody tr td .smEnrollmentStatus > a,
.isPhone .smEnrollmentStatus > a
{
    color:red;
    text-decoration:underline;
}

#registrationContainer .itemDetails table tbody tr td .hiddenInputs
{
    display:none !important;
}

#registrationContainer ul.detailedList > li.requiredByDegree .listItem
{
    padding-left:2em;
    background-position:10px 15px;
}

#registrationContainer .courseSearchResults > li.active > .listItem > .summaryBlock > h3
{
    color:#0883aa;
}

#registrationContainer .itemDetails table
{
    background-color:#f7f7f7;
}

#registrationContainer .informationalMessages
{
    padding:.5em;
    margin-top:-1em;
}

#registrationContainer .informationalMessages p
{
    font-style:italic;
    color:#945F00;
    font-size:medium;
    margin-left:-.5em;
}

#registrationContainer .listItem table.portalDefault th
{
    font-weight:normal;
}

#registrationContainer .isPhone .courseSectionWrapper
{
    background-color:#f7f7f7;
    /*margin-left:-1.5em;*/
}

#registrationContainer li.requiredByDegree .listItem .itemDetails
{
    margin-left: -1em;
}

.smRegistrationCourseSearch
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    background-color:#fff;
    padding:.5em;
    text-align:left;
}

/* PHONE ONLY DIV BASED COURSE DETAILS */
ul.courseSearchResults .isPhone .courseSections
{
    margin-top:-.5em;
}

ul.courseSearchResults .courseSections .courseSectionHeader
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    border-bottom:solid 1px #afafaf;
    padding-bottom:.5em;
    margin-bottom:.5em;
}

ul.courseSearchResults .courseSections .courseSectionHeader span.icon
{
    display:none;
}

/* SHOW CHECKMARK FOR SECTION RESERVED */
ul.courseSearchResults li.hasSectionReserved .courseSections .courseSectionHeader span.icon
{
    display:block;
    float:left;
}

ul.courseSearchResults .courseSections .courseSectionHeader h3
{
    float:left;
}

ul.courseSearchResults .courseSections .courseSectionHeader .enrollModeButtons
{
    float:right;
}

ul.courseSearchResults .courseSections .courseSectionDetails .smSeatsAndCredits
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    box-sizing:border-box;
    padding:.5em 0;
    padding-top:.25em;
    margin-bottom:.5em;
    border-bottom:solid 1px #afafaf;
}

ul.courseSearchResults .courseSections .courseSectionDetails .smSeatsAndCredits .field
{
    display:block;
    float:left;
    width:50%;
}

ul.courseSearchResults .courseSections .courseSectionDetails .smSeatsAndCredits .field span
{
    clear:none;
    margin:0 .5em;
}



/* SUPER SEARCH */

.smRegistrationCourseSearch .superSearch
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
}

.smRegistrationCourseSearch .superSearch input[type="text"]
{
    padding:.25em;
    font-size:1em;
    min-width:50%;
}

.smRegistrationCourseSearch .superSearch input[type="text"][disabled]
{
    background-color:#D3D3D3 !important;
}

.smRegistrationCourseSearch .superSearch div.optionsButton
{
    display:inline-block;
}

.smRegistrationCourseSearch .superSearch div.optionsButton button,
.buttonGroup button
{
    padding:.25em;
    line-height:25px;
}

.smRegistrationCourseSearch .superSearch div.optionsButton button
{
    padding:0 .5em;
}

.smRegistrationCourseSearch .superSearch div.optionsButton button.active
{
    background-color:rgb(52, 152, 219);
}

.smRegistrationCourseSearch .superSearch div.optionsButton button.active span.open
{
    background-image:url('../img/svg/icon_disclosureUp.svg');
}

.smRegistrationCourseSearch .superSearch div.optionsButton button[disabled]:hover,
.buttonGroup button[disabled]:hover
{
    background-image: linear-gradient(to bottom,#fff,#e6e6e6);
}

.smRegistrationCourseSearch .superSearch div.optionsButton button:first-child
{
    padding:0 2em;
}

.smRegistrationCourseSearch .superSearch div.optionsButton button:first-child span.icon
{
    margin-right:.5em;
}

.smRegistrationCourseSearch .superSearch div.optionsButton button span.icon,
.buttonGroup button span.icon
{
    display:block;
    float:left;
}

#registrationContainer .pageIndicator,
.smContentArea .pageIndicator
{
    display:inline-block;
    color:#FFF;
}

.smContentArea #registrationContainer .pagerWrap,
.smContentArea .pagerWrap
{
    margin:.5em 0;
    background-color:#7c878e;
    line-height:35px;
}

/* SEARCH AREA */
.smRegistrationCourseSearch .searchArea
{
    display:none;
    float:left;
    clear:both;
    width:100%;
    margin-top:.5em;
}

.smRegistrationCourseSearch .searchArea.active
{
    border-top:solid 1px #afafaf;
}

.smRegistrationCourseSearch .fieldGroup .field
{
    margin-right:.5em;
    display:inline-block;
    float:none;
    vertical-align:top;
}

.smRegistrationCourseSearch .fieldGroup .field input[type="text"]
{
    font-size:1em;
}

.smRegistrationCourseSearch .fieldGroup .field select
{
    float:left;
    clear:both;
}

.smRegistrationCourseSearch .buttonGroup
{
    display:inline-block;
    text-align:center;
    width:100%;
}

.smRegistrationCourseSearch .buttonGroup button
{
    padding:.25em 1em;
}

h2.smCourseWidgetHeader
{
    color: #333333;
    text-align:center;
    width:100%;
    margin:.5em 0 0 0;
    font-size:1.25em;
}

/* SM REGISTRATION CART */
.smCoursesCart
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smCoursesCart .smSlideupHeader
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    background-color:#165B85;
    color:#fff;
    box-sizing:border-box;
    padding:.25em;
}

.smCoursesCart .smSlideupHeader
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

.smCoursesCart .smSlideupHeader > *
{
    float:right;
}

.smCoursesCart .smSlideupHeader > h3
{
    color:#fff;
    font-size:1.5em;
    float:left;
}

.smCoursesCart .smSlideupHeader button
{
    color:#fff;
    border:solid 1px #fff;
    padding: 0 1em;
    min-width:0;
    margin: 0 .25em;
    line-height:2.5;
    vertical-align:top;
    border-width:1px;
    border-radius:2px;
}

.smCoursesCart ul
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    overflow-y:auto;
}

.smCoursesCart ul li
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    background-color:#efefef;
    border-bottom:solid 1px #999;
    padding:.5em 0;
}

.smCoursesCart ul li > span.inspectIcon
{
    float:left;
}

.smCoursesCart ul li > h3
{
    display:block;
    float:left;
    font-size:1em;
    margin:0 .5em;
    max-width:70%;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}

.smCoursesCart ul li > h3 > b
{
    float:left;
    margin-right:.5em;
}

.smCoursesCart ul li > h3 > span.smCourseDescription
{
    max-width:58%;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}

.smCoursesCart ul li > span.smEnrollmentType
{
    display:block;
    float:left;
    margin-top:.25em;
}

.smCoursesCart ul li > span.deleteIcon
{
    float:right;
    margin-right:.5em;
    margin-top:.25em;
    border:solid 1px rgb(153,153,153);
    border-radius:3em;
    cursor:pointer;
}

.smCoursesCart ul li > span.deleteIcon:hover
{
    border-color:rgb(52, 152, 219);
    background-color:rgb(52, 152, 219);
    background-image:url('../img/svg/icon_X.svg');
}

#registrationContainer .widgetContent h3
{
    max-width:90%;
    line-height:1.5em;
}

#registrationContainer ul.detailedList > li .listItem
{
    padding:.5em;
}

.smEnrollmentStatus
{
    font-style:italic;
    color:#999;
}

/* SM STICKY FOOTER SLIDEUP */
.smStickySlideup
{
    display:block;
    width:100%;
    position:fixed;
    bottom:79px;
    height:0px;
    background-color:#fff;
    transition:all .2s ease;
    visibility:hidden;
    /*box-shadow:0px 0px 1.5em rgba(0,0,0,.5);*/
    border-style:solid;
    border-width:1px 5px 3px 5px;
    border-color:#165B85;
    z-index:999;
    max-width:1165px;
    left:50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform:translateX(-50%);
}

.smStickySlideup.inYourFace
{
    height:calc(100% - 70px);
    visibility:visible;
}

.smStickyFooterButtons span:not(.badge)
{
    display:block;
    line-height:1;
    margin: .5em 0;
}
/* END STICKY SLIDEUP */

/* SM STICKY FOOTER */
.smStickyFooter
{
    position:relative;
}

.smStickyFooterButtons
{
    display:none;
    width:100%;
    position:fixed;
    bottom:0;
    box-sizing:border-box;
    z-index:1000;
    max-width:1165px;
    left:50%;
    transform:translateX(-50%);
    -webkit-transform:translateX(-50%);
    -moz-transform:translateX(-50%);
    box-shadow:0px 0px 1.5em rgba(0,0,0,.5);
}

.smStickyFooterButtons button
{
    display:block;
    float:left;
    width:25%;
    text-align:center;
    background:#2C3E50;
    color:#fff;
    border:0;
    text-shadow:none;
    border-right:solid 1px rgba(255,255,255,.3);
    border-radius:0;
    min-width:0;
    border-top:solid 1px #2B699B;
    min-height:64px;
    margin:0;
}

.smStickyFooterButtons button:focus
{
    /*outline:0;*/
    outline-color: deepskyblue;
}

.smStickyFooterButtons button:last-child
{
    border:0;
    border-top:solid 1px #2B699B;
}

.smStickyFooterButtons button.active
{
    background:#165B85;
    box-shadow:none;
    border-top:solid 1px transparent;
}

.smStickyFooterButtons span.badge
{
    display:inline-block;
    margin:0 .5em;
    background-color:#fff;
    color:#333;
    border-radius:3em;
    width:auto;
    line-height:15px;
    font-weight:bold;
    padding:.5em 1em;
}

.smStickyFooter .regPaymentOptions > .fieldGroup > .field.inline
{
    margin-right:3em;
}

.regPaymentOptions > .fieldGroup > .field:first-child
{
    margin-left:.5em;
}

.smStickyFooterButtons button[data-scroll-top] span.iconWrap
{
    background-color:transparent;
}

.smStickyFooterButtons .badge.iconWrap
{
    padding: 0 .5em;
}

.smStickyFooterButtons .badge .icon
{
    margin: 0;
}

.smStickyFooterButtons.visible
{
    display:block;
}

.smStickyFooterButtons button > span:last-child
{
    width:70px;
    display:inline-block;
}

.smContentArea #smStickySlideup .regSummaryBlock.regPaymentOptions
{
    background-color:#efefef;
}

@-webkit-keyframes increment
{
    0% { -webkit-transform: scale3d(1,1,1);background-color:#fff;}
    5%{ -webkit-transform: scale3d(1.5,1.5,1.5);background-color:#0DFF0D;}
    100% { -webkit-transform: scale3d(1,1,1);background-color:#fff; }
}

@keyframes increment
{
    0% { transform: scale3d(1,1,1);background-color:#fff;}
    5%{ transform: scale3d(1.5,1.5,1.5);background-color:#0DFF0D;}
    100% { transform: scale3d(1,1,1);background-color:#fff; }
}

@-webkit-keyframes decrement
{
    0% { -webkit-transform: scale3d(1,1,1);background-color:#fff;}
    5%{ -webkit-transform: scale3d(1.5,1.5,1.5);background-color:#ff0000;}
    100% { -webkit-transform: scale3d(1,1,1);background-color:#fff; }
}

@keyframes decrement
{
    0% { transform: scale3d(1,1,1);background-color:#fff;}
    5%{ transform: scale3d(1.5,1.5,1.5);background-color:#ff0000;}
    100% { transform: scale3d(1,1,1);background-color:#fff; }
}

.increment
{
    animation-name: increment;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function:ease-out;
    animation-delay:0s;

    -webkit-animation-name: increment;
    -webkit-animation-duration: .5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -webkit-animation-timing-function:ease-out;
    -webkit-animation-delay:0s;
}

.decrement
{
    animation-name: decrement;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function:ease-out;
    animation-delay:0s;

    -webkit-animation-name: decrement;
    -webkit-animation-duration: .5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -webkit-animation-timing-function:ease-out;
    -webkit-animation-delay:0s;
}
/* END SM STICKY FOOTER */

.u4sm-theme .legend.requiredByDegree
{
	padding-left: 2em;
    padding-right: .5em;
    height: 2.5rem;
    display: inline-block;
    color:#6d6d6d;
}

.u4sm-theme ul.auditReqPercentages .legend.requiredByDegree 
{
    position: relative;
    transform: initial;
    left: 0;
    padding: 0 5px 0 20px;
    background-position: 2px;
    float: left;
}

.u4sm-theme ul.auditReqPercentages .legend.requiredByDegree label 
{
    font-weight: normal;
}

.u4sm-theme ul.auditReqPercentages .legend.requiredByDegree label:after
{
    content: ' ';
}

.u4sm-theme li.requiredByDegree .listItem,
.u4sm-theme .legend.requiredByDegree,
.u4sm-theme li.hasSectionReserved.requiredByDegree .listItem,
.u4sm-theme tr.courseRequired,
.u4sm-theme li.courseRequired
{
    border-style: solid !important;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
    border-left-color: #ffd118 !important;
    border-right-color: #ffd118 !important;
    background-color: #fffcf0 !important;
    background-image: url(../img/svg/dark/icon_asterisk.svg);
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: 7px 7px;
}

.u4sm-theme li.courseRequired.active
{ 
    background-position: 7px 15px;
}

.u4sm-theme tr.courseRequired 
{
    border-left-width: 4px !important;
    border-right-width: 6px !important;
}

.u4sm-theme tr.courseRequired td:first-child,
.u4sm-theme li.courseRequired 
{
    padding-left: 2.5rem !important;
}

/* Required for background image on tr - without setting the background color on td's, the image would repeat in each td */
.u4sm-theme tr.courseRequired td:not(:first-child) 
{
    background-color: #fffcf0;
}

/* GENERIC NEW CONSTRUCT FOR EASY ROWS */
.fullFloatBox,
.smRegistrationCourseList
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

/************************************************************************************************/
/****      8073 - 10/21/2015 - MVS - PULLED FROM TEMPORARY FOR MERGING AND OPTIMIZATION      ****/
/************************************************************************************************/

.smContentArea div.field.plainField
{
    border:0 !important;
    margin:0 !important;
    padding:0 !important;
}


.smModal.small
{
    max-height:300px;
    max-width:400px;
    top:50%;
    transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
}


/* APPLICANT CHECKLIST TEMP */
.u4sm-theme #documentChecklistForm select
{
    max-width:none !important;
}

.phoneTestScores > li
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    border:solid 1px;
    padding:.5em;
    margin-bottom:.5em !important;
}


/* RB 6/11/2015 - Student Schedule Listing on Portal */
.smContentArea .isPhone ul.scheduleDetails > li
{
	display:block;
	float:left;
	width:100%;
	border-bottom:solid 1px #afafaf;
	padding:.5em;
}

.smContentArea .isPhone ul.scheduleDetails > li > span:nth-child(2)
{
	display:block;
	font-size:1.25em;
	color:#888888;
	font-weight:bold;
	line-height:1.5em;
}

.smContentArea .isPhone ul.scheduleDetails > li > span:nth-child(3),
.smContentArea .isPhone ul.scheduleDetails > li > span:nth-child(4),
.smContentArea .isPhone ul.scheduleDetails > li > span:nth-child(5),
.smContentArea .isPhone ul.scheduleDetails > li > span:nth-child(6)
{
    display:block;
	float:left;
	font-size:.9em;
	color:rgb(61,61,61);
    padding-right: 1em;
}


/* RB 6/11/2015 - Button styling on the Student Schedule search screen.*/
.smContentArea .widgetControls button.scheduleSearch
{
    float:none;
}


/* JITG - 09/14/2015 - Degre Audit Shopping */
.smContentArea .widgetHeader > button > span.icon,
.smContentArea button > span.icon
{
    float:left;
    clear:none;
}

#degreeShoppingScenarios li.buttonTab
{
    padding:0 !important;
}

#degreeShoppingScenarios li.buttonTab > button
{
    margin:.5em;
}

.smContentArea #degreeShoppingScenarios ul.tabsVisible
{
    margin-top:-4px;
}


/* MVS 09-29-15 PORTAL REGISTRATION REVIEW */
#smPreviewCourses table
{
    width: 100% !important;
}

#smPreviewCourses td,
#smPreviewCourses th
{
    padding: 0.8rem;
}

#smPreviewCourses tbody > tr
{
    border-style: solid;
    border-width: 0 0 1px 0;
    border-bottom-color: #dfdfdf;
}

#PreviewCourseListTable > tbody > tr:nth-child(odd)
{
    background-color:#f7f7f7;
}

#smPreviewCourses div.field
{
    display: inline-block;
    vertical-align: top;
    position: relative;
}

#smPreviewCourses div.field label
{
    float: left;
}

#smPreviewCourses div.field input,
#smPreviewCourses div.field select
{
    float: left;
    clear: both;
}

#smPreviewCourses div.field.inline input,
#smPreviewCourses div.field.inline select
{
    clear:none;
}

#smPreviewCourses input[type="text"],
#smPreviewCourses input[type="password"],
#smPreviewCourses input[type="textarea"],
#smPreviewCourses input[type="datetime"],
#smPreviewCourses textarea,
#smPreviewCourses input.date
{
    padding: .2rem;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    line-height: normal;
}

#smPreviewCourses table th:last-child,
#smPreviewCourses table td:last-child
{
    text-align: left;
    vertical-align:top;
}

#reg_singleStudentSummary
{
    text-align: center;
}

#reg_singleStudentSummary > h3
{
    margin: .5em;
    text-align: left;
    margin-left: 1em;
    color: #777;
}

#smPreviewCourses .fieldGroup
{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: .5rem;
}

#smPreviewCourses .optionsButton
{
    margin: 2em 0.5em;
    text-align: center;
}

#smPreviewCourses #previewCourseList
{
    margin: 0;
}

#smPreviewCourses .regSummaryBlock
{
    display: inline-block;
    width: 98%;
    margin: .25em;
    text-align: left;
}

#smPreviewCourses .regSummaryBlock > h3
{
    padding: .5em;
    font-weight: normal;
}

#smPreviewCourses .regSummaryBlock thead tr
{
    background: none;
    border-style: solid;
    border-width: 0 0 1px 0;
}

#smPreviewCourses .regSummaryBlock th
{
    font-size: .9em;
    line-height:1;
}

#smPreviewCourses .regSummaryBlock th.regKeyHeading,
#reg_singleStudentSummary > h3
{
    font-size: 1.6rem;
    line-height: inherit;
    font-weight: bold;
}

#smPreviewCourses .regSummaryBlock > .fieldGroup
{
    background: none;
}

#smPreviewCourses .regStudent
{
    margin-top: .5em;
}

#smPreviewCourses .regSchedule table
{
    vertical-align:top;
}

#smPreviewCourses .regSchedule table > tbody > tr > td:first-child > span
{
    display: block;
    float: left;
    clear: both;
    width: 100%;
    margin-bottom: .5em;
    font-size: 1.1em;
    margin-top: .5em;
    font-style:italic;
}

#smPreviewCourses .regSchedule table > tbody > tr > td:first-child
{
    vertical-align: top;
    width:30%;
}

#smPreviewCourses .regSchedule table > tbody > tr > td:first-child > .field
{
    padding: 0;
    padding-left: 0;
    display:block;
}

#smPreviewCourses .regSchedule table > tbody > tr > td:first-child > .field > label
{
    margin: 0;
    float: none;
}

#smPreviewCourses .regSchedule table > tbody > tr > td:first-child > .field > label:after
{
    content: ":";
}

#smPreviewCourses .regSchedule table > tbody > tr > td:first-child > .field > span
{
    display: inline-block;
    float:none;
}

#smPreviewCourses .regSchedule > table > tbody table > tbody > tr:last-child
{
    border: 0;
}

.reg_schedulesList td
{
    width: auto;
}

.regSchedule > table thead > tr > th:first-child,
.regSchedule > table tbody > tr > td:first-child
{
    width: 35%;
}


/* PHONE RENDERING OF COURSES & SCHEDULE */
.regSchedule .isPhone > li
{
    background-color:#fff;
    margin-bottom:.5em;
    border:solid 1px #999;
    padding:.5em;
}


/* Courses info in first div */
.regSchedule .isPhone > li > div:first-child > h3
{
    font-weight:normal;
    font-size:1em;
    margin:.25em 0;
    line-height:1.5em;
}

.regSchedule .isPhone > li > div:first-child > h3 > b
{
    font-weight:bold;
}

.regSchedule .isPhone > li > div:first-child > div.field
{
    margin:.5em;
}

.regSchedule .isPhone > li > div:first-child > div.field > label
{
    margin-bottom:0;
}


/* Schedule table in second div */
.regSchedule .isPhone > li:nth-child(odd)
{
    background-color:#f7f7f7;
}


#smRegistsrationDatePicker
{
    margin: 15px 0 0;
}

#smRegistsrationDatePicker .field
{
    margin: 0;
}

#smRegistsrationDatePicker .field > label,
#smRegistsrationDatePicker .field > input
{
    float: none;
    display: block;
    margin: 0 auto .5em;
}


/* DROP/WITHDRAW/UNDROP MENU - SCOPED ONLY TO REGISTERED COURSE LIST */
#smRegisteredCourses .drawerCourseList > li
{
    position: relative;
}

#smRegisteredCourses .drawerCourseList > li .courseInformation
{
    margin-right: 50px;
}

#smRegisteredCourses .drawerCourseList > li .itemContext
{
    position: absolute;
    right: 12px;
    top: 12px;
}

#smRegisteredCourses .drawerCourseList > li .icon.inspectIcon
{
    display: none;
}

#smRegisteredCourses .drawerCourseList > li.drop h3,
#smRegisteredCourses .drawerCourseList > li.withdraw h3
{
    max-width: 55%;
}
#smRegisteredCourses .drawerCourseList > li.drop h3,
#smRegisteredCourses .drawerCourseList > li.withdraw h3,
#smRegisteredCourses .drawerCourseList > li.drop b,
#smRegisteredCourses .drawerCourseList > li.withdraw b
{
    font-style: italic;
    text-decoration: line-through;
    color: #888;
}
#smRegisteredCourses .drawerCourseList > li.drop .regStatus,
#smRegisteredCourses .drawerCourseList > li.withdraw .regStatus
{
    color: red;
    line-height: 1.5;
}

#smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon
{
	background-image: url('../img/svg/dark/icon_menu.svg');
    overflow: visible;
    width: 25px;
    z-index: 0;
}

#smRegisteredCourses .icon.clickable
{
    background-color: white;
    border: 1px solid #ddd;
    cursor: pointer;
}

#smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu
{
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    min-width: 150px;
    left: -125px;
    top: 25px;
    background-color: rgba(255,255,255,1);
    border: solid 1px rgba(255,255,255,1);
    box-shadow: 0px 0px 1.5em rgba(0,0,0,.3);
    z-index: 1;
}

#smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu.opened
{
    display: block;
}

#smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu li
{
    background-color: rgba(255,255,255,1);
    border: 0;
    padding: 1em;
    text-align: center;
    cursor: pointer;
}
#smRegisteredCourses .drawerCourseList > li .itemContext > .itemContextIcon > ul.itemContextMenu li:hover
{
    background-color: #efefef;
}

.smCoursesCart ul li > .courseInformation > span.inspectIcon
{
    float:left;
}

.smCoursesCart ul li > .courseInformation > h3
{
    display: block;
    float: left;
    font-size: 1em;
    margin: 0 .5em;
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.smCoursesCart ul li > .courseInformation > h3 > b
{
    float:left;
    margin-right:.5em;
}

.smCoursesCart ul li > .courseInformation > h3 > span.smCourseDescription
{
    max-width:58%;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}

.smCoursesCart ul li > .courseInformation > span.smEnrollmentType
{
    display:block;
    float:left;
    margin-top:.25em;
}

.smCoursesCart ul li > .courseInformation > span.deleteIcon
{
    float:right;
    margin-right:.5em;
    margin-top:.25em;
    border:solid 1px rgb(153,153,153);
    border-radius:3em;
    cursor:pointer;
}

.smCoursesCart ul li > .courseInformation > span.deleteIcon:hover
{
    border-color:rgb(52, 152, 219);
    background-color:rgb(52, 152, 219);
    background-image:url('../img/svg/icon_X.svg');
}
/* END REGISTRATION REVIEW */


/* MVS 09-30-2015 MY FINANCIAL AID */
#smStudentFinancialAid input
{
    padding: .2rem;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    position: relative;
    top: -0.25em;
}

#smStudentFinancialAid ul.detailedList > li .listItem .itemSummary > div.field.inline
{
    padding: 0.5em 0 0.25em;
}

#smStudentFinancialAid ul.detailedList > li .listItem .itemSummary > div.field.inline.error,
#smStudentFinancialAid ul.detailedList > li .listItem .itemSummary > div.field.inline.error > label.error
{
    padding: 1em;
    margin: 0;
}

#smStudentFinancialAid .flexWidget button[disabled="disabled"]
{
    background-color: transparent;
    color: grey;
    border-color: grey;
}


#smStudentFinancialAid .instruction
{
    font-style: italic;
    font-size: .9em;
    text-align: center;
    margin-top: .5em;
    color: grey;
}
/* END FINANCIAL AID */


/* JITG - 10/6 - PORTAL REGISTRATION UX ENHANCEMENTS */
.smContentArea #registrationContainer
{
    min-height:0;
}


.smContentArea #registrationContainer .widgetControls .widgetActions
{
    display:block;
    float:right;
}

.smContentArea #registrationContainer .noRegistrationResults
{
    text-align:center;
    padding:1em;
    font-style:italic;
}

.smContentArea #registrationContainer .widgetControls .widgetActions > .fieldGroup
{
    display:none;
}

.smContentArea #registrationContainer .widgetControls .widgetActions > .fieldGroup.visible
{
    display:block;
}

.smContentArea #registrationContainer .sessionIndicator
{
    margin: 0 .5em;
}

.smContentArea #registrationContainer #SessionSection,
.smContentArea #SessionSection
.smContentArea #DateSection
{
    float:left;
    margin-top:2px;
}

button#registrationReview[disabled="disabled"]:hover
{
    background-color:transparent;
}

.smContentArea #registrationContainer #DateSection > button
{
    margin: 0 1em;
}

.smContentArea #registrationContainer #AvailableDate
{
    max-width:105px;
}


/* isPhone Course List */
.smContentArea #registrationContainer .courseSectionHeader
{
    float:left;
    clear:both;
    width:100%;
    text-align:center;
}

.smContentArea #registrationContainer .courseSectionHeader .smSeatsAndCredits
{
    float:left;
    clear:both;
    width:100%;
    padding:.5em 0;
    border-style:solid;
    border-width: 1px 0;
    border-color:#dfdfdf;
}

.smContentArea #registrationContainer .courseSectionHeader .smSeatsAndCredits .field.inline
{
    display:inline-block;
    margin: 0 .5em;
}

.smContentArea #registrationContainer .courseSectionHeader .smSeatsAndCredits .field.inline label
{
    margin-bottom:0;
}

.smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field.small input[type="text"]
{
    max-width:170px;
}

.smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field.medium input[type="text"]
{
    max-width:115px;
}

.smContentArea #registrationContainer .smRegistrationCourseSearch .searchFields .fieldGroup .field.large input[type="text"]
{
    max-width:170px;
}


/* PHONE - SECTION WRAPPER & HEADER */
#registrationContainer .isPhone .courseSectionWrapper
{
    border-style:solid;
    border-width:1px;
    border-color:#fff;
    margin:.5em 0;
    box-shadow: 0 0 .25em rgba(0,0,0,.5);
}


/* PHONE - SECTION HEADING & ENROLLMENT DETAILS (BUTTONS & MESSAGE) */
#registrationContainer .isPhone .courseSectionWrapper .courseSectionHeader .courseSectionSelector
{
    float:left;
    clear:both;
    width:100%;
}

#registrationContainer .isPhone .courseSectionWrapper .courseSectionHeader .courseSectionSelector .field.inline
{
    float:left;
    width:50%;
    clear:none;
    padding-top:.75em;
    padding-left:.5em;
}

#registrationContainer .isPhone .smEnrollmentStatus,
#registrationContainer .smEnrollmentStatus,
body.u4sm-theme .smEnrollmentStatus
{
    display:none;
    text-align:left;
    font-style:italic;
    color:#999;
    margin-bottom:.5em;
    max-width:100%;
}

#registrationContainer th.enrollButtonColumn
{
    min-width:0 !important;
    width:180px;
}

#registrationContainer .itemDetails table tbody tr td.enrollButtonColumn .enrollModeButtons
{
    text-align:left;
}

#registrationContainer .isPhone .courseSectionWrapper .courseSectionHeader .courseSectionSelector .enrollmentContainer
{
    float:right;
    width:50%;
}

#registrationContainer .isPhone .courseSectionWrapper .courseSectionHeader .courseSectionSelector .enrollmentContainer .enrollModeButtons
{
    float:left;
    clear:both;
    width:100%;
    text-align:right;
    padding:.5em;
}


/* sectionDisabled */
#registrationContainer .isPhone .courseSectionWrapper.sectionDisabled
{
    opacity:.4;
    cursor:not-allowed;
}

#registrationContainer .isPhone .courseSectionWrapper.sectionDisabled .courseSectionSelector > div.enrollmentContainer
{
    display:none;
}

/* JITG - 6/17/16 - Block Registration Prototype */
/* BLOCK REGISTRATION PROTOTYPE */
#registrationContainer
{
    
}

#registrationContainer .msg
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    padding:1em;
    font-style:italic;
    background-color:#f4f4f4;
}

#registrationContainer .msg > span
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    line-height:1.25;
}

#registrationContainer .msg > span > button
{
    background-color:#fff;
    border-color:#999;
}

#registrationContainer .msg > span > button:hover
{
    color:#000;
}

#registrationContainer .msg > span > button > span.icon.forwardArrow
{
    margin:0;
    float:right
}

#registrationContainer .msg.conditional
{
    display:none;
}

#registrationContainer .msg.conditional.active
{
    display:block;
}

#registrationContainer .msg.alert,
#registrationContainer .msg.info,
#registrationContainer .msg.notification
{
    background-repeat:no-repeat;
    background-size:3rem;
    background-position:1rem 50%;
}

#registrationContainer .msg.alert > span,
#registrationContainer .msg.info > span,
#registrationContainer .msg.notification > span
{
    display:block;
    padding-left:2.5em;
}

#registrationContainer .msg.alert
{
    background-image:url(../img/svg/dark/icon_invalidSearchInput.svg);
    background-color:#ffe8ec;
    color:#5d0000;
}

#registrationContainer [data-activate-tab].disabledBlock
{
    font-style:italic;
    font-weight:normal;
    color:#5d0000;
    padding-left:2.5em;
    padding-right:1em;
    background-image: url(../img/svg/dark/icon_invalidSearchInput.svg);
    background-repeat:no-repeat;
    background-position: 0.5em 0.5em;
    background-size:1.5em;
}

#registrationContainer .tabBarButtons.fRight
{
    position:relative;
    top:.5em;
}

#blockRegistration
{
    overflow:visible !important;
}

.regTransactions .isPhone li
{
    border:0;
    background-color:#f6f6f6;
}

.regTransactions .isPhone li p.noResults
{
    text-align:center;
    font-style:italic;
}

#smPreviewCourses .regTransactions .isPhone th:last-child,
#smPreviewCourses .regTransactions .isPhone td:last-child
{
    text-align:right;
    vertical-align:middle;
}

#smPreviewCourses .regTransactions .isPhone td:first-child span
{
    display:block;
    float:left;
    clear:both;
    width:100%;
}

/************************************************************************************************/
/****    END 8073 - 10/21/2015 - MVS - PULLED FROM TEMPORARY FOR MERGING AND OPTIMIZATION    ****/
/************************************************************************************************/


/* MAIN SM RECORD ATTENDANCE STYLE */
/* ACTION BAR */
#smRecordAttendance .legendBar,
#smRecordAttendance .actionBar,
#smRecordAttendance .moreOptionsBar,
#smRecordAttendance .contextUtility > ul
{
    float:left;
    clear:both;
    width:100%;
}

#smRecordAttendance .actionBar
{
    display:block;
    background-color:#F0F0F0;
    padding:.25em;
    font-size:.8em;
    margin-bottom:.25em;
}

#smRecordAttendance .actionBar label
{
    display:inline-block;
    vertical-align:top;
    margin:0;
    font-weight:normal;
}

#smRecordAttendance .actionBar div.field.inline
{
    display:inline-block;
    vertical-align:middle;
    border-right:solid 1px #dfdfdf;
    padding-right:.25em;
}

#smRecordAttendance .actionBar div.field.inline > label
{
    padding: 0 .25em;
    border-radius:2px;
    cursor:pointer;
}

#smRecordAttendance .actionBar div.field.inline > label:after,
#smRecordAttendance .legendBar > div.field.inline > label:after
{
    content:"";
}

#smRecordAttendance .actionBar div.field.inline input[type="checkbox"]
{
    margin:.25em;
}

#smRecordAttendance .actionBar .moreOptionsWrap > div.field.inline > button
{
    padding:0 .5em;
    line-height:2.2;
    margin:0 .25em;
    float:left;
}

#smRecordAttendance .actionBar .moreOptionsWrap > div.field.inline > input[type="text"]
{
    max-width:50px;
}

#smRecordAttendance .actionBar .fRight button
{
    display:block;
}

#smRecordAttendance .actionBar .fRight .moreOptionsWrap
{
    display:none;
}
/* END ACTION BAR */


/* MORE OPTIONS BAR */
#smRecordAttendance .moreOptionsBar
{
    display:none;
}

#smRecordAttendance .moreOptionsWrap > div.field.inline
{
    display:inline-block;
    vertical-align:top;
    margin:0 .5em;
}
#smRecordAttendance .moreOptionsBar .moreOptionsWrap.active
{
    display:block;
}
/* ONLY FOR PHONE */
#smRecordAttendance .moreOptionsBar .moreOptionsWrap > div.field.inline > label
{
    font-weight:normal;
    font-size:.8em;
}

#smRecordAttendance .moreOptionsBar .moreOptionsWrap > div.field.inline > input[type="text"]
{
    width:50px;
    font-size:.8em;
}

#smRecordAttendance .moreOptionsBar .moreOptionsWrap > div.field.inline > select
{
    font-size:.8em;
}

#smRecordAttendance .moreOptionsBar .moreOptionsWrap > div.field.inline > button
{
    font-size:.7em;
    padding:.5em;
    line-height:1;
    margin-top:1px;
}
/* END PHONE */
#smRecordAttendance .optionsBarToggle,
#smRecordAttendance .optionsBarToggle:hover
{
    margin:0;
    padding:.25em .5em;
    font-size:.7em;
    background-image:none !important;
}

#smRecordAttendance .optionsBarToggle.active
{
    background:none;
    background-color:#3498db;
    color:#fff;
    border-color:#3498db;
}

#smRecordAttendance .optionsBarToggle > span.icon
{
    height:20px;
    width:20px;
}
/* END MORE OPTIONS BAR */


/* LEGEND BAR */
#smRecordAttendance .legendBar
{
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#dfdfdf;
    opacity:.7;
    text-align:center;
    padding:.25em 0;
}

#smRecordAttendance .legendBar > label
{
    font-weight:normal;
    font-size:.8em;
    margin:0;
    vertical-align:middle;
}

#smRecordAttendance .legendBar > div.field.inline
{
    display:inline-block;
    border-right:dotted 1px #999;
    vertical-align:top;
}

#smRecordAttendance .legendBar > div.field.inline:last-of-type
{
    border:0;
}

#smRecordAttendance .legendBar > div.field.inline > label
{
    font-weight:normal;
    font-size:.8em;
}

{
    content:"";
}

#smRecordAttendance .legendBar > div.field.inline > span.icon
{
    float:left;
}
/* END LEGEND BAR */


/* CONTEXT UTILITY */
#smRecordAttendance .contextUtility
{
    display:inline-block;
    vertical-align:middle;
}

#smRecordAttendance .contextUtility > ul
{
    display:block;
}

#smRecordAttendance .contextUtility > ul li
{
    display:block;
    float:left;
}

#smRecordAttendance .contextUtility button
{
    min-width:0;
    color:#000;
    display:inline-block;
    vertical-align:top;
    margin:0 .25em;
    padding:0;
    border-color:#fff;
    height:33px;
    width:33px;
    margin-top:1px;
}

#smRecordAttendance .contextUtility > ul li button > span.icon:not(.searchResultsIcon)
{
    background-size:100%;
    height:31px;
    width:31px;
}

#smRecordAttendance .contextUtility > ul > li button > span.icon
{
    margin:0;
}

#smRecordAttendance .contextUtility > ul > li button > span.searchResultsIcon
{
    margin-left:3px;
}

#smRecordAttendance .contextUtility button[disabled="disabled"]:hover
{
    background:none;
}

#smRecordAttendance .contextUtility .selectedDate
{
    display:inline-block;
    vertical-align:top;
    line-height:30px;
    margin:0 0px 0 3px;
    padding: 0 .5em;
    border-style:solid;
    border-width:1px 0 1px 1px;
    border-color:#fff;
    background-color:#E6E6E6;
    border-top-left-radius:2px;
    border-bottom-left-radius:2px;
    text-align:center;
    height:33px;
    margin-top:1px;
    color:#666;
}

#smRecordAttendance .contextUtility li > button[data-attendance-date-lookup]
{
    border-style:solid;
    border-width:1px;
    border-top-left-radius:0;
    border-bottom-left-radius:0;
    border-top-right-radius:2px;
    border-bottom-right-radius:2px;
    margin-left:-3px;
}

#smRecordAttendance .contextUtility li > button[data-attendance-date-lookup].active,
#smRecordAttendance .contextUtility .searchResultsWidget .headerBar
{
    background-color:#4E5A62;
}

#smRecordAttendance .contextUtility .searchResultsWidget
{
    display:none;
    background-color:#fff;
    position:absolute;
    width:100%;
    top:41px;
    z-index:1;
    max-width:280px;
    left:50%;
    transform:translateX(-50%);
    -webkit-transform:translateX(-50%);
}

#smRecordAttendance .contextUtility .searchResultsWidget .headerBar > h3
{
    color:#fff;
    float:none;
    font-weight:normal;
    margin:0 .5em;
    padding:.5em 0;
    font-size:1.1em;
}

#smRecordAttendance .contextUtility .searchResultsWidget .u4StickyHeaderList
{
    overflow:auto;
}

#smRecordAttendance .contextUtility .searchResultsWidget ul.miniSearchResults li.separator
{
    background-color:#efefef;
    padding:.5em 0;
    color:#999;
    font-style:italic;
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#dfdfdf;
}

#smRecordAttendance .contextUtility .searchResultsWidget ul.miniSearchResults li.searchResultsItem
{
    padding:.25em 0;
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#dfdfdf;
    color:#777;
    cursor:pointer;
}

#smRecordAttendance .contextUtility .searchResultsWidget ul.miniSearchResults li.searchResultsItem.activeSearchResult
{
    background-color:#3498db;
    color:#fff;
}

#smRecordAttendance .contextUtility .searchResultsWidget.active
{
    display:block;
    box-shadow:0 0 .5em rgba(0,0,0,.5);
    border-style:solid;
    border-width:0 1px 1px 1px;
}
/* END CONTEXT UTILITY */


/* STUDENT RECORD TABLES */
#smRecordAttendance table
{
    width:100%;
}

#smRecordAttendance table tr
{
    border-style:solid;
    border-color:#cfcfcf;
    border-width:0 0 1px 0;
    padding:.5em;
}

#smRecordAttendance table tr.rowRecorded .listImage,
#smRecordAttendance table tr.rowRecorded td.attendDetails > h3
{
    opacity:.5;
}

#smRecordAttendance table tr.rowRecorded td.recordedStatus
{
    background-image:url('../img/svg/dark/icon_checkBoxChecked.svg');
    opacity:.7;
}

#smRecordAttendance table td
{
    padding:0 !important;
    vertical-align:middle;
}

#smRecordAttendance table td.recordedStatus
{
    background-color:#efefef;
    width:25px;
    background-repeat:no-repeat;
    background-size:30px;
    background-position:50%;
    opacity:.3;
}

#smRecordAttendance table td.recordedStatus.recorded
{
    opacity:1;
}

#smRecordAttendance table td.attendPhoto
{
    border-color: #efefef;
    width:50px;
    border-style:solid;
    border-width:0 1px;
}

#smRecordAttendance table td.attendPhoto > div.listImage
{
    float:left;
    height:50px;
    width:50px;
    overflow:hidden;
}

#smRecordAttendance table td.attendPhoto > div.listImage > span.noPhotoIcon
{
    height:50px;
    width:50px;
    background-size:100%;
    opacity:.3;
}

#smRecordAttendance table td.attendDetails
{
    padding:0 .5em !important;
    width:30%;
}

#smRecordAttendance table td.attendDetails > h3
{
    color:#7F7F7F;
    line-height:1;
    margin:0;
    padding:.5em 0 0 0;
    font-weight:bold;
}

#smRecordAttendance table td.attendDetails .field > label
{
    margin-right:.25em;
    font-weight:400;
    font-size:.9em;
}

#smRecordAttendance table td.attendDetails .myStatus
{
    font-style:italic;
    clear:none;
    font-size:.9em;
}

#smRecordAttendance table td:last-child
{
    text-align:right;
    padding-right:.5em !important;
}
/* END STUDENT RECORD TABLES */


/* ATTENDANCE CONTROLS */
#smRecordAttendance .attendanceControls
{
    float:right;
    padding:.25em 0;
}

#smRecordAttendance .attendanceControls .hoursInput
{
    max-width:50px;
    overflow:hidden;
    margin:.25em;
}

#smRecordAttendance .attendanceControls .hoursInput > input[type="text"],
#smRecordAttendance .attendanceControls .commentsInput > input[type="text"]
{
    max-width:100%;
    font-size:.8em;
}

#smRecordAttendance .attendanceControls .commentsInput
{
    max-width:134px;
    overflow:hidden;
    padding:0 0 0 .25em;
}

#smRecordAttendance .attendanceControls > button
{
    background-color:#e1e1e1;
    border:0;
    border-bottom:solid 1px;
    color:#747474;
    display:inline-block;
    opacity:.5;
    border-radius:2px;
    padding:0;
    height:2.5em;
    line-height:2.5;
    margin-bottom:.25em;
}

#smRecordAttendance .attendanceControls > button.active,
#smRecordAttendance .attendanceControls > button:hover
{
    opacity: .8 !important;
    color:#747474;
}

#smRecordAttendance .attendanceControls > button.active
{
    opacity:1;
}

#smRecordAttendance .attendanceControls > button > span.btnText
{
    display:none;
}
/* END ATTENDANCE CONTROLS */

.u4sm-theme .sessionBrowser
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    text-align:center;
    margin-top:.5em;
}

.u4sm-theme .sessionBrowser > div.field.inline
{
    display:inline-block;
}

.u4sm-theme .sessionBrowser > div.field.inline > label
{
    color: #fff;
}

/* END MAIN SM RECORD ATTENDANCE STYLE */


/*
    NAMES: KEVIN SEECK
    DATES: 12/10/2015
    PAGE: FACULTY 360 PORTAL LANDING PAGE
    STORY: 7259
*/

#newStyle > li > .listItem
{
    font-size:smaller;
}

/* STANDARD VIEW */

#newStyle > li table td
{
    line-height:1.5;
}

#newStyle > li > .listItem > .itemSummary.responsive
{
    margin:0;
    max-width:calc(100% - 30px);
}

#newStyle > li > .listItem > .itemSummary.responsive > .headings > h2 /* Convert to H2 */
{
    font-size:1.5em;
    line-height:1.25;
    margin-top:-.25em;
    margin-bottom:.5em;
    font-weight:400;
    color:#666;
}

#newStyle > li > .listItem > .itemSummary.responsive > .headings > h3 /* Convert to H3 */
{
    font-size:1.1em;
    margin:.25em 0;
    line-height:1;
}

#newStyle > li > .listItem > .itemSummary.responsive > .fields
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    margin:.5em 0;
    margin-bottom:-.5em;
}

#newStyle > li > .listItem > .itemSummary.responsive > .fields > div.field.inline /* Convert to H3 */
{
    display:inline-block;
    vertical-align:top;
}

#newStyle > li > .listItem > .itemSummary.responsive > .fields > div.field.inline:after /* Convert to H3 */
{
    content: "|";
}

#newStyle > li > .listItem > .itemSummary.responsive > .fields > div.field.inline:last-child:after /* Convert to H3 */
{
    content: "";
}

/* REVISING TAB AESTHETICS TO MATCH ADMIN SIDE */
/* ORIGINALLY .tabBar and .tabWrapper were targeted by ul:first-child, ul:last-child, but this is better */
#newStyle .tabSet > ul.tabBar
{
    border-bottom:solid 1px rgb(51,173,255);
}

#newStyle .tabSet > ul.tabWrapper
{
    border:0;
}

#newStyle .tabSet > ul.tabBar > li[data-activate-tab]
{
    border-style:solid;
    border-width:0 0 5px 0;
    border-color:transparent;
    background:transparent;
    min-height:0;
    font-size:1em;
    padding:0 1em 0 0 !important;
    height:40px;
    line-height:40px;
}

#newStyle .tabSet > ul.tabBar > li[data-activate-tab].activeTab
{
    border-color:rgb(51,173,255);
}

#newStyle .tabSet > ul.tabBar > li[data-activate-tab] > span.icon
{
    height:40px;
    width:40px;
    margin:0;
    background-size:50%;
    opacity:.5;
}

#newStyle .tabSet > ul.tabBar > li[data-activate-tab].activeTab > span.icon
{
    opacity:1;
}

#newStyle .tabSet > ul.tabBar > li.tabButton
{
    top:.5em;
}

#newStyle td button
{
    line-height:1;
}

#newStyle > li > .listItem .itemDetails ul.scheduleDetails > li > button,
#newStyle > li > .listItem .itemDetails td > button
{
    padding:0;
    background-color:#fff;
}

#newStyle > li > .listItem .itemDetails a.lightboxLink
{
    color:#428bca;
    text-decoration:underline;
    font-weight:bold;
    display:block;
    padding:.25em;
}

/* DETAILS SHOWING */
#newStyle > li.detailsShowing > .listItem
{
    background-color:#f6f6f6;
}

#newStyle > li.detailsShowing > .listItem > .itemSummary.responsive > .headings > h2 /* Convert to H2 */
{
    color:rgb(51,173,255) !important;
}

#newStyle > li.detailsShowing > .listItem > .itemSummary.responsive > .fields
{
    margin-bottom:0;
}

#newStyle > li.detailsShowing > .listItem > .itemDetails.active
{
    border-top:solid 1px #999;
}
/************************************************************************************************/
/****    END 7259 - 12/10/2015 - KEVIN SEECK - FACULTY 360 PORTAL LANDING PAGE               ****/
/************************************************************************************************/


/************************************************************************************************/
/****    JITG - STUDENT ADVISOR TRANSCRIPT - 12/23/15                                        ****/
/************************************************************************************************/
.degreeContainer,
.degreeContainer .degreeHeader,
.degreeContainer .termContainer,
.degreeContainer .termHeader
{
    float:left;
    clear:both;
    width:100%;
}

.degreeContainer
{
    border:solid 1px #999;
    padding:.5em;
}

.degreeContainer .degreeHeader,
.degreeContainer .termHeader
{
    text-align:center;
    margin-bottom:.25em;
}

.degreeContainer .degreeHeader
{
    border-bottom:solid 1px #dfdfdf;
    padding-bottom:.25em;
}

.widgetContent .fieldGroup.full .field.half
{
    float:left;
    width:47%;
    clear:none;
}

.widgetContent .fieldGroup.full .field.half:last-child
{
    float:right;
}

.studentTranscript .fieldGroup.full .field label
{
    font-weight:700 !important;
}

.degreeContainer .degreeHeader div.field
{
    display:inline-block;
    width:32%;
    clear:none;
    line-height:1;
    vertical-align:top;
    border-right:dashed 1px #999;
    min-height:50px;
}

.degreeContainer .degreeHeader div.field span
{
    text-align:left;
}

.degreeContainer .termContainer
{
    border:solid 1px #afafaf;
    margin:.25em 0;
}

.degreeContainer .termHeader
{
    position:relative;
    background-color:#9e9e9e;
    color:#fff;
    padding:.5em 0;
}

.degreeContainer .termHeader h3
{
    display:inline;
    color:#fff;
    font-weight:bold;
    font-size:1.1em;
    line-height:1;
    padding:.5em 0;
}

.degreeContainer .termHeader span
{
    position:absolute;
    right:1em;
    top:50%;
    line-height:2;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform:translateY(-50%);
}

.degreeContainer .termSummary
{
    background-color:#fff;
    border-top:solid 1px #999;
}

.degreeContainer .termSummary > table
{
    margin: .5em auto;
    color:#777;
    border:solid 1px #999;
}

.degreeContainer .termSummary > table th,
.degreeContainer .termSummary > table td
{
    padding:.5em 1em;
}

.degreeContainer .termSummary tbody td:first-child
{
    font-weight:bold;
    color:#777;
}

/* DEFAULT */
table.courseDetails th:nth-child(3),
table.courseDetails td:nth-child(3),
table.courseDetails th:nth-child(4),
table.courseDetails td:nth-child(4),
table.courseDetails th:nth-child(5),
table.courseDetails td:nth-child(5),
table.courseDetails th:nth-child(6),
table.courseDetails td:nth-child(6),
/* PHONE */
.isPhone table.courseDetails td:nth-child(2),
.isPhone table.courseDetails th:nth-child(3),
.isPhone table.courseDetails td:nth-child(3),
.isPhone table.courseDetails th:nth-child(4),
.isPhone table.courseDetails td:nth-child(4),
.isPhone table.courseDetails th:nth-child(5),
.isPhone table.courseDetails td:nth-child(5)
{
    text-align:center;
}

.degreeContainer .degreeHeader div.field:last-child
{
    border:0;
}

.printWatermark,
.printOnly
{
    display:none;
}

#printTranscript
{
    display:block;
    position:absolute;
    right:10px;
}
/************************************************************************************************/
/****    END JITG - STUDENT ADVISOR TRANSCRIPT - 12/23/15                                    ****/
/************************************************************************************************/

/* JITG - FIX FOR OVERLAPPING DROPDOWN ARROWS */
.u4sm-theme .smContentArea select,
.u4sm-theme .smStickyFooter select
{
   background-position: calc(100% - 5px) 50%; 
}

.u4sm-theme .requiredLabel
{
    float: right;
    margin-left: 0.25em;
}

.u4sm-theme .requiredLabel:before
{
    content: '*';
}

/* Fixing visibility of offscreen menus when on a page where sticky nav is not on - display none replaced with visibility hide and just keeping it absolute */
body.u4HideNav .top-bar.offscreen-menu.nav-sticky
{
    position: absolute !important;
    display: block;
    visibility: hidden;
}

body.u4HideFooter footer.short
{
    display:none;
}

.top-bar.offscreen-menu.nav-sticky .offscreen-container.reveal-nav,
.top-bar.offscreen-menu.nav-sticky .offscreen-container-authenticated.reveal-nav 
{
    visibility: visible;
}

/* RESPONSIVE TABS - JITG - 6/20/16 */
/* RESPONSIVE COURSE GROUP TABS */
.tabSet .tabWrapper
{
    width:calc(100% - 150px) !important;
    float:left;
    position:relative;
}

.tabSet .tabMenuWrap
{
    display:none;
    position:relative;
}

.tabSet.responsiveTabs .tabMenuWrap
{
    display:inline-block;
    position:relative;
}

.tabSet.responsiveTabs > .tabWrapper > [data-activate-tab]:not(.activeTab)
{
    display:none;
}

.tabSet.responsiveTabs > ul:first-child > li[data-activate-tab].activeTab
{
    background-color:#efefef;
    border-style:solid;
    border-width:1px 1px 0 1px;
    border-color:#cfcfcf;
    color:#000;
    position:relative;
    top:10px;
}

.tabSet > .tabBarButtons,
.tabSet.responsiveTabs > .tabBarButtons
{
    padding-right:.5em;
}

.tabSet.responsiveTabs > .tabBarButtons > .tabMenuWrap > .tabMenuBtn
{
    min-width:0;
    padding:0 .5em;
    margin-bottom:1em;
}

.tabSet.responsiveTabs > .tabBarButtons > .tabMenuWrap > .tabMenuBtn > span.icon
{
    background-image: url('../img/svg/dark/icon_menu.svg');
}

.tabSet.responsiveTabs > .tabBarButtons > .tabMenuWrap > .tabMenuBtn > span.icon
{
    background-position:.25em .5em;
    background-size:15px;
}

.tabSet.responsiveTabs > .tabBarButtons > .tabMenuWrap > .tabDropMenu
{
    position:absolute;
    z-index:999;
    width:auto;
    border-style:solid;
    border-width:1px;
    border-color: #999;
    background-color: #fff;
    box-shadow: 0 6px .5em rgba(0,0,0,.1);
    right:3px;
    min-width:200px;
}

.tabSet.responsiveTabs > .tabBarButtons > .tabMenuWrap > .tabMenuBtn.active
{
    background-color:#fff; /* THEME SPECIFIC */
}

.tabSet.responsiveTabs > .tabBarButtons > .tabMenuWrap > .tabDropMenu > li
{
    padding:.5em;
    border-style:solid;
    border-width:0 0 1px 0;
    border-color:#999;
    width:100%;
}

.tabSet.responsiveTabs > .tabBarButtons > .tabMenuWrap > .tabDropMenu > li.activeTab
{
    display:none;
}

.tabSet.responsiveTabs > ul:not(.tabWrapper)
{
    margin-top:.5em;
}

/* STYLES WHEN A BLOCK IS DISABLED */
.tab.disabledBlock .courseSearchResults
{
    
}

/* FREEZE BODY SCROLLING WHEN OPENING OFFSCREEN MENU */
html.freezeHtml
{
    overflow:hidden;
}

/* CONTINUING EDUCATION - PROTOTYPE */
.widgetSample
{
    display:block;
    float:left;
    clear:both;
    width:100%;
    min-height:200px;
    background-color:#efefef;
    padding:1em;
    margin-bottom:1em;
}

.smHeaderMessageBox.dark
{
    background-color:rgba(0,0,0,.8);
}

.smContentArea.noPad
{
    padding:0;
}
/* END CONTINUING EDUCATION  */

/* PORTAL FIXES PRIOR TO PLUGIN v1 DEPLOYMENT */
.drawerCourseList span.courseMode
{
    float:left;
    margin-top:.25em;
}

div#enrollmentSection ul.detailedList li .listItem span.detailsIcon 
{
    top: 3px;
}

[data-platform-flag="pc"] select
{
    min-height:2em;
}

/* PORTAL REGISTRATION - CO-Requisite Selection Use-Cases JITG - 1/13/17 */
/* THESE RULES BRING THE STANDARD COURSE LIST STYLING INTO A SLIDEUP */
/* TODO: STANDARDIZE */
body.u4sm-theme .smCoursesCart ul.courseSearchResults
{
    padding:.5em;
}

body.u4sm-theme .smCoursesCart ul.courseSearchResults li
{
    background:none;
    border:0;
    margin:0 0 .25em 0;
    padding:0;
}

body.u4sm-theme .smCoursesCart ul.courseSearchResults li .listItem
{
    background-color:#fff;
    padding:.5em;
    position:relative;
    border:solid 1px rgb(207,207,207);
    line-height:1em;
}

body.u4sm-theme .smCoursesCart ul.courseSearchResults li .summaryBlock h3
{
    max-width:90%;
    line-height:1.5em;
    font-size:1em;
    font-weight:normal;
}

body.u4sm-theme .smCoursesCart ul.courseSearchResults li .summaryBlock > span.detailsIcon
{
    position:absolute;
    top:5px;
    right:5px;
    left:auto;
    margin:0;
}

body.u4sm-theme .smCoursesCart ul.courseSearchResults li .itemDetails
{
    padding:.5em;
}

body.u4sm-theme .smCoursesCart ul.courseSearchResults li .smEnrollmentStatus
{
    display:none;
    text-align:left;
    font-style:italic;
    color:#999;
    margin-bottom:.5em;
    max-width:100%;
}

body.u4sm-theme .smCoursesCart ul.courseSearchResults li .enrollModeButtons button
{
    padding:0 1em;
    min-width:0;
    margin:0 .25em;
    vertical-align:top;
    line-height:2.5;
}

body.u4sm-theme #smCoRequisiteConflicts .smCoursesCart
{
    height: 100%;
}

.smModalContent #OverridePopup .paneContent
{
    padding:.5em;
}

/* quick utility hack */
.hidden
{
    display:none !important;
}