/* ALL THEME-SPECIFIC OVERRIDES */
/* PIVOT SPECIFIC */
/* Hide map icon at top right */
.smContentArea section
{
    padding:0;
}

.smHeaderMessageBox
{
    background-color:rgba(0,0,0,.4);
    padding:1em 2em 2em 2em;
    color:#fff;
    margin:0 auto;
}

.smHeaderMessageBox > h1
{
    color:#fff;
}

.smHeaderMessageBox > p
{
    font-size:1.3em;
    text-align:center;
    line-height:1.5em;
}

.smHeaderMessageBox > p a,
#NewAccountToggleButton
{
    color:#E74C3D;
}

.smContentArea .fieldGroup > .field.trsPasswordMessage > h3
{
    color:#920000;
    line-height:1.4;
    font-size:1em;
    font-style:italic;
    background-color:rgb(255,241,244);
    padding:.5em 10em;
    max-width:100%;
}

.smLandingPageHeader header.signup
{
    padding-top:100px !important;
    padding-bottom:0 !important;
}

/* IN OVERLAY */
.smQuickLinks .smProfileLogin
{
	margin-top:-14px;
}

.smQuickLinks .smProfileLogin .login_wid > li > input[type="text"],
.smQuickLinks .smProfileLogin .login_wid > li > input[type="password"],
.smQuickLinks .smProfileLogin .login_wid > li > input[type="submit"]
{
	border-style:solid;
	border-width:1px;
	color:#333;
	border-color:#999;
}

.smQuickLinks .smProfileLogin .login_wid > li > input[type="submit"]
{
	padding:8px;
}

/* IN FIXED POSITION */

 .top-bar.overlay-bar.offscreen-menu .smProfileHeader .smProfileDetails
{
	color:#fff;
}


.smQuickLinks span > i
{
	display:none;
}

.smQuickLinks span > a
{
	display:inline-block;
	margin:0 .5em;
	color:#efefef;
}

.smQuickLinks span > a:after
{
	content:"|";
	margin-left:.5em;
}

.smQuickLinks span > a:last-child:after
{
	content:"";
}

.trsContentWrapper,
.trsContentWrapper .col-md-12
{
	padding:0;
}

.smContentArea .widgetHeader
{
	padding:0;
}

section.dark-wrapper.trs-light-row .stat-bubble,
section.dark-wrapper.trs-light-row h3
{
	color:#999;
	border-color:#999;	
}

.smQuickLinks
{
	display:none;
	position:relative;
	top:-19px;
}

.nav-sticky .smQuickLinks
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	height:15px;
	border-bottom:solid 1px #afafaf;
	margin-left:15px;
	padding-bottom:20px;
}

.top-bar.nav-sticky
{
	border-bottom:solid 1px #fff;
	box-shadow:0px 0px 1.5em rgba(0,0,0,.2);
}

#menu-main-menu ul.subnav
{
	border-style:solid;
	border-width:1px;
	box-shadow:0px 0px 1.5em rgba(0,0,0,.3);
}

.nav-sticky #menu-main-menu ul.subnav
{
	border-style:solid;
	border-width:0 1px 1px;
	box-shadow:0px 11px 1.5em rgba(0,0,0,.2);
	margin-top:1px;
	margin-left:-20px;
}

/* .hidePivotNav .top-bar.overlay-bar.offscreen-menu
{
    display:none;
} */

/* HEADING MENU ITEMS IN OFFSCREEN MENU */
.offscreen-container
{
	-webkit-overflow-scrolling:touch;
	padding-bottom:1em;
}

.offscreen-container .menu-item-has-children > a
{
    margin-left:.5em;
    margin-top:.5em;
}

.offscreen-container ul.menu > li > a:hover
{
	color:#fff !important;
	text-decoration:none;
}

.offscreen-container ul.menu > li > ul > li,
.offscreen-container-authenticated ul.menu > li
{
	padding-left:2em;
}

.offscreen-toggle
{
	display:none;
}

.smQuickLinks .offscreen-toggle.sm-authenticated
{
	display:block;
}

.smQuickLinks .offscreen-toggle.sm-authenticated
{
	position:relative;
	right:10px;
}

.offscreen-menu .logo
{
	padding-bottom:0;
}

.overlay-bar.nav-sticky .menu li a:hover
{
	color:#000;
}

.offscreen-container
{
	z-index:9999;
}

.offscreen-container.sm-authenticated
{
	z-index:9998;
}

.offscreen-container.sm-authenticated.showing
{
	z-index:10000;
}

.smProfileDetails > span
{
	display:block;
	line-height:1;
}

/* TOP BAR OFFSCREEN MENU WITHOUT OVERLAY - OVERLAY PAGE OVERRIDES BELOW */
.top-bar.offscreen-menu
{
    background-color: rgba(0,0,0,.5);
    position: absolute !important;
}
.top-bar.offscreen-menu.nav-sticky
{
    position: fixed !important;
    background-color: rgba(0,0,0,.75);
}
.top-bar .utility-menu
{
    display: none !important;
}

.top-bar.offscreen-menu .smProfileLogin .login_wid > li > input[type="text"],
.top-bar.offscreen-menu .smProfileLogin .login_wid > li > input[type="password"]
{
	border:solid 1px #999;
}

.top-bar.offscreen-menu .smProfileLogin .login_wid > li > input[type="submit"]
{
	color:#333;
	border-color:#999;
}

.top-bar.offscreen-menu .smProfileLogin .login_wid > li > input[type="submit"]:hover
{
	color:#fff;
	border-color:#999;
    background-color:#999;
}

/* TOP BAR OVERRIDES FOR OVERLAY */ 
.top-bar.offscreen-menu.overlay-bar:not(.nav-sticky) .smQuickLinks span > a
{
	color:#fff;
}

.top-bar.offscreen-menu.overlay-bar .smProfileLogin .login_wid > li > input[type="text"],
.top-bar.offscreen-menu.overlay-bar .smProfileLogin .login_wid > li > input[type="password"]
{
	/* border:solid 1px #fff; */
    -webkit-appearance:none;
}

.top-bar.offscreen-menu.overlay-bar:not(.nav-sticky) .smProfileLogin .login_wid > li > input[type="submit"]
{
	color:#fff;
	border-color:#fff;
}

.top-bar.offscreen-menu.overlay-bar:not(.nav-sticky) .smProfileLogin .login_wid > li > input[type="submit"]:hover
{
	color:#333;
	border-color:#fff;
    background-color:#fff;
}

/* IN FLOATING POSITION */
.nav-menu .offscreen-toggle.sm-authenticated .smProfileImage
{
	display:block;
	float:left;
	clear:none;
	height:35px;
	width:35px;
	margin:0 .5em;
	background-color:rgba(0, 0, 0, 0.52);
}

.nav-menu .offscreen-toggle.sm-authenticated .smProfileImage > span.personIconWhite,
.nav-menu .offscreen-toggle.sm-authenticated .smProfileImage > span.personIcon
{
	display:block;
    margin:0;
	height:35px;
	width:35px;
	background-size:100%;
	background-position:0 0;
	top:0;
}

.nav-menu .offscreen-toggle.sm-authenticated > .icon
{
	float:right;
	height:35px;
	width:35px;
	background-size:50%;
	background-position:50%;
	top:0;
}
div.offscreen-toggle .menuIcon
{
    background-image: url('../img/svg/icon_menu.svg') !important;
    position: relative;
    right: -5px;
}


.nav-menu .offscreen-toggle.sm-authenticated .smProfileDetails
{
	display:block;
	float:left;
	clear:none;
}

.nav-menu .offscreen-toggle.sm-authenticated .smProfileDetails > p:first-child
{
	margin:0;
	line-height:1;
}

/* TRS OFFSCREEN MENU - CURRENTLY RELIES ON PIVOT - NEED TO REFACTOR TO BE THEME AGNOSTIC EVENTUALLY */
.offscreen-container-authenticated 
{
    position: fixed;
    width: 300px;
    background: #333333;
    right: 0px;
    height: 100%;
    top: 0px;
    z-index: 99999;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translate3d(300px, 0, 0);
    -moz-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
    padding:25px 10px;
}

.offscreen-container-authenticated.reveal-nav 
{
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}

.offscreen-container .smMenuContainer,
.offscreen-container-authenticated .smMenuContainer
{
    padding:0 1em;
}

.offscreen-menu-authenticated .container 
{
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}

.offscreen-menu-authenticated .container.reveal-nav 
{
    -webkit-transform: translate3d(-300px, 0, 0);
    -moz-transform: translate3d(-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
}

.offscreen-container-authenticated .logo 
{
    bottom: 0px !important;
}

.offscreen-container-authenticated .menu 
{
    display: block;
}

.offscreen-container-authenticated .menu li 
{
    float: none;
    margin-bottom: 16px;
}

.offscreen-container-authenticated .menu li a 
{
    padding-bottom: 0px !important;
    color: #aaa !important;
}

.offscreen-container-authenticated .menu li a:hover 
{
    color: #fff !important;
}

.offscreen-container-authenticated .social-icons 
{
    position: absolute;
    bottom: 40px;
}

.offscreen-container-authenticated .social-icons li a 
{
    color: #fff !important;
}

.offscreen-container-authenticated > h2
{
    color:#fff;
    margin-top:-37px;
    margin-bottom:13px;
}

.offscreen-container-authenticated .smMenuContainer > h2,
.offscreen-container .menu-item-has-children > a
{
    font-family: Open Sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight:600;
    margin:.5em 0;
    margin-top:.25em;
    color:#fff !important;
    font-size:20px !important;
    line-height:44px !important;
    text-transform:none;
    padding-left:.5em;
    padding-right:.75em;
}

.offscreen-container-authenticated .smMenuContainer > h2:first-child,
.offscreen-container .menu-item-has-children > a:first-child
{
    margin: .25em 0 .5em 0;
}

.offscreen-container .menu-item-has-children > a
{
    margin-left:2em;
}

.offscreen-container-authenticated > ul.menu
{
    padding:0 1em;
}

.offscreen-container,
.offscreen-container-authenticated
{
	padding:0;
	overflow:auto;
}

.offscreen-container .logoPlusSocial
{
	display:block;
	float:left;
	clear:both;
	width:100%;
	margin-top:-18px;
    margin-bottom:0.5em;
}

.offscreen-container .homeLogoLink
{
	float:left;
}

.offscreen-container .logoPlusSocial > ul.social-icons
{
	display:block;
	position:static;
	float:right;
	margin-top:12px;
}

.offscreen-container .logo
{
	display:block;
}

.offscreen-container ul.menu > li > a
{
	font-size:1em;
	color:#fff !important;
	cursor:default;
	text-decoration:none;
}

.offscreen-container .icon.deleteIcon,
.offscreen-container-authenticated .icon.deleteIcon
{
    background-image: url('../img/svg/icon_X.svg');
    position: absolute;
    z-index:1;
}

.offscreen-container .icon.deleteIcon
{
    right: 15px;
    top: 15px;
}

.offscreen-container-authenticated .icon.deleteIcon
{
    right: 15px;
    top: 15px;
}

/* CUSTOM LOGIN WIDGET - AFO WORDPRESS PLUGIN */
.smProfileLogin #login
{
	display:block;	
}

.smProfileLogin .login_wid
{
	margin:0;
}

.smProfileLogin .login_wid > li
{
	display:block;
	float:left;
	margin-right:5px;
}

.smProfileLogin .login_wid > li:nth-child(1),
.smProfileLogin .login_wid > li:nth-child(3)
{
    display:none;
}

.smProfileLogin .login_wid > li > input[type="text"],
.smProfileLogin .login_wid > li > input[type="password"]
{
	padding:3px 10px;
	line-height:1.3;
	max-width:100%;
}

.smProfileLogin .login_wid > li > input[type="submit"]
{
	padding:6px;
	color:#fff;
	border-color:#fff;
	position:relative;
	top:-2px;
}

.smProfileLogin .login_wid > li:last-child
{
	float:right;
	margin-right:0;
}

.smProfileLogin
{
	display:block;
	float:right;
}

.smProfileLogin .error_wid_login
{
	position:absolute;
    border:0;
	padding:14px;
	width:349px;
    text-align:center;
    right:176px;
}

a.trsApplyNow
{
    border-radius:0;
    width:100%;
    font-size:1.2em;
    color:#fff;
    background-color:#3498db;
    position:relative;
    bottom:-32px;
    padding:20px 10px;
    display:block;
}

/* PAGE SPECIFIC OVERRIDES - TRY TO GET RID OF THESE AND MAKE PAGE CLASSES AS NEEDED */

/* HOME PAGE OVERRIDES */
section.smallTopPad
{
	padding-top:20px;
}

/* TRSLandingPage */
/* TRS LOGIN LANDING PAGES */


.smLandingPageHeader .smHeaderLogin
{
	background-color:rgba(0,0,0,.6);
	max-width:400px;
}

.smLandingPageHeader .smHeaderLogin h2
{
	color:#fff;
	padding:.5em .5em 0 .5em;
}

.trsFullMarqueeContainer > .col-sm-12
{
    padding:0;
}

.smLandingPageHeader .col-sm-12
{
    padding:20px;
    margin:0;
}

.smLandingPageHeader .vc_column_container .text-center h1
{
    margin-bottom:0;
}

/* LOGIN CONTAINER */

.smLandingPageHeader .smHeaderLogin .login_wid li:nth-child(1),
.smLandingPageHeader .smHeaderLogin .login_wid li:nth-child(3)
{
	display:none;
}

.smLandingPageHeader .smHeaderLogin
{
	max-width:350px;
}

/* LOGIN ELEMENTS */
.smLandingPageHeader .smHeaderLogin .trsLoginText
{
	color:#fff;
	padding:1em 1em 0 1em;
	font-size:1.5em;
}

.smLandingPageHeader .smHeaderLogin .login_wid input[type="text"],
.smLandingPageHeader .smHeaderLogin .login_wid input[type="password"]
{
	border-radius:0;
	max-width:90%;
	background-color:#fff;
}

.smLandingPageHeader .smHeaderLogin .login_wid input[type="submit"]
{
	background:transparent !important;
	border:solid 1px #fff !important;
	color:#fff;
}

.smLandingPageHeader .smHeaderLogin .login_wid input[type="submit"]:hover
{
	background-color:#fff !important;
	color:#000 !important;
}

.login form .input,
.login input[type=text]
{
    font-weight:normal;
}

div.offscreen-container,
div.offscreen-container-authenticated,
.offscreen-menu .container,
.main-container,
.footer-container,
.top-bar
{
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
}


/* VISUAL COMPOSER FIXES */
/* FIX FOR VISUAL COMPOSER PLUGIN ICONS SIZING */
.vc_control-btn .icon,
.vc_control-btn .vc_icon,
.vc_control-btn .icon,
.vc_control-btn .vc_icon
{
    background-size:auto;
}

/* PARALLAX BG OVERRIDE */
.background-image-holder.parallax-background
{
    top:-20%;
}

/* HIGHLIGHT ACTIVE LINKS */

/* STANDARD MENU */
.menu-item-772 > a, /* REQUEST INFORMATION */
.menu-item-773 > a, /* APPLY NOW */
.menu-item-774 > a, /* CHECK APPLICATION STATUS */
.menu-item-776 > a, /* STUDENT LOGIN */
.menu-item-775 > a /* FACULTY LOGIN */
{
    color:#004EDD !important;
}

/* MOBILE MENU */
.offscreen-container .menu li.menu-item-798 > a, /* REQUEST INFORMATION */
.offscreen-container .menu li.menu-item-797 > a, /* APPLY NOW */
.offscreen-container .menu li.menu-item-796 > a, /* CHECK APPLICATION STATUS */
.offscreen-container .menu li.menu-item-800 > a, /* STUDENT LOGIN */
.offscreen-container .menu li.menu-item-807 > a, /* FACULTY LOGIN */
.offscreen-container .menu li.menu-item-791 > a, /* APPLICANT LOGIN */

{
    color:#00FFD1 !important;
}

/* TRS PIVOT FOOTER */
.trsFooterMenu
{
    display:block;
    margin-bottom:4em;
    text-align:center;
}

.footerAddress
{
    display:block;
    float:left;
}

.footerAddress > img
{
    float:left;
    max-width:210px;
    border-right:solid 1px;
    padding-right:.5em;
    margin-right:.5em;
}

.footerAddress > address
{
    float:right;
    line-height:1.25;
}

.footerAddress > address > span
{
    display:block;
}

/* ARTICLE - POST OVERRIDES */
.article-body header.title
{
    margin-bottom:20px;
}

.blog-snippet-1 .sub
{
    display:none;
}

/* DISABLE OVERLAID MENU ON REGISTRATION PAGE */
.page-id-114 .top-bar.offscreen-menu.nav-sticky,
.page-id-285 .top-bar.offscreen-menu.nav-sticky,
.page-id-101 .top-bar.offscreen-menu.nav-sticky,
.page-id-108 .top-bar.offscreen-menu.nav-sticky,
.page-id-118 .top-bar.offscreen-menu.nav-sticky
{
    display:none;
}


/* BUTTON STYLE OVERRIDES */
button
{
    border-width:1px;
    border-radius:2px;
}

/* HOME PAGE HERO (VIDEO BG) */
.hero-divider a.btn
{
    min-width:0;
    padding:1em .5em;
}

.hero-divider a.btn:first-of-type
{
    margin-right:1em;
}


/* MVS - 10-15-2015 HEADER REDESIGN */
div.main-container,
.smLandingPageHeader .smHeaderLogin
{
    margin-top: 0 !important;
}
header .container .col-md-10.col-md-offset-1.col-sm-12
{
    display: none !important;
}
.top-bar .nav-menu,
.top-bar .nav-menu .smQuickLinks,
.top-bar .nav-menu .smQuickLinks .alt-font
{
    display: block !important;
}

.top-bar .nav-menu .smQuickLinks .alt-font
{
    position:relative;
    top:0.5em;
}

.top-bar.nav-sticky .nav-menu .smQuickLinks .alt-font
{
    top:-0.5em;
}

div.main-container .smLandingPageHeader .col-sm-12,
div.main-container .smLandingPageHeader .vc_col-sm-12,
.smLandingPageHeader header .photo-form-wrapper
{
    margin: 0 !important;
}
div.main-container .smLandingPageHeader header
{
    padding-top: 150px !important;
}
.top-bar .nav-menu .smQuickLinks .smHeaderLogin button span.btnTxt,
.overlay-bar.nav-sticky .columns > .menu > li > a,
.overlay-bar .has-dropdown:after,
.overlay-bar .offscreen-toggle i
{
    color: #fff !important;
}
.top-bar .nav-menu
{
    padding: 0 15px !important;
}
.top-bar.overlay-bar.offscreen-menu
{
    overflow: visible !important;
    min-height: 0 !important;
    max-height: none !important;
    border-bottom:solid 1px rgba(255,255,255,.5);
}
.top-bar.overlay-bar.offscreen-menu .row.nav-menu .smQuickLinks
{
    border-bottom: 1px solid rgba(255,255,255,0.3) !important;
    background-color: inherit !important;
    float: right !important;
    width: 100% !important;
    top: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 0 !important;
    padding-top: 23px !important;
    padding-bottom: .5em !important;
}
.logged-in .top-bar.overlay-bar.offscreen-menu .row.nav-menu .smQuickLinks
{
    padding-top: 15px !important;
}
.top-bar .nav-menu .smQuickLinks .smHeaderLogin button
{
    border: solid 1px #fff !important;
    border-radius: 2px !important;
}
/* .top-bar .nav-menu .smQuickLinks .smHeaderLogin button span.icon
{
    background-image: url('../../../U4SM-adaptor/assets/img/svg/icon_locked.svg') !important;
} */
.top-bar .nav-menu > .columns
{
    padding: 32px 0 0 !important;
}
.top-bar .nav-menu > .columns.col-sm-9.col-md-10
{
    padding-left: 5px !important;
}
.top-bar .smQuickLinks .smProfileLogin
{
    margin-top: -13px !important;
}
div.offscreen-toggle
{
    width: 100% !important;
    float: none !important;
    top: 0 !important;
    right: 0 !important;
    color: #fff !important;
    margin: 0 !important;
}
div.offscreen-toggle .smProfileImage
{
    margin-left: 0 !important;
}
.top-bar.nav-sticky > div.container,
.top-bar.nav-sticky > div.container > div.nav-menu
{
    padding-right: 0 !important;
}
/* END HEADER REDESIGN */

/* ADDED FOR PORTAL HOUSING */
.widgetContent .contentBlock.lightBg button
{
    background-color:#fff;
}

.widgetContent .contentBlock.lightBg button:hover
{
    background-color:#3498DB;
    color:#fff;
}
/* END THEME OVERRIDES - OR WORK DIRECTLY INTO THEME? */
